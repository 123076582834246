import React, { useEffect, useState } from 'react';
import { ATMIcon } from 'src/components/common/atoms/ATMIcon/ATMIcon.component';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    Dimmer,
    FormField,
    Grid,
    Image,
    Loader,
} from 'semantic-ui-react';
import transmissionField from '../../../assets/images/transmissionField.jpg';
import {
    IORGDataTableColumn,
    ORGDataTable,
} from 'src/components/common/organisms/ORGDataTable/ORGDataTable.component';
import { noDataFound } from 'src/constants/home';
import { ATMDropdown } from 'src/components/common/atoms/ATMDropdown/ATMDropdown.component';
import Lang from 'src/libraries/language';
import { ATMButton } from 'src/components/common/atoms/ATMButton/ATMButton.component';
import { ATMImage } from 'src/components/common/atoms/ATMImage/ATMImage.component';
import Vector from 'src/assets/images/Vector.png';
import submitRequest from 'src/assets/images/submitRequest.jpg';
import EDRPworksheet from 'src/assets/images/EDRPworksheet.jpg';
import resendVerification from 'src/assets/images/resendVerification.jpg';
import dataRequestLog from 'src/assets/images/dataRequestLog.jpg';
import styles from './Home.module.scss';
import useGetQuarterlyData from 'src/hooks/useFetchQuarterlyData';
import EmailVerification from '../EmailVerification/EmailVerification.component';
import {
    downloadBase64File,
    extractYears,
    filterYears,
} from 'src/utils/common.utils';
import useFetchEmailVerification from 'src/hooks/useFetchEmailVerification';
import { useNotificationDispatch } from 'src/contexts/notification.context';
import CustomModal from 'src/components/common/CustomModal/CustomModal.component';
import { WarningRounded } from '@mui/icons-material';
import useDownloadQuarterlyData from 'src/hooks/useDownloadQuarterlyData';

const Home: React.FC<any> = () => {
    const [resendLink, setResendLink] = useState(false);
    const [tableFilters, setTableFilters] = useState({
        fromYear: '',
        toYear: '',
    });
    const [emailVerifyResponse, setEmailVerifyResponse] = useState(false);
    const [tableData, setTableData] = useState<any>([]);
    const [staticQuarterlyData, setStaticQuarterlyData] = useState<any>([]);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [filterError, setFilterError] = useState(false);
    const [fromYearOptions, setFromYearOptions] = useState([]);
    const [toYearOptions, setToYearOptions] = useState([]);
    const [downloadFileName, setDownloadFileName] = useState('');
    const [repeativeEmailVerification, setRepeativeEmailVerification] =
        useState<any>('');
    const notificationDispatch = useNotificationDispatch();
    const {
        mutate: getQuarterlyData,
        data: quarterlyData,
        isLoading: quarterlyDataLoading,
        isError: quarterlyDataError,
    } = useGetQuarterlyData();

    const {
        mutate: downloadQuarterlyData,
        data: downloadedData,
        isLoading: downloadedFileLoading,
        isError: downloadedFileError,
    } = useDownloadQuarterlyData();

    // Use to Check the already sent Email Link through Id
    const {
        mutate: getEmailVerification,
        data: emailVerificationData,
        isLoading: emailVerificationLoading,
        isError: emailVerificationError,
    } = useFetchEmailVerification();

    useEffect(() => {
        getQuarterlyData({});

        const urlParams = new URLSearchParams(window.location.search);
        const requestId = urlParams.get('request-id');
        try {
            if (requestId) {
                getEmailVerification(requestId);
            }
        } catch (error) {
            notificationDispatch({
                type: 'ERROR',
                payload: Lang.MSG_TOAST_FETCHING_ERROR,
            });
        }
    }, []);

    useEffect(() => {
        if (quarterlyData && quarterlyData?.data) {
            const sortedData = quarterlyData?.data.sort(
                (a, b) => b.year - a.year
            );
            setStaticQuarterlyData(sortedData);
            setTableData(sortedData);

            const result = extractYears(quarterlyData?.data);
            setFromYearOptions(result?.yearsRange);
            setToYearOptions(result?.nextYearsRange);
        } else {
            setTableData([]);
            setStaticQuarterlyData([]);
            setFromYearOptions([]);
            setToYearOptions([]);
        }
    }, [quarterlyData]);

    useEffect(() => {
        if (downloadedData && downloadedData?.data) {
            downloadBase64File(downloadedData?.data, downloadFileName);
        }
    }, [downloadedData]);

    useEffect(() => {
        if (!!tableFilters.fromYear && !!tableFilters.toYear) {
            setButtonsDisabled(true);
            setFilterError(false);

            if (Number(tableFilters.fromYear) >= Number(tableFilters.toYear)) {
                setButtonsDisabled(false);
                setSelectedValues([]);
                setFilterError(true);
            }
        } else {
            setTableData(staticQuarterlyData);
            setButtonsDisabled(false);
            setSelectedValues([]);
            setFilterError(false);
        }
    }, [tableFilters.fromYear, tableFilters.toYear]);

    useEffect(() => {
        if (
            emailVerificationError ||
            quarterlyDataError ||
            downloadedFileError
        ) {
            notificationDispatch({
                type: 'ERROR',
                payload: Lang.MSG_API_500,
            });
        }
    }, [emailVerificationError, quarterlyDataError, downloadedFileError]);

    useEffect(() => {
        if (emailVerificationData && emailVerificationData?.data) {
            if (emailVerificationData?.data?.body) {
                const responseMessage = JSON.parse(
                    emailVerificationData?.data?.body
                );
                setRepeativeEmailVerification(responseMessage?.data);
                setEmailVerifyResponse(true);
            }
        } else {
            setEmailVerifyResponse(false);
        }
    }, [emailVerificationData]);

    const handleSearchData = () => {
        if (buttonsDisabled) {
            const tempSelection = [] as any[];
            !!tableFilters.fromYear &&
                tempSelection.push(tableFilters.fromYear);
            !!tableFilters.toYear && tempSelection.push(tableFilters.toYear);
            setSelectedValues(tempSelection);

            const filteredData = filterYears(
                staticQuarterlyData,
                Number(tableFilters?.fromYear),
                Number(tableFilters?.toYear)
            );
            setTableData(filteredData);
        }
    };

    const handleTenYearsDataSearch = () => {
        const currentYear = new Date().getFullYear();
        const tenYearsAgo = currentYear - 9;

        setTableFilters((prevState) => ({
            ...prevState,
            fromYear: tenYearsAgo.toString(),
            toYear: currentYear.toString(),
        }));

        const tempSelection = [] as any[];

        tempSelection.push(tenYearsAgo);
        tempSelection.push(currentYear);
        setSelectedValues(tempSelection);

        const filteredData = filterYears(
            staticQuarterlyData,
            Number(tenYearsAgo),
            Number(currentYear)
        );
        setTableData(filteredData);
    };

    const handleDownloadFile = (fileName) => {
        downloadQuarterlyData(fileName);
        setDownloadFileName(fileName);
    };

    const quarterlyDataColumns: IORGDataTableColumn<any>[] = [
        {
            title: 'Years',
            index: 'year',
            sortable: false,
            render: (value) => value && <div>{value}</div>,
        },
        {
            title: 'Quarter 1',
            index: 'Q1',
            sortable: false,
            render: (_, record) =>
                record?.Q1 && (
                    <a
                        onClick={() => handleDownloadFile(record?.Q1)}
                        className={styles.downloadLinks}
                    >
                        {record?.Q1}
                        <ATMImage
                            src={Vector}
                            tiny
                            className={styles.downloadIcon}
                            alt="download Q1"
                        />
                    </a>
                ),
        },
        {
            title: 'Quarter 2',
            index: 'Q2',
            sortable: false,
            render: (_, record) =>
                record?.Q2 && (
                    <a
                        onClick={() => handleDownloadFile(record?.Q2)}
                        className={styles.downloadLinks}
                    >
                        {record?.Q2}
                        <ATMImage
                            src={Vector}
                            tiny
                            className={styles.downloadIcon}
                            alt="download Q2"
                        />
                    </a>
                ),
        },
        {
            title: 'Quarter 3',
            index: 'Q3',
            sortable: false,
            render: (_, record) =>
                record?.Q3 && (
                    <a
                        onClick={() => handleDownloadFile(record?.Q3)}
                        className={styles.downloadLinks}
                    >
                        {record?.Q3}
                        <ATMImage
                            src={Vector}
                            tiny
                            className={styles.downloadIcon}
                            alt="download Q3"
                        />
                    </a>
                ),
        },
        {
            title: 'Quarter 4',
            index: 'Q4',
            sortable: false,
            render: (_, record) =>
                record?.Q4 && (
                    <a
                        onClick={() => handleDownloadFile(record?.Q4)}
                        className={styles.downloadLinks}
                    >
                        {record?.Q4}
                        <ATMImage
                            src={Vector}
                            tiny
                            className={styles.downloadIcon}
                            alt="download Q4"
                        />
                    </a>
                ),
        },
    ];

    const handleResendVerificationLink = () => {
        setResendLink(!resendLink);
    };

    const handleDropDownChange = (name, value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        return value;
    };
    
    return (
        <>
            <Grid>
                {emailVerificationLoading || downloadedFileLoading ? (
                    <Dimmer
                        active={
                            emailVerificationLoading || downloadedFileLoading
                        }
                        inverted
                    >
                        <Loader size="large">Loading</Loader>
                    </Dimmer>
                ) : null}

                <div
                    className={styles.fullHeightBackground}
                    style={{
                        backgroundImage: `linear-gradient(45deg, #010E3A, rgba(1,14,58,0) 75%), url(${transmissionField})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        marginLeft: '1em',
                        borderRadius: '16px',
                    }}
                >
                    <div className={styles.imgText}>
                        <h1>Energy Data Request <br/>Program</h1>
                        <p>
                            Find the tools to request energy usage data and
                            download of <br/>quarterly gas usage in our service
                            territory by ZIP Code. SoCalGas® <br/>can also supply
                            more specific reports to qualifying academic<br/>
                            researchers, local government entities or state and
                            federal agencies.
                        </p>
                    </div>
                </div>
                <Grid.Row className={styles.additionalInfo}>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                        {Lang.MSG_HOME_ADDITIONAL_INFO}
                    </Grid.Column>
                </Grid.Row>

                {/* Basic Info section */}
                <Grid.Row className={styles.tableSpacing}>
                    <div className={[styles['homeDescription']].join(' ')}>
                        <h3>{Lang.LBL_QUARTERLY_DATA}</h3>
                        <p>{Lang.MSG_QUARTERLY_DATA}</p>
                    </div>
                </Grid.Row>

                {/* Table section */}
                <Grid.Row
                    className={`${styles.tableSpacing} ${styles.displayBlock}`}
                >
                    <ORGDataTable
                        columns={quarterlyDataColumns}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        defaultRowsPerPage={10}
                        data={tableData}
                        sortable
                        history
                        allButtonSize="small"
                        noDataText={noDataFound}
                        showPagination={true}
                        toolbars={[
                            () => (
                                <FormField className={styles.tableFilters}>
                                    <label>{Lang.LBL_FROMYEAR}</label>
                                    <ATMDropdown
                                        name="fromYear"
                                        label={Lang.LBL_FROMYEAR}
                                        placeholder={'Select'}
                                        selection
                                        search
                                        size="tiny"
                                        data-testid="fromYear"
                                        value={tableFilters.fromYear}
                                        options={fromYearOptions}
                                        clearable
                                        onChange={(e, data) => {
                                            handleDropDownChange(
                                                'fromYear',
                                                data.value
                                            );
                                        }}
                                        selectOnBlur={false}
                                    />
                                </FormField>
                            ),
                            () => (
                                <FormField className={styles.tableFilters}>
                                    <label>{Lang.LBL_TOYEAR}</label>
                                    <ATMDropdown
                                        name="toYear"
                                        label={Lang.LBL_TOYEAR}
                                        placeholder={'Select'}
                                        selection
                                        search
                                        size="tiny"
                                        data-testid="toYear"
                                        options={toYearOptions}
                                        value={tableFilters.toYear}
                                        clearable
                                        onChange={(e, data) => {
                                            handleDropDownChange(
                                                'toYear',
                                                data.value
                                            );
                                        }}
                                        selectOnBlur={false}
                                    />
                                </FormField>
                            ),
                            () => (
                                <ATMButton
                                    content="Search"
                                    size="tiny"
                                    data-testid="searchButton"
                                    className={
                                        buttonsDisabled
                                            ? [
                                                  styles.searchButton,
                                                  styles.buttons,
                                              ].join(' ')
                                            : [styles.buttons].join(' ')
                                    }
                                    onClick={handleSearchData}
                                    disabled={!buttonsDisabled}
                                />
                            ),
                            () => (
                                <ATMButton
                                    content="View Last 10 years data"
                                    size="tiny"
                                    className={styles.buttons}
                                    onClick={handleTenYearsDataSearch}
                                />
                            ),
                        ]}
                        responsive={true}
                        className={styles.tableFiltersRow}
                        loading={quarterlyDataLoading}
                        tableLabel={
                            <>
                                <p className={styles.filterStatement}>
                                    Please utilize the filter option to refine
                                    your search results.
                                    <br />
                                    <br />
                                    {selectedValues.length ? (
                                        <div className="customFilter">
                                            Filtered by:{' '}
                                            {selectedValues?.map(
                                                (item, index) => (
                                                    <strong key={item}>
                                                        {(index ? ', ' : '') +
                                                            item}
                                                    </strong>
                                                )
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </p>
                            </>
                        }
                        tableError={
                            filterError && (
                                <span className={styles.errorText}>
                                    {' '}
                                    <WarningRounded
                                        sx={{ fontSize: 15 }}
                                        color="error"
                                    />
                                    <p>
                                        From Year should be less than To Year.
                                    </p>
                                </span>
                            )
                        }
                    />
                </Grid.Row>
            </Grid>

            {/* Card Section */}
            <Grid columns={4} stackable>
                <Grid.Row>
                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={4}
                        className="sempraExtraLargePaddingBottom"
                    >
                        <Grid stackable reversed="tablet" columns="equal">
                            <Grid.Row tablet={16} computer={4}>
                                <Grid.Column
                                    tablet={8}
                                    computer={16}
                                    className={styles.imageColumn}
                                >
                                    <Card>
                                        <Image
                                            src={submitRequest}
                                            wrapped
                                            ui={false}
                                            className={styles.cardImage}
                                            alt={Lang.LBL_SUBMIT_A_REQUEST}
                                        />
                                    </Card>
                                </Grid.Column>
                                <Grid.Column
                                    tablet={8}
                                    computer={16}
                                    className={styles.contentColumn}
                                >
                                    <Card>
                                        <CardContent
                                            className={styles.cardContent}
                                        >
                                            <CardHeader className="sempraWhiteFontColor">
                                                {Lang.LBL_SUBMIT_A_REQUEST}
                                            </CardHeader>
                                            <CardDescription className="sempraWhiteFontColor">
                                                {Lang.MSG_SUBMIT_A_REQUEST}
                                            </CardDescription>
                                        </CardContent>
                                        <CardContent
                                            extra
                                            className={styles.cardContentLink}
                                        >
                                            <a
                                                className="sempraWhiteFontColor"
                                                href="/submitRequest"
                                            >
                                                {Lang.LBL_SUBMIT_A_REQUEST}{' '}
                                                <ATMIcon name="angle right" />
                                            </a>
                                        </CardContent>
                                    </Card>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>

                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={4}
                        className="sempraExtraLargePaddingBottom"
                    >
                        <Grid stackable reversed="tablet" columns="equal">
                            <Grid.Row tablet={16} computer={4}>
                                <Grid.Column
                                    tablet={8}
                                    computer={16}
                                    className={styles.imageColumn}
                                >
                                    <Card>
                                        <Image
                                            src={EDRPworksheet}
                                            wrapped
                                            ui={false}
                                            className={styles.cardImage}
                                            alt={Lang.LBL_EDRP_DOWNLOAD}
                                        />
                                    </Card>
                                </Grid.Column>
                                <Grid.Column
                                    tablet={8}
                                    computer={16}
                                    className={styles.contentColumn}
                                >
                                    <Card>
                                        <CardContent
                                            className={styles.cardContent}
                                        >
                                            <CardHeader className="sempraWhiteFontColor">
                                                {Lang.LBL_EDRP_DOWNLOAD}
                                            </CardHeader>
                                            <CardDescription className="sempraWhiteFontColor">
                                                {Lang.MSG_EDRP_WORKSHEET}
                                            </CardDescription>
                                        </CardContent>
                                        <CardContent
                                            extra
                                            className={styles.cardContentLink}
                                        >
                                            <a
                                                className="sempraWhiteFontColor"
                                                href={require('../../../assets/files/EDRP_Offline_Request_Form_Worksheet.xlsx')}
                                            >
                                                {Lang.LBL_DOWNLOAD_WORKSHEET}{' '}
                                                <ATMIcon name="angle right" />
                                            </a>
                                        </CardContent>
                                    </Card>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>

                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={4}
                        className="sempraExtraLargePaddingBottom"
                    >
                        <Grid stackable reversed="tablet" columns="equal">
                            <Grid.Row tablet={16} computer={4}>
                                <Grid.Column
                                    tablet={8}
                                    computer={16}
                                    className={styles.imageColumn}
                                >
                                    <Card>
                                        <Image
                                            src={resendVerification}
                                            wrapped
                                            ui={false}
                                            className={styles.cardImage}
                                            alt={
                                                Lang.LBL_RESEND_VERIFICATION_LINK
                                            }
                                        />
                                    </Card>
                                </Grid.Column>
                                <Grid.Column
                                    tablet={8}
                                    computer={16}
                                    className={styles.contentColumn}
                                >
                                    <Card>
                                        <CardContent
                                            className={styles.cardContent}
                                        >
                                            <CardHeader className="sempraWhiteFontColor">
                                                {
                                                    Lang.LBL_RESEND_VERIFICATION_LINK
                                                }
                                            </CardHeader>
                                            <CardDescription className="sempraWhiteFontColor">
                                                {Lang.MSG_RESEND_LINK}
                                            </CardDescription>
                                        </CardContent>
                                        <CardContent
                                            extra
                                            className={styles.cardContentLink}
                                        >
                                            <a
                                                className="sempraWhiteFontColor"
                                                onClick={
                                                    handleResendVerificationLink
                                                }
                                            >
                                                {Lang.LBL_ENTER_EMAIL}{' '}
                                                <ATMIcon name="angle right" />
                                            </a>
                                        </CardContent>
                                    </Card>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>

                    <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={4}
                        className="sempraExtraLargePaddingBottom"
                    >
                        <Grid stackable reversed="tablet" columns="equal">
                            <Grid.Row tablet={16} computer={4}>
                                <Grid.Column
                                    tablet={8}
                                    computer={16}
                                    className={styles.imageColumn}
                                >
                                    <Card>
                                        <Image
                                            src={dataRequestLog}
                                            wrapped
                                            ui={false}
                                            className={styles.cardImage}
                                            alt={Lang.LBL_DATA_REQUEST_LOG}
                                        />
                                    </Card>
                                </Grid.Column>
                                <Grid.Column
                                    tablet={8}
                                    computer={16}
                                    className={styles.contentColumn}
                                >
                                    <Card>
                                        <CardContent
                                            className={styles.cardContent}
                                        >
                                            <CardHeader className="sempraWhiteFontColor">
                                                {Lang.LBL_DATA_REQUEST_LOG}
                                            </CardHeader>
                                            <CardDescription className="sempraWhiteFontColor">
                                                {Lang.MSG_DATA_REQUEST_LOG}
                                            </CardDescription>
                                        </CardContent>
                                        <CardContent
                                            extra
                                            className={styles.cardContentLink}
                                        >
                                            <a
                                                className="sempraWhiteFontColor"
                                                href="/dataRequestLog"
                                            >
                                                {Lang.LBL_VIEW_REQ}{' '}
                                                <ATMIcon name="angle right" />
                                            </a>
                                        </CardContent>
                                    </Card>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {resendLink && (
                // Resend Email Verification Modal
                // Use to send email verification link through email address and Validate it
                <EmailVerification
                    resendLink={resendLink}
                    setResendLink={setResendLink}
                />
            )}

            {emailVerifyResponse && (
                // Resend Email Verification Modal
                <CustomModal
                    modalOpen={emailVerifyResponse}
                    setModalClose={setEmailVerifyResponse}
                    header={Lang.LBL_EMAIL_VERIFICATION}
                    subHeader={repeativeEmailVerification}
                    content={Lang.MSG_EMAIL_VERIFICATION_SUCCESS_MSG}
                    exitButton={true}
                    navigate={true}
                    navigatePath="/"
                />
            )}
        </>
    );
};

export default Home;
