import React from 'react';
import {
  Segment,
  SegmentProps,
  SegmentGroupProps,
  SegmentInlineProps,
} from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './ATMSegment.module.scss';
import { ATMGrid } from '../ATMGrid/ATMGrid.component';
import { ATMHeader } from '../ATMHeader/ATMHeader.component';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';
import { ATMImage } from '../ATMImage/ATMImage.component';

export interface IATMSegment extends React.FC<SegmentProps> {
  Group: typeof Segment.Group;
  Inline: typeof Segment.Inline;
  header?: string;
  subHeader?: string;
  iconName?: any;
  showIcon?: boolean;
  profileImage?: string;
  showProfileImage?: boolean;
  showActionIcon?: boolean;
  segmentHeaderStyle?: string;
  headingSize?: string;
  noPadding?: boolean;
  actionIcons?: React.ReactNode;
}

const ATMSegment: IATMSegment = ({
  children,
  iconName = '',
  showIcon = false,
  iconColor = '',
  iconSize = '',
  profileImageFloat = 'left',
  profileImageSize = 'mini',
  profileImage,
  showProfileImage,
  segmentHeaderStyle,
  showActionIcon = false,
  headingSize = 'h2',
  noPadding,
  actionIcons,
  ...props
}) => {
  if (props.header) {
    return (
      <Segment {...props} className={styles.segmentContainer}>
        <ATMGrid>
          <ATMGrid.Row>
            <ATMGrid.Column computer="10" mobile="10" tablet="9">
              <div className={styles.flex}>
                {showIcon && (
                  <ATMIcon
                    circular
                    color={iconColor}
                    size={iconSize}
                    className={styles.cardIcon}
                    name={iconName}
                  />
                )}
                {showProfileImage && (
                  <ATMImage
                    floated={profileImageFloat}
                    size={profileImageSize}
                    src={profileImage}
                    alt="Profile Image"
                  />
                )}
                <div className={styles.segmentHeaderTitle}>
                  <ATMHeader
                    as={headingSize}
                    className={
                      // eslint-disable-next-line no-nested-ternary
                      segmentHeaderStyle === 'blue'
                        ? styles.blueHeader
                        : segmentHeaderStyle === 'blackWithoutUnderline'
                        ? styles.blackHeader
                        : styles.header
                    }
                  >
                    {props.header}
                  </ATMHeader>
                  <div className={styles.segmentSubHeader}>
                    {props.subHeader}
                  </div>
                </div>
              </div>
            </ATMGrid.Column>
            <ATMGrid.Column computer="6" mobile="6" tablet="7">
              {showActionIcon && (
                <div className={styles.actionIcons}>{actionIcons}</div>
              )}
            </ATMGrid.Column>
          </ATMGrid.Row>
        </ATMGrid>
        {children}
      </Segment>
    );
  }

  return (
    <Segment
      {...props}
      className={classNames(props.className, {
        [styles.noPadding]: noPadding,
      })}
    >
      {children}
    </Segment>
  );
};

ATMSegment.Group = Segment.Group;
ATMSegment.Inline = Segment.Inline;

export type IATMSegmentProps = SegmentProps;
export type IATMSegmentGroupProps = SegmentGroupProps;
export type IATMSegmentInlineProps = SegmentInlineProps;

export { ATMSegment };
