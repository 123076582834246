import React from 'react';
import { ATMBreadcrumb } from '../atoms/ATMBreadcrumb/ATMBreadCrumb.component';
import styles from './HeaderSection.module.scss';
import { Home } from '@mui/icons-material';

interface HeaderSectionProps {
    sections: string;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({ sections }) => {
    const allSections = [
        {
            key: 'Home',
            content: (
                <span className={styles.homeIcon}>
                    <a href="/">
                        <Home sx={{ fontSize: 24 }} />
                    </a>
                </span>
            ),
            link: true,
        },
        sections?.length > 0 && {
            key: `${sections}`,
            content: <a className={styles.newBreadcrumb}>{sections}</a>,
            active: true,
        },
    ] as any;

    return (
        <ATMBreadcrumb
            icon="right chevron"
            size="small"
            sections={allSections}
            className={styles.breadCrumb}
        />
    );
};

export default HeaderSection;
