import { getServiceInstance } from './base.service';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const resendEmailVerification = async (requestBody) => {
    try {
        const response = await getServiceInstance().get(
            `${BASE_URL}/resend-email-verification?Email=${requestBody}`
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};
