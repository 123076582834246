import React from 'react';
import { Route } from 'react-router-dom';

const routerFactory = (routes): React.ReactNode => {

    return routes.map((route) => {
        return <Route key={route.path} path={route.path} element={<route.component />} />
    }
    );
};

export default routerFactory;
