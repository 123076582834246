import React, { useEffect, useState } from 'react';
import {
    ContactPhone,
    Contacts,
    ModeEdit,
    SupervisedUserCircle,
    Villa,
} from '@mui/icons-material';
import { ATMDropdown } from 'src/components/common/atoms/ATMDropdown/ATMDropdown.component';
import {
    radioToggleOptions,
    statesOptions,
    typeOfOrganizationOptions,
} from 'src/constants/home';
import { ATMDivider } from 'src/components/common/atoms/ATMDivider/ATMDivider.component';
import {
    CorporateFare,
    ExpandLess,
    ExpandMore,
    WarningRounded,
} from '@mui/icons-material';
import Lang from 'src/libraries/language';
import { ATMField } from 'src/components/common/atoms/ATMField/ATMField.component';
import { ATMRadio } from 'src/components/common/atoms/ATMRadio/ATMRadio.component';
import {
    ATMInput,
    formatPhoneNumber,
} from 'src/components/common/atoms/ATMInput/ATMInput.component';
import { FormField } from 'semantic-ui-react';
import { ATMSegment } from 'src/components/common/atoms/ATMSegment/ATMSegment.component';
import { ATMGrid } from 'src/components/common/atoms/ATMGrid/ATMGrid.component';
import styles from './SubmitRequest.module.scss';
import { ATMHeader } from 'src/components/common/atoms/ATMHeader/ATMHeader.component';
import { ATMButton } from 'src/components/common/atoms/ATMButton/ATMButton.component';
import {
    useSubmitRequestDispatch,
    useSubmitRequestState,
} from 'src/contexts/submitRequest.context';
import {
    useSubmitRequestErrorsDispatch,
    useSubmitRequestErrorsState,
} from 'src/contexts/submitRequestErrors.context';
import {
    isAllowedDomain,
    isValidEmail,
    validateForm,
} from 'src/utils/common.utils';

interface OrganizationProps {
    setOrganizationRequestToggle: (value: boolean) => void;
    OrganizationRequestToggle: boolean;
    handleDataChange: ([_, data]: [any, any]) => any;
    reviewAndSubmitButton: boolean;
    handleRadioButtonChange: (_: any, data: any) => any;
    setReviewAndSubmitButton: (value: boolean) => void;
}

const Organization: React.FC<OrganizationProps> = ({
    setOrganizationRequestToggle,
    OrganizationRequestToggle,
    handleDataChange,
    reviewAndSubmitButton,
    handleRadioButtonChange,
    setReviewAndSubmitButton,
}) => {
    const [showStaticOrganizationRequest, setShowStaticOrganizationRequest] =
        useState(false);

    const { submitRequestForm } = useSubmitRequestState();
    const dispatchForm = useSubmitRequestDispatch();
    const { submitRequestErrors } = useSubmitRequestErrorsState();
    const dispatchErrors = useSubmitRequestErrorsDispatch();

    useEffect(() => {
        if (reviewAndSubmitButton) {
            setShowStaticOrganizationRequest(true);
        } else {
            setShowStaticOrganizationRequest(false);
        }
    }, [reviewAndSubmitButton]);

    const handleEditClick = () => {
        setShowStaticOrganizationRequest(false);
        setReviewAndSubmitButton(false);
    };

    const handleChange = (fieldName, fieldValue) => {
        if (
            fieldName == 'AdditionalRequesterContact' ||
            fieldName == 'ContactNumber'
        ) {
            const result = formatPhoneNumber(fieldValue);
            submitRequestForm[fieldName] = result;
        } else if (fieldName == 'SocalGasContactEmail') {
            if (fieldValue != '') {
                if (!isValidEmail(fieldValue)) {
                    submitRequestErrors.SocalGasContactEmail =
                        'Not a valid email';
                } else if (!isAllowedDomain(fieldValue)) {
                    submitRequestErrors.SocalGasContactEmail =
                        'Not an allowed domain';
                } else {
                    submitRequestErrors.SocalGasContactEmail = null;
                }
 
                submitRequestForm[fieldName] = fieldValue;
 
                dispatchErrors({
                    type: 'UPDATE_SUBMIT_REQUEST_ERRORS',
                    payload: submitRequestErrors,
                });
            }
            if (fieldValue == '') {
                delete submitRequestForm[fieldName];
                submitRequestErrors.SocalGasContactEmail = null;
 
                dispatchErrors({
                    type: 'UPDATE_SUBMIT_REQUEST_ERRORS',
                    payload: submitRequestErrors,
                });
            }
        } else {
            submitRequestForm[fieldName] = fieldValue;
        }
        const { errors, formData } = validateForm(
            fieldName,
            submitRequestForm,
            submitRequestErrors
        );
        dispatchForm({
            type: 'UPDATE_SUBMIT_REQUEST',
            payload: formData,
        });
        dispatchErrors({
            type: 'UPDATE_SUBMIT_REQUEST_ERRORS',
            payload: errors,
        });
    };

    const handleToggle = () => {
        setOrganizationRequestToggle(!OrganizationRequestToggle);
    };

    const staticAddress = (submitRequestForm) => {
        let address = '';
        if (submitRequestForm.AddressLine1) {
            address += submitRequestForm.AddressLine1;
        }
        if (submitRequestForm.AddressLine2) {
            address += ' ' + submitRequestForm.AddressLine2;
        }
        return address;
    };

    return (
        <ATMSegment size="tiny" className={styles.commonMargin}>
            <ATMGrid>
                <ATMGrid.Row>
                    <ATMGrid.Column
                        width={8}
                        className={styles.segmentsStyle}
                        onClick={handleToggle}
                    >
                        <ATMHeader as="h2" className={styles.headerStyle}>
                            <CorporateFare />
                            {Lang.LBL_ORGANIZATION_REQUESTING_DATA}
                        </ATMHeader>
                    </ATMGrid.Column>

                    {OrganizationRequestToggle ? (
                        showStaticOrganizationRequest ? (
                            <ATMGrid.Column
                                width={8}
                                floated="right"
                                textAlign="right"
                            >
                                <ATMButton
                                    className={[
                                        styles.editButton,
                                        'sempraNoPaddingRight',
                                    ].join(' ')}
                                    onClick={handleEditClick}
                                >
                                    <ModeEdit sx={{ fontSize: 16 }} /> Edit
                                </ATMButton>
                            </ATMGrid.Column>
                        ) : (
                            <ATMGrid.Column
                                width={8}
                                floated="right"
                                textAlign="right"
                                onClick={handleToggle}
                            >
                                <ExpandLess />
                            </ATMGrid.Column>
                        )
                    ) : (
                        <ATMGrid.Column
                            width={8}
                            floated="right"
                            textAlign="right"
                            onClick={handleToggle}
                        >
                            <ExpandMore />
                        </ATMGrid.Column>
                    )}
                </ATMGrid.Row>
            </ATMGrid>

            {OrganizationRequestToggle && (
                <div>
                    {showStaticOrganizationRequest ? (
                        // static Organization UI
                        <>
                            {/* Organization */}
                            <ATMGrid lassName={styles.gridStyle}>
                                <ATMDivider className={styles.divider} />
                                <ATMHeader
                                    as="h2"
                                    className={styles.headerStyle}
                                >
                                    <Villa />
                                    Organization
                                </ATMHeader>

                                <ATMDivider className={styles['divider']} />

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMHeader
                                            as="h3"
                                            content={Lang.LBL_ORGANIZATION_NAME}
                                            subheader={
                                                submitRequestForm.OrganizationName
                                            }
                                            className={styles.atmHeader}
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={Lang.LBL_ADDRESS}
                                            subheader={staticAddress(
                                                submitRequestForm
                                            )}
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        computer={5}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={Lang.LBL_CITY}
                                            subheader={submitRequestForm.City}
                                        />
                                    </ATMGrid.Column>

                                    <ATMGrid.Column
                                        computer={5}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={Lang.LBL_STATE}
                                            subheader={submitRequestForm.State}
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        computer={6}
                                        tablet={16}
                                        mobile={16}
                                    >
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={Lang.LBL_ZIP_CODE}
                                            subheader={
                                                submitRequestForm.ZipCode
                                            }
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={
                                                Lang.LBL_EMPLOYER_IDENTIFICATION_NUMBER
                                            }
                                            subheader={submitRequestForm.EIN}
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={
                                                Lang.LBL_TYPE_OF_ORGANIZATION
                                            }
                                            subheader={
                                                submitRequestForm.OrganizationType ==
                                                'Others (Please Specify)'
                                                    ? 'Others (Please Specify) - '+ submitRequestForm?.OrganizationTypeOthers
                                                    : submitRequestForm.OrganizationType
                                            }
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                                <ATMDivider className={styles.divider} />
                            </ATMGrid>

                            {/* Primary Contact */}
                            <ATMGrid className={styles.gridStyle}>
                                <ATMHeader
                                    as="h2"
                                    className={styles.headerStyle}
                                >
                                    <Contacts />
                                    Primary Contact
                                </ATMHeader>

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <h5>{Lang.MSG_PRIMARY_CONTACT}</h5>
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        computer={5}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={Lang.LBL_NAME}
                                            subheader={
                                                submitRequestForm.RequesterName
                                            }
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        computer={5}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={Lang.LBL_TITLE_OR_ROLE}
                                            subheader={submitRequestForm.Role}
                                        />
                                    </ATMGrid.Column>

                                    <ATMGrid.Column
                                        computer={6}
                                        tablet={8}
                                        mobile={16}
                                    >
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={Lang.LBL_PHONE_NUMBER}
                                            subheader={
                                                submitRequestForm.ContactNumber
                                            }
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={
                                                Lang.LBL_OFFICIAL_EMAIL_ADDRESS
                                            }
                                            subheader={submitRequestForm.Email}
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={
                                                Lang.LBL_ARE_YOU_THE_PRIMARY_CONTACT
                                            }
                                            subheader={
                                                submitRequestForm.IsPrimaryContact ===
                                                'Yes'
                                                    ? 'Yes'
                                                    : 'No'
                                            }
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMDivider className={styles.divider} />
                            </ATMGrid>

                            {/* Your Contact Information - This will show only when "Are you the primary contact ?  is NO*/}
                            {submitRequestForm?.IsPrimaryContact === 'No' && (
                                <ATMGrid className={styles.gridStyle}>
                                    <ATMHeader
                                        as="h2"
                                        className={styles.headerStyle}
                                    >
                                        <ContactPhone />
                                        Your Contact Information
                                    </ATMHeader>

                                    <ATMGrid.Row>
                                        <ATMGrid.Column
                                            computer={5}
                                            tablet={8}
                                            mobile={16}
                                            className="sempraMediumMarginBottom"
                                        >
                                            <ATMHeader
                                                className={styles.atmHeader}
                                                as="h3"
                                                content={Lang.LBL_YOUR_NAME}
                                                subheader={
                                                    submitRequestForm.AdditionalRequesterName
                                                }
                                            />
                                        </ATMGrid.Column>
                                        <ATMGrid.Column
                                            computer={5}
                                            tablet={8}
                                            mobile={16}
                                            className="sempraMediumMarginBottom"
                                        >
                                            <ATMHeader
                                                className={styles.atmHeader}
                                                as="h3"
                                                content={Lang.LBL_COMPANY}
                                                subheader={
                                                    submitRequestForm.AdditionalRequesterCompany
                                                }
                                            />
                                        </ATMGrid.Column>
                                        <ATMGrid.Column
                                            computer={6}
                                            tablet={8}
                                            mobile={16}
                                            className="sempraMediumMarginBottom"
                                        >
                                            <ATMHeader
                                                className={styles.atmHeader}
                                                as="h3"
                                                content={
                                                    Lang.LBL_YOUR_PHONE_NUMBER
                                                }
                                                subheader={
                                                    submitRequestForm.AdditionalRequesterContact
                                                }
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles.atmHeader}
                                                as="h3"
                                                content={
                                                    Lang.LBL_YOUR_EMAIL_ADDRESS
                                                }
                                                subheader={
                                                    submitRequestForm.AdditionalRequesterEmail
                                                }
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    <ATMDivider className={styles.divider} />
                                </ATMGrid>
                            )}

                            {/* Faculty Contact - Academic Researcher is selected in "Type of Organization*/}
                            {submitRequestForm?.OrganizationType ===
                                'Academic Researcher' && (
                                <ATMGrid className={styles.gridStyle}>
                                    <ATMHeader
                                        as="h2"
                                        className={styles.headerStyle}
                                    >
                                        <SupervisedUserCircle />
                                        Faculty Contact
                                    </ATMHeader>

                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles.atmHeader}
                                                as="h3"
                                                content={
                                                    Lang.LBL_ACCREDITED_INST_SPONSERING_RESEARCH
                                                }
                                                subheader={
                                                    submitRequestForm.AccreditedInstituteName
                                                }
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles['ATMHeader']}
                                                as="h3"
                                            >
                                                <p
                                                    className={
                                                        styles.paragraphLabel
                                                    }
                                                >
                                                    {
                                                        Lang.LBL_FACULTY_MEMBER_OF_INST
                                                    }
                                                </p>
                                            </ATMHeader>
                                            <div
                                                className={
                                                    styles.primaryContactRadioButtons
                                                }
                                            >
                                                <ATMHeader
                                                    className={styles.atmHeader}
                                                    as="h3"
                                                    subheader={
                                                        submitRequestForm?.IsFacultyMember ===
                                                        'Yes'
                                                            ? 'Yes'
                                                            : 'No'
                                                    }
                                                />
                                            </div>
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    {/* this will be visible when "Are you a faculty member at inst -> NO" */}
                                    {submitRequestForm?.IsFacultyMember ===
                                        'No' && (
                                        <ATMGrid.Row>
                                            <ATMGrid.Column width={16}>
                                                <ATMHeader
                                                    className={styles.atmHeader}
                                                    as="h3"
                                                    content={
                                                        Lang.LBL_FACULTY_MEMBER_SPONSORING
                                                    }
                                                    subheader={
                                                        submitRequestForm.FacultyMemberSponsored
                                                    }
                                                />
                                            </ATMGrid.Column>
                                        </ATMGrid.Row>
                                    )}

                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles.atmHeader}
                                                as="h3"
                                                content={
                                                    Lang.LBL_DEPT_JOB_TITLE
                                                }
                                                subheader={
                                                    submitRequestForm.AcademicDepartment
                                                }
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    <ATMDivider className={styles.divider} />
                                </ATMGrid>
                            )}

                            {/* Contact at SoCalGas */}
                            <ATMGrid className={styles.gridStyle}>
                                <ATMHeader
                                    as="h2"
                                    className={styles.headerStyle}
                                >
                                    <ContactPhone />
                                    Contact at SoCalGas
                                </ATMHeader>

                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        computer={8}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={Lang.LBL_NAME}
                                            subheader={
                                                submitRequestForm.SoCalGasContactName
                                            }
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        computer={8}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={Lang.LBL_EMAIL_ADDRESS}
                                            subheader={
                                                submitRequestForm.SocalGasContactEmail
                                            }
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMDivider className={styles.divider} />
                            </ATMGrid>
                        </>
                    ) : (
                        // Organization UI
                        <>
                            {/* Organization */}

                            <ATMGrid className={styles.gridStyle}>
                                <ATMDivider className={styles.divider} />
                                <ATMHeader
                                    as="h2"
                                    className={styles.headerStyle}
                                >
                                    <Villa />
                                    Organization
                                </ATMHeader>

                                <ATMDivider className={styles['divider']} />

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMField
                                            name="OrganizationName"
                                            placeholder={
                                                Lang.PLC_ORGANIZATION_NAME
                                            }
                                            as={ATMInput}
                                            label={Lang.LBL_ORGANIZATION_NAME}
                                            fluid
                                            required
                                            data-testid="OrganizationName"
                                            className={styles.fieldStyle}
                                            restrictAngularBrackets
                                            maxLength={100}
                                            minLength={2}
                                            error={
                                                submitRequestErrors.OrganizationName
                                            }
                                            onBlur={(e) =>
                                                handleChange(
                                                    'OrganizationName',
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                submitRequestForm.OrganizationName
                                            }
                                            noSpecialCharacters={true}
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        computer={8}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMField
                                            name="AddressLine1"
                                            as={ATMInput}
                                            label={Lang.LBL_ADDRESS_LINE_1}
                                            placeholder={Lang.PLC_ADDRESS_LINE1}
                                            onBlur={(e) =>
                                                handleChange(
                                                    'AddressLine1',
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                submitRequestForm.AddressLine1
                                            }
                                            fluid
                                            required
                                            data-testid="AddressLine1"
                                            className={styles.fieldStyle}
                                            restrictAngularBrackets
                                            maxLength={100}
                                            minLength={2}
                                            error={
                                                submitRequestErrors.AddressLine1
                                            }
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        computer={8}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMField
                                            name="AddressLine2"
                                            as={ATMInput}
                                            label={Lang.LBL_ADDRESS_LINE_2}
                                            placeholder={Lang.PLC_ADDRESS_LINE2}
                                            onBlur={(e) =>
                                                handleChange(
                                                    'AddressLine2',
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                submitRequestForm.AddressLine2
                                            }
                                            fluid
                                            data-testid="AddressLine2"
                                            className={styles.fieldStyle}
                                            restrictAngularBrackets
                                            maxLength={100}
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        computer={5}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMField
                                            name="City"
                                            as={ATMInput}
                                            label={Lang.LBL_CITY}
                                            placeholder={Lang.PLC_CITY}
                                            onBlur={(e) =>
                                                handleChange(
                                                    'City',
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                submitRequestForm.City
                                            }
                                            fluid
                                            required
                                            data-testid="City"
                                            className={styles.fieldStyle}
                                            restrictAngularBrackets
                                            maxLength={100}
                                            minLength={2}
                                            error={submitRequestErrors.City}
                                            noSpecialCharacters={true}
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        computer={5}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMField
                                            name="State"
                                            label={Lang.LBL_STATE}
                                            placeholder={
                                                Lang.PLC_SELECT_YOUR_STATE
                                            }
                                            as={ATMDropdown}
                                            search
                                            selection
                                            selectOnBlur={false}
                                            onChange={(_, data) =>
                                                handleDataChange([
                                                    _,
                                                    {
                                                        name: `State`,
                                                        value: data.value,
                                                    },
                                                ])
                                            }
                                            fluid
                                            required
                                            data-testid="State"
                                            className={styles.dropDownLabel}
                                            value={submitRequestForm.State}
                                            options={statesOptions}
                                            error={submitRequestErrors.State}
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>

                                    <ATMGrid.Column
                                        computer={6}
                                        tablet={16}
                                        mobile={16}
                                    >
                                        <ATMField
                                            name="ZipCode"
                                            as={ATMInput}
                                            label={Lang.LBL_ZIP_CODE}
                                            placeholder={Lang.PLC_ZIP_CODE}
                                            onBlur={(e) =>
                                                handleChange(
                                                    'ZipCode',
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                submitRequestForm.ZipCode
                                            }
                                            fluid
                                            required
                                            data-testid="ZipCode"
                                            className={styles.fieldStyle}
                                            restrictAngularBrackets
                                            maxLength={10}
                                            error={submitRequestErrors.ZipCode}
                                            isZip
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMField
                                            name="EIN"
                                            as={ATMInput}
                                            isEIN={true}
                                            label={
                                                Lang.LBL_EMPLOYER_IDENTIFICATION_NUMBER
                                            }
                                            placeholder={
                                                Lang.PLC_EMPLOYER_IDENTIFICATION_NUMBER
                                            }
                                            onBlur={(e) =>
                                                handleChange(
                                                    'EIN',
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={submitRequestForm.EIN}
                                            fluid
                                            required
                                            data-testid="EIN"
                                            className={styles.fieldStyle}
                                            restrictAngularBrackets
                                            maxLength={9}
                                            error={submitRequestErrors.EIN}
                                            Number={true}
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={9}>
                                        <div className="sempraSmallMarginBottom">
                                            <ATMHeader
                                                className={styles['ATMHeader']}
                                                as="h3"
                                            >
                                                <p
                                                    className={
                                                        styles.paragraphLabel
                                                    }
                                                >
                                                    {
                                                        Lang.LBL_TYPE_OF_ORGANIZATION
                                                    }
                                                </p>
                                                <p className={styles.indicator}>
                                                    &nbsp; *
                                                </p>
                                            </ATMHeader>

                                            {typeOfOrganizationOptions.map(
                                                (option, index) => (
                                                    <FormField key={index}>
                                                        <ATMRadio
                                                            label={`${option}`}
                                                            value={option}
                                                            name={`${option}`}
                                                            className={`sempraExtraLargeMarginRight radioButton ${
                                                                index === 4
                                                                    ? ''
                                                                    : 'sempraExtraLargeMarginRight'
                                                            }`}
                                                            onChange={
                                                                handleRadioButtonChange
                                                            }
                                                            checked={
                                                                submitRequestForm?.OrganizationType ===
                                                                option
                                                            }
                                                            autoComplete="off"
                                                        />
                                                    </FormField>
                                                )
                                            )}

                                            {submitRequestForm?.OrganizationType ===
                                                'Others (Please Specify)' && (
                                                <FormField>
                                                    <ATMField
                                                        name="OrganizationTypeOthers"
                                                        as={ATMInput}
                                                        restrictAngularBrackets
                                                        label={Lang.LBL_OTHERS}
                                                        placeholder={
                                                            Lang.PLC_OTHERS
                                                        }
                                                        onBlur={(e) =>
                                                            handleChange(
                                                                'OrganizationTypeOthers',
                                                                e.target.value
                                                            )
                                                        }
                                                        onChange={(e) =>
                                                            handleChange(
                                                                'OrganizationTypeOthers',
                                                                e.target.value
                                                            )
                                                        }
                                                        defaultValue={
                                                            submitRequestForm.OrganizationTypeOthers
                                                        }
                                                        fluid
                                                        required
                                                        data-testid="OrganizationTypeOthers"
                                                        className={
                                                            styles.fieldStyle
                                                        }
                                                        maxLength={100}
                                                        value={
                                                            submitRequestForm?.OrganizationTypeOthers
                                                        }
                                                        error={
                                                            submitRequestErrors?.OrganizationTypeOthers
                                                        }
                                                        noSpecialCharacters={
                                                            true
                                                        }
                                                        autoComplete="off"
                                                    />
                                                </FormField>
                                            )}

                                            {submitRequestErrors?.OrganizationType && (
                                                <FormField className="sempraMediumPaddingBottom">
                                                    <p
                                                        className={
                                                            styles.errorText
                                                        }
                                                    >
                                                        <WarningRounded />
                                                        This is a required
                                                        field.
                                                    </p>
                                                </FormField>
                                            )}
                                        </div>
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                                <ATMDivider className={styles.divider} />
                            </ATMGrid>

                            {/* Primary Contact */}
                            <ATMGrid className={styles.gridStyle}>
                                <ATMHeader
                                    as="h2"
                                    className={styles.headerStyle}
                                >
                                    <Contacts />
                                    Primary Contact
                                </ATMHeader>

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <h5>{Lang.MSG_PRIMARY_CONTACT}</h5>
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        computer={6}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMField
                                            name="RequesterName"
                                            as={ATMInput}
                                            label={Lang.LBL_NAME}
                                            placeholder={Lang.PLC_NAME}
                                            onBlur={(e) =>
                                                handleChange(
                                                    'RequesterName',
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                submitRequestForm.RequesterName
                                            }
                                            fluid
                                            required
                                            data-testid="RequesterName"
                                            className={styles.fieldStyle}
                                            error={
                                                submitRequestErrors.RequesterName
                                            }
                                            restrictAngularBrackets
                                            maxLength={100}
                                            minLength={2}
                                            noSpecialCharacters={true}
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>

                                    <ATMGrid.Column
                                        computer={5}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMField
                                            name="Role"
                                            as={ATMInput}
                                            label={Lang.LBL_TITLE_OR_ROLE}
                                            placeholder={Lang.PLC_TITLE_ROLE}
                                            onBlur={(e) =>
                                                handleChange(
                                                    'Role',
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                submitRequestForm.Role
                                            }
                                            fluid
                                            required
                                            data-testid="Role"
                                            className={styles.fieldStyle}
                                            restrictAngularBrackets
                                            maxLength={100}
                                            minLength={2}
                                            error={submitRequestErrors.Role}
                                            noSpecialCharacters={true}
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>

                                    <ATMGrid.Column
                                        computer={5}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMField
                                            name="ContactNumber"
                                            as={ATMInput}
                                            label={Lang.LBL_PHONE_NUMBER}
                                            placeholder={Lang.PLC_PHONE_NUMBER}
                                            onBlur={(e) =>
                                                handleChange(
                                                    'ContactNumber',
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                submitRequestForm.ContactNumber
                                            }
                                            fluid
                                            required
                                            data-testid="ContactNumber"
                                            className={styles.fieldStyle}
                                            error={
                                                submitRequestErrors?.ContactNumber
                                            }
                                            isPhone={true}
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMField
                                            name="Email"
                                            as={ATMInput}
                                            label={
                                                Lang.LBL_OFFICIAL_EMAIL_ADDRESS
                                            }
                                            placeholder={
                                                Lang.LBL_OFFICIAL_EMAIL_ADDRESS
                                            }
                                            onBlur={(e) =>
                                                handleChange(
                                                    'Email',
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                submitRequestForm.Email
                                            }
                                            fluid
                                            required
                                            restrictAngularBrackets
                                            data-testid="Email"
                                            className={styles.fieldStyle}
                                            error={submitRequestErrors?.Email}
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMField
                                            name="confirmEmailAddress"
                                            as={ATMInput}
                                            label={
                                                Lang.LBL_CONFIRM_OFFICIAL_EMAIL_ADDRESS
                                            }
                                            placeholder={
                                                Lang.PLC_CONFIRM_OFFICIAL_EMAIL_ADDRESS
                                            }
                                            onBlur={(e) =>
                                                handleChange(
                                                    'confirmEmailAddress',
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                submitRequestForm.confirmEmailAddress
                                            }
                                            fluid
                                            required
                                            restrictAngularBrackets
                                            data-testid="confirmEmailAddress"
                                            className={styles.fieldStyle}
                                            error={
                                                submitRequestErrors.confirmEmailAddress
                                            }
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMHeader
                                            className={styles['ATMHeader']}
                                            as="h3"
                                        >
                                            <p
                                                className={
                                                    styles.paragraphLabel
                                                }
                                            >
                                                {
                                                    Lang.LBL_ARE_YOU_THE_PRIMARY_CONTACT
                                                }
                                            </p>
                                            <p className={styles.indicator}>
                                                &nbsp; *
                                            </p>
                                        </ATMHeader>
                                        <div
                                            className={
                                                styles.primaryContactRadioButtons
                                            }
                                        >
                                            {radioToggleOptions?.map(
                                                (label, index) => (
                                                    <ATMRadio
                                                        key={index}
                                                        label={label}
                                                        onChange={
                                                            handleRadioButtonChange
                                                        }
                                                        checked={
                                                            submitRequestForm?.IsPrimaryContact ===
                                                            `${label}`
                                                        }
                                                        value={`${label}`}
                                                        name={`IsPrimaryContact`}
                                                        autoComplete="off"
                                                    />
                                                )
                                            )}
                                        </div>
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                {submitRequestErrors?.IsPrimaryContact && (
                                    <FormField className="sempraMediumPaddingBottom">
                                        <p className={styles.errorText}>
                                            <WarningRounded />
                                            This is a required field.
                                        </p>
                                    </FormField>
                                )}

                                <ATMDivider className={styles.divider} />
                            </ATMGrid>

                            {/* Your Contact Information - This will show only when "Are you the primary contact ?  is NO*/}
                            {submitRequestForm?.IsPrimaryContact === 'No' && (
                                <ATMGrid
                                    className={styles.gridStyle}
                                    key={JSON.stringify(
                                        submitRequestForm?.IsPrimaryContact
                                    )}
                                >
                                    <ATMHeader
                                        as="h2"
                                        className={styles.headerStyle}
                                    >
                                        <ContactPhone />
                                        Your Contact Information
                                    </ATMHeader>

                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={5}>
                                            <ATMField
                                                name="AdditionalRequesterName"
                                                as={ATMInput}
                                                label={Lang.LBL_YOUR_NAME}
                                                placeholder={Lang.PLC_NAME}
                                                onBlur={(e) =>
                                                    handleChange(
                                                        'AdditionalRequesterName',
                                                        e.target.value
                                                    )
                                                }
                                                defaultValue={
                                                    submitRequestForm.AdditionalRequesterName
                                                }
                                                fluid
                                                required
                                                data-testid="AdditionalRequesterName"
                                                className={styles.fieldStyle}
                                                restrictAngularBrackets
                                                maxLength={100}
                                                minLength={2}
                                                error={
                                                    submitRequestErrors.AdditionalRequesterName
                                                }
                                                key={JSON.stringify(
                                                    submitRequestForm?.IsPrimaryContact
                                                )}
                                                noSpecialCharacters={true}
                                                autoComplete="off"
                                            />
                                        </ATMGrid.Column>

                                        <ATMGrid.Column width={6}>
                                            <ATMField
                                                name="AdditionalRequesterCompany"
                                                as={ATMInput}
                                                label={Lang.LBL_COMPANY}
                                                placeholder={Lang.PLC_COMPANY}
                                                onBlur={(e) =>
                                                    handleChange(
                                                        'AdditionalRequesterCompany',
                                                        e.target.value
                                                    )
                                                }
                                                fluid
                                                required
                                                data-testid="AdditionalRequesterCompany"
                                                className={styles.fieldStyle}
                                                defaultValue={
                                                    submitRequestForm.AdditionalRequesterCompany
                                                }
                                                restrictAngularBrackets
                                                maxLength={100}
                                                minLength={2}
                                                error={
                                                    submitRequestErrors.AdditionalRequesterCompany
                                                }
                                                key={JSON.stringify(
                                                    submitRequestForm?.IsPrimaryContact
                                                )}
                                                noSpecialCharacters={true}
                                                autoComplete="off"
                                            />
                                        </ATMGrid.Column>

                                        <ATMGrid.Column width={5}>
                                            <ATMField
                                                name="AdditionalRequesterContact"
                                                as={ATMInput}
                                                label={
                                                    Lang.LBL_YOUR_PHONE_NUMBER
                                                }
                                                placeholder={
                                                    Lang.PLC_PHONE_NUMBER
                                                }
                                                onBlur={(e) =>
                                                    handleChange(
                                                        'AdditionalRequesterContact',
                                                        e.target.value
                                                    )
                                                }
                                                fluid
                                                required
                                                data-testid="AdditionalRequesterContact"
                                                className={styles.fieldStyle}
                                                defaultValue={
                                                    submitRequestForm.AdditionalRequesterContact
                                                }
                                                isPhone={true}
                                                error={
                                                    submitRequestErrors.AdditionalRequesterContact
                                                }
                                                autoComplete="off"
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={16}>
                                            <ATMField
                                                name="AdditionalRequesterEmail"
                                                as={ATMInput}
                                                label={
                                                    Lang.LBL_YOUR_EMAIL_ADDRESS
                                                }
                                                placeholder={
                                                    Lang.PLC_OFFICIAL_EMAIL_ADDRESS
                                                }
                                                onBlur={(e) =>
                                                    handleChange(
                                                        'AdditionalRequesterEmail',
                                                        e.target.value
                                                    )
                                                }
                                                fluid
                                                required
                                                data-testid="AdditionalRequesterEmail"
                                                className={styles.fieldStyle}
                                                defaultValue={
                                                    submitRequestForm.AdditionalRequesterEmail
                                                }
                                                restrictAngularBrackets
                                                maxLength={50}
                                                error={
                                                    submitRequestErrors.AdditionalRequesterEmail
                                                }
                                                key={JSON.stringify(
                                                    submitRequestForm?.IsPrimaryContact
                                                )}
                                                autoComplete="off"
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={16}>
                                            <ATMField
                                                name="confirmYourEmailAddress"
                                                as={ATMInput}
                                                label={
                                                    Lang.LBL_CONFIRM_YOUR_EMAIL_ADDRESS
                                                }
                                                placeholder={
                                                    Lang.PLC_CONFIRM_OFFICIAL_EMAIL_ADDRESS
                                                }
                                                onBlur={(e) =>
                                                    handleChange(
                                                        'confirmYourEmailAddress',
                                                        e.target.value
                                                    )
                                                }
                                                fluid
                                                required
                                                data-testid="confirmYourEmailAddress"
                                                className={styles.fieldStyle}
                                                defaultValue={
                                                    submitRequestForm.confirmYourEmailAddress
                                                }
                                                error={
                                                    submitRequestErrors.confirmYourEmailAddress
                                                }
                                                key={JSON.stringify(
                                                    submitRequestForm?.IsPrimaryContact
                                                )}
                                                autoComplete="off"
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    <ATMDivider className={styles.divider} />
                                </ATMGrid>
                            )}

                            {/* Faculty Contact - Academic Researcher is selected in "Type of Organization*/}
                            {submitRequestForm?.OrganizationType ===
                                'Academic Researcher' && (
                                <ATMGrid className={styles.gridStyle}>
                                    <ATMHeader
                                        as="h2"
                                        className={styles.headerStyle}
                                    >
                                        <SupervisedUserCircle />
                                        Faculty Contact
                                    </ATMHeader>

                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={16}>
                                            <ATMField
                                                name="AccreditedInstituteName"
                                                key={JSON.stringify(
                                                    submitRequestForm?.OrganizationType
                                                )}
                                                as={ATMInput}
                                                label={
                                                    Lang.LBL_ACCREDITED_INST_SPONSERING_RESEARCH
                                                }
                                                placeholder={
                                                    Lang.PLC_UNIVERSITY_SCHOOL_NAME
                                                }
                                                onBlur={(e) =>
                                                    handleChange(
                                                        'AccreditedInstituteName',
                                                        e.target.value
                                                    )
                                                }
                                                fluid
                                                required
                                                data-testid="AccreditedInstituteName"
                                                className={styles.fieldStyle}
                                                restrictAngularBrackets
                                                maxLength={60}
                                                minLength={2}
                                                error={
                                                    submitRequestErrors.AccreditedInstituteName
                                                }
                                                defaultValue={
                                                    submitRequestForm.AccreditedInstituteName
                                                }
                                                noSpecialCharacters={true}
                                                autoComplete="off"
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles['ATMHeader']}
                                                as="h3"
                                            >
                                                <p
                                                    className={
                                                        styles.paragraphLabel
                                                    }
                                                >
                                                    {
                                                        Lang.LBL_FACULTY_MEMBER_OF_INST
                                                    }
                                                </p>
                                                <p className={styles.indicator}>
                                                    &nbsp; *
                                                </p>
                                            </ATMHeader>
                                            <div
                                                className={
                                                    styles.primaryContactRadioButtons
                                                }
                                            >
                                                {radioToggleOptions?.map(
                                                    (label, index) => (
                                                        <ATMRadio
                                                            key={index}
                                                            label={label}
                                                            onChange={
                                                                handleRadioButtonChange
                                                            }
                                                            checked={
                                                                submitRequestForm?.IsFacultyMember ===
                                                                `${label}`
                                                            }
                                                            value={`${label}`}
                                                            name={`IsFacultyMember`}
                                                            autoComplete="off"
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    {submitRequestErrors?.IsFacultyMember && (
                                        <FormField className="sempraMediumPaddingBottom">
                                            <p className={styles.errorText}>
                                                <WarningRounded />
                                                This is a required field.
                                            </p>
                                        </FormField>
                                    )}

                                    {/* this will be visible when "Are you a faculty member at inst -> NO" */}
                                    {submitRequestForm?.IsFacultyMember ===
                                        'No' && (
                                        <ATMGrid.Row>
                                            <ATMGrid.Column width={16}>
                                                <ATMField
                                                    name="FacultyMemberSponsored"
                                                    as={ATMInput}
                                                    label={
                                                        Lang.LBL_FACULTY_MEMBER_SPONSORING
                                                    }
                                                    placeholder={Lang.PLC_NAME}
                                                    onBlur={(e) =>
                                                        handleChange(
                                                            'FacultyMemberSponsored',
                                                            e.target.value
                                                        )
                                                    }
                                                    fluid
                                                    required
                                                    data-testid="FacultyMemberSponsored"
                                                    className={
                                                        styles.fieldStyle
                                                    }
                                                    defaultValue={
                                                        submitRequestForm.FacultyMemberSponsored
                                                    }
                                                    restrictAngularBrackets
                                                    maxLength={50}
                                                    minLength={2}
                                                    error={
                                                        submitRequestErrors.FacultyMemberSponsored
                                                    }
                                                    noSpecialCharacters={true}
                                                    autoComplete="off"
                                                />
                                            </ATMGrid.Column>
                                        </ATMGrid.Row>
                                    )}

                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={16}>
                                            <ATMField
                                                name="AcademicDepartment"
                                                as={ATMInput}
                                                label={Lang.LBL_DEPT_JOB_TITLE}
                                                placeholder={
                                                    Lang.PLC_DEPT_JOB_TITLE
                                                }
                                                onBlur={(e) =>
                                                    handleChange(
                                                        'AcademicDepartment',
                                                        e.target.value
                                                    )
                                                }
                                                fluid
                                                required
                                                data-testid="AcademicDepartment"
                                                className={styles.fieldStyle}
                                                defaultValue={
                                                    submitRequestForm.AcademicDepartment
                                                }
                                                restrictAngularBrackets
                                                maxLength={50}
                                                minLength={2}
                                                error={
                                                    submitRequestErrors.AcademicDepartment
                                                }
                                                noSpecialCharacters={true}
                                                autoComplete="off"
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    <ATMDivider className={styles.divider} />
                                </ATMGrid>
                            )}

                            {/* Contact at SoCalGas */}
                            <ATMGrid className={styles.gridStyle}>
                                <ATMHeader
                                    as="h2"
                                    className={styles.headerStyle}
                                >
                                    <ContactPhone />
                                    Contact at SoCalGas
                                </ATMHeader>

                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        computer={8}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMField
                                            name="SoCalGasContactName"
                                            as={ATMInput}
                                            label={Lang.LBL_NAME}
                                            placeholder={Lang.PLC_NAME}
                                            onBlur={(e) =>
                                                handleChange(
                                                    'SoCalGasContactName',
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                submitRequestForm.SoCalGasContactName
                                            }
                                            fluid
                                            data-testid="SoCalGasContactName"
                                            className={styles.fieldStyle}
                                            restrictAngularBrackets
                                            maxLength={100}
                                            noSpecialCharacters={true}
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>
                                    <ATMGrid.Column
                                        computer={8}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <ATMField
                                            name="SocalGasContactEmail"
                                            as={ATMInput}
                                            label={Lang.LBL_EMAIL_ADDRESS}
                                            placeholder={
                                                Lang.PLC_OFFICIAL_EMAIL_ADDRESS
                                            }
                                            onBlur={(e) =>
                                                handleChange(
                                                    'SocalGasContactEmail',
                                                    e.target.value
                                                )
                                            }
                                            defaultValue={
                                                submitRequestForm.SocalGasContactEmail
                                            }
                                            fluid
                                            data-testid="SocalGasContactEmail"
                                            className={styles.fieldStyle}
                                            restrictAngularBrackets
                                            maxLength={50}
                                            error={
                                                submitRequestErrors.SocalGasContactEmail
                                            }
                                            autoComplete="off"
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>

                                <ATMDivider className={styles.divider} />
                            </ATMGrid>
                        </>
                    )}
                </div>
            )}
        </ATMSegment>
    );
};

export default Organization;
