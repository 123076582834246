import React from 'react';
import { ATMForm } from '../atoms/ATMForm/ATMForm.component';

export function CustomCheckbox({ value, onChange, ...props }) {
    return (
        <ATMForm.Field>
            <ATMForm.Checkbox
                {...props}
                defaultChecked={value}
                onChange={onChange}
                label={props.text}
            />
        </ATMForm.Field>
    );
}
