import React, { useEffect, useState } from 'react';
import HeaderSection from 'src/components/common/HeaderSection/headerSection.component';
import { ATMHeader } from 'src/components/common/atoms/ATMHeader/ATMHeader.component';
import styles from './DataRequestLog.module.scss';
import Lang from 'src/libraries/language';
import { ATMGrid } from 'src/components/common/atoms/ATMGrid/ATMGrid.component';
import {
    IORGDataTableColumn,
    ORGDataTable,
} from 'src/components/common/organisms/ORGDataTable/ORGDataTable.component';
import {
    noDataFound,
    requestorTypeOptions,
    statusOptions,
    typeOfOrganizations,
} from 'src/constants/home';
import { ATMLabel } from 'src/components/common/atoms/ATMLabel/ATMLabel.component';
import {
    Cached,
    Close,
    DoNotDisturb,
    DoneAll,
    InfoOutlined,
    LibraryBooks,
    RemoveRedEye,
} from '@mui/icons-material';
import { FormField, Grid, Input } from 'semantic-ui-react';
import { ATMDropdown } from 'src/components/common/atoms/ATMDropdown/ATMDropdown.component';
import { ATMButton } from 'src/components/common/atoms/ATMButton/ATMButton.component';
import CustomModal from 'src/components/common/CustomModal/CustomModal.component';
import { textTruncate } from 'src/utils/common.utils';
import useFetchDataRequestLog from 'src/hooks/useFetchDataRequestLog';
import { useNotificationDispatch } from 'src/contexts/notification.context';
import moment from 'moment';
const limit = 40;

const DataRequestLog: React.FC = () => {
    const [searchValue, setSearchValue] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedRequestorType, setSelectedRequestorType] = useState('');
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [logData, setLogData] = useState<any>([]);
    const [staticLogData, setStaticLogData] = useState<any>([]);
    const [moreInfo, setMoreInfo] = useState(false);
    const [viewMoreObj, setViewMoreObj] = useState({});
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const notificationDispatch = useNotificationDispatch();

    const {
        mutate: getDataRequestLogs,
        data: dataRequestLogsData,
        isLoading: dataRequestLogLoading,
        isError: dataRequestLogError,
    } = useFetchDataRequestLog();

    useEffect(() => {
        getDataRequestLogs({});
    }, []);

    useEffect(() => {
        if (!!searchValue || !!selectedStatus || !!selectedRequestorType) {
            setButtonsDisabled(true);
        } else {
            setLogData(staticLogData);
            setButtonsDisabled(false);
            setSelectedValues([]);
        }
    }, [searchValue, selectedStatus, selectedRequestorType]);

    useEffect(() => {
        if (dataRequestLogsData && dataRequestLogsData?.data) {
            const response = JSON.parse(dataRequestLogsData?.data?.body);

            const responseData = response
                .map((ele) => {
                    return {
                        RequestId: ele?.RequestId,
                        DateSubmitted: ele?.DateSubmitted,
                        RequestorType: typeOfOrganizations?.includes(ele?.RequestorType) ? ele?.RequestorType : 'Others',
                        RequestorName: ele?.RequestorName,
                        RequestDescription: ele?.RequestDescription,
                        Status: ele?.Status,
                        StatusModifiedDate: ele?.StatusModifiedDate,
                    };
                })
                .sort((a, b) => b.RequestId - a.RequestId);

            setStaticLogData(responseData);
            setLogData(responseData);

            response?.map((item) => {
                setViewMoreObj((prevState) => ({
                    ...prevState,
                    [item?.RequestId]: {
                        RequestorName: false,
                        RequestDescription: false,
                    },
                }));
            });
        }
    }, [dataRequestLogsData]);

    useEffect(() => {
        if (dataRequestLogError) {
            notificationDispatch({
                type: 'ERROR',
                payload: Lang.MSG_API_500,
            });
        }
    }, [dataRequestLogError]);

    const handleSearchData = () => {
        if (buttonsDisabled) {
            const filteredData = staticLogData.filter((item) => {
                const searchValueMatch =
                    (searchValue != '' &&
                        item?.RequestId?.toString()
                            ?.toLowerCase()
                            ?.includes(
                                searchValue?.toString()?.toLowerCase()
                            )) ||
                    item?.RequestorName?.toString()
                        ?.toLowerCase()
                        ?.includes(searchValue?.toString()?.toLowerCase()) ||
                    item?.RequestDescription?.toString()
                        ?.toLowerCase()
                        ?.includes(searchValue?.toString()?.toLowerCase());

                const requestorTypeMatch =
                    item?.RequestorType?.toString()?.toLowerCase() ==
                    selectedRequestorType?.toLowerCase();

                const statusMatch =
                    item?.Status?.toString()?.toLowerCase() ==
                    selectedStatus?.toLowerCase();

                return (
                    (searchValueMatch || !searchValue) &&
                    (requestorTypeMatch || !selectedRequestorType) &&
                    (statusMatch || !selectedStatus)
                );
            });

            const tempSelection = [] as any[];
            !!searchValue && tempSelection.push(searchValue);
            !!selectedStatus && tempSelection.push(selectedStatus);
            !!selectedRequestorType &&
                tempSelection.push(selectedRequestorType);
            setSelectedValues(tempSelection);
            setLogData(filteredData);
        }
    };

    const handleClearInputs = () => {
        setSearchValue('');
        setSelectedStatus('');
        setSelectedRequestorType('');
        setSelectedValues([]);
        setLogData(staticLogData);
    };

    const statusHandler = (status) => {
        let result = null as any;

        if (status == 'In Review') {
            result = (
                <ATMLabel
                    customcolor="lightblue"
                    className={styles.statusLabel}
                >
                    <RemoveRedEye sx={{ fontSize: 15 }} color="primary" />
                    In Review
                </ATMLabel>
            );
        } else if (status == 'In Progress') {
            result = (
                <ATMLabel
                    customcolor="lightgrey"
                    className={styles.statusLabel}
                >
                    <Cached sx={{ fontSize: 15 }} />
                    In Progress
                </ATMLabel>
            );
        } else if (status == 'Completed') {
            result = (
                <ATMLabel
                    customcolor="lightteal"
                    className={styles.statusLabel}
                >
                    <DoneAll sx={{ fontSize: 15 }} color="action" />
                    Completed
                </ATMLabel>
            );
        } else if (status == 'Cancelled') {
            result = (
                <ATMLabel
                    customcolor="lightyellow"
                    className={styles.statusLabel}
                >
                    <Close sx={{ fontSize: 15 }} color="warning" />
                    Cancelled
                </ATMLabel>
            );
        } else if (status == 'Denied') {
            result = (
                <ATMLabel customcolor="lightred" className={styles.statusLabel}>
                    <DoNotDisturb sx={{ fontSize: 15 }} color="error" />
                    Denied
                </ATMLabel>
            );
        } else {
            result = (
                <ATMLabel
                    customcolor="lightgreen"
                    className={styles.statusLabel}
                >
                    <LibraryBooks sx={{ fontSize: 15 }} color="success" />
                    {status}
                </ATMLabel>
            );
        }
        return result;
    };

    const dataRequestLogColumns: IORGDataTableColumn<any>[] = [
        {
            title: 'Request ID',
            index: 'RequestId',
            width: '10%',
            sortable: true,
            render: (_, record) =>
                record?.RequestId && <div>{record?.RequestId}</div>,
        },
        {
            title: 'Date Submitted',
            index: 'DateSubmitted',
            width: '10%',
            sortable: true,
            render: (_, record) => (
                <div>{moment(record?.DateSubmitted).format('DD-MM-YYYY')}</div>
            ),
        },
        {
            title: 'Requestor Type',
            index: 'RequestorType',
            width: '10%',
            sortable: true,
            render: (_, record) => <div>{record?.RequestorType}</div>,
        },
        {
            title: 'Requestor Name',
            index: 'RequestorName',
            width: '30%',
            sortable: false,
            render: (_, record) => (
                <div>
                    <span>
                        <p className={styles.infoContent}>
                            {Object.keys(viewMoreObj)?.length > 0 &&
                            viewMoreObj[`${record?.RequestId}`][`RequestorName`]
                                ? record?.RequestorName
                                : textTruncate(record?.RequestorName, limit)}
                        </p>
                    </span>
                    {record?.RequestorName &&
                        record?.RequestorName?.length > limit && (
                            <a
                                href="#view"
                                className="sempraPrimaryLink"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setViewMoreObj((prevState) => ({
                                        ...prevState,
                                        [`${record?.RequestId}`]: {
                                            ...prevState[
                                                `${record?.RequestId}`
                                            ],

                                            RequestorName:
                                                !prevState[
                                                    `${record?.RequestId}`
                                                ].RequestorName,
                                        },
                                    }));
                                }}
                            >
                                {Object.keys(viewMoreObj)?.length > 0 &&
                                viewMoreObj[`${record?.RequestId}`][
                                    `RequestorName`
                                ]
                                    ? 'Less'
                                    : 'More'}
                            </a>
                        )}
                </div>
            ),
        },
        {
            title: 'Description',
            index: 'RequestDescription',
            width: '30%',
            sortable: false,
            render: (_, record) => (
                <div>
                    <span>
                        <p className={styles.infoContent}>
                            {Object.keys(viewMoreObj)?.length > 0 &&
                            viewMoreObj[`${record?.RequestId}`][
                                `RequestDescription`
                            ]
                                ? record?.RequestDescription
                                : textTruncate(
                                      record?.RequestDescription,
                                      limit
                                  )}
                        </p>
                    </span>
                    {record?.RequestDescription &&
                        record?.RequestDescription?.length > limit && (
                            <a
                                href="#view"
                                className="sempraPrimaryLink"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setViewMoreObj((prevState) => ({
                                        ...prevState,
                                        [`${record?.RequestId}`]: {
                                            ...prevState[
                                                `${record?.RequestId}`
                                            ],
                                            RequestDescription:
                                                !prevState[
                                                    `${record?.RequestId}`
                                                ].RequestDescription,
                                        },
                                    }));
                                }}
                            >
                                {Object.keys(viewMoreObj)?.length > 0 &&
                                viewMoreObj[`${record?.RequestId}`][
                                    `RequestDescription`
                                ]
                                    ? 'Less'
                                    : 'More'}
                            </a>
                        )}
                </div>
            ),
        },
        {
            title: 'Status',
            index: 'Status',
            width: '10%',
            sortable: true,
            render: (_, record) => (
                <div>
                    <>{statusHandler(record?.Status)}</>
                    <p className={styles.statusModifiedDate}>
                        {moment(record?.StatusModifiedDate).format(
                            'DD-MM-YYYY'
                        )}
                    </p>
                </div>
            ),
        },
    ];

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                        {/* Breadcrumb section */}
                        <HeaderSection sections={'Data Request Log'} />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <div className={styles.headerTitleStyle}>
                        <ATMHeader className={styles.header}>
                            {Lang.LBL_DATA_REQ_LOG}
                        </ATMHeader>
                        <span>
                            <p className={styles.indicatorParagrapgh}>
                                {Lang.MSG_DATA_REQUEST_LOG_DESC}
                                <a
                                    className={styles.dataReqMessage}
                                    href="mailto:energydatarequest@socalgas.com"
                                >
                                    {Lang.LBL_EMAILID}
                                </a>
                            </p>
                        </span>
                    </div>
                </Grid.Row>

                {/* Table section */}
                <Grid.Row
                    className={`${styles.tableSpacing} ${styles.displayBlock}`}
                >
                    <ORGDataTable
                        columns={dataRequestLogColumns}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        defaultRowsPerPage={10}
                        data={logData}
                        sortable={true}
                        history={true}
                        allButtonSize="small"
                        noDataText={noDataFound}
                        showPagination={true}
                        toolbars={[
                            () => (
                                <FormField className={styles.tableFilters}>
                                    <label>{Lang.LBL_SEARCH}</label>
                                    <Input
                                        name="searchDataRequestLog"
                                        icon="search"
                                        iconPosition="left"
                                        data-testid="searchInputField"
                                        placeholder={
                                            Lang.PLC_SEARCH_DATA_REQ_LOG
                                        }
                                        onChange={(data) => {
                                            setSearchValue(data.target.value);
                                            return data.target.value;
                                        }}
                                        value={searchValue}
                                        className={styles.fieldWidth}
                                        clearable
                                    />
                                </FormField>
                            ),
                            () => (
                                <FormField className={styles.tableFilters}>
                                    <label>{Lang.LBL_STATUS}</label>
                                    <ATMDropdown
                                        name="status"
                                        selection
                                        search
                                        size="tiny"
                                        data-testid="status"
                                        placeholder={Lang.LBL_STATUS}
                                        options={statusOptions}
                                        value={selectedStatus}
                                        clearable
                                        onChange={(e, data) => {
                                            setSelectedStatus(
                                                data.value as string
                                            );
                                            return data.value;
                                        }}
                                        selectOnBlur={false}
                                        className={styles.fieldWidth}
                                    />
                                </FormField>
                            ),
                            () => (
                                <FormField className={styles.tableFilters}>
                                    <label>{Lang.LBL_REQUESTOR_TYPE}</label>
                                    <ATMDropdown
                                        name="requestorType"
                                        selection
                                        search
                                        size="tiny"
                                        data-testid="requestorType"
                                        placeholder={Lang.LBL_REQUESTOR_TYPE}
                                        options={requestorTypeOptions}
                                        value={selectedRequestorType}
                                        clearable
                                        onChange={(e, data) => {
                                            setSelectedRequestorType(
                                                data.value as string
                                            );
                                            return data.value;
                                        }}
                                        selectOnBlur={false}
                                        className={styles.fieldWidth}
                                    />
                                </FormField>
                            ),
                            () => (
                                <ATMButton
                                    content="Search"
                                    className={
                                        buttonsDisabled
                                            ? [
                                                  styles.searchButton,
                                                  styles.buttons,
                                              ].join(' ')
                                            : [styles.buttons].join(' ')
                                    }
                                    onClick={handleSearchData}
                                    disabled={!buttonsDisabled}
                                    data-testid="searchButton"
                                />
                            ),
                            () => (
                                <ATMButton
                                    content="Clear All"
                                    className={
                                        buttonsDisabled
                                            ? [
                                                  styles.searchButton,
                                                  styles.buttons,
                                              ].join(' ')
                                            : [styles.buttons].join(' ')
                                    }
                                    onClick={handleClearInputs}
                                    disabled={!buttonsDisabled}
                                />
                            ),
                            () => (
                                <InfoOutlined
                                    onClick={() => {
                                        setMoreInfo(!moreInfo);
                                    }}
                                    className={styles.crossIcon}
                                />
                            ),
                        ]}
                        className={styles.tableFiltersRow}
                        tableLabel={
                            selectedValues.length ? (
                                <div className="customFilter">
                                    Filtered by:{' '}
                                    {selectedValues?.map((item, index) => (
                                        <strong key={item}>
                                            {(index ? ', ' : '') + item}
                                        </strong>
                                    ))}
                                </div>
                            ) : (
                                ''
                            )
                        }
                        loading={dataRequestLogLoading}
                    />
                </Grid.Row>
            </Grid>

            {moreInfo && (
                // More Info Modal
                <CustomModal
                    modalOpen={moreInfo}
                    setModalClose={setMoreInfo}
                    header="More Info"
                    content={
                        <ATMGrid>
                            <ATMGrid.Row
                                columns="equal"
                                verticalAlign="middle"
                                className="sempraTinyPaddingBottom sempraTinyPaddingTop"
                            >
                                <ATMGrid.Column width={4}>
                                    <ATMLabel
                                        customcolor="lightblue"
                                        className={styles.statusLabel}
                                    >
                                        <RemoveRedEye sx={{ fontSize: 15 }} />
                                        In Review
                                    </ATMLabel>
                                </ATMGrid.Column>
                                <ATMGrid.Column width={12}>
                                    <p className={styles.infoContent}>
                                        Request is being reviewed.
                                    </p>
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row
                                columns="equal"
                                verticalAlign="middle"
                                className="sempraTinyPaddingBottom sempraTinyPaddingTop"
                            >
                                <ATMGrid.Column width={4}>
                                    <ATMLabel
                                        customcolor="lightgrey"
                                        className={styles.statusLabel}
                                    >
                                        <Cached sx={{ fontSize: 15 }} />
                                        In Progress
                                    </ATMLabel>
                                </ATMGrid.Column>
                                <ATMGrid.Column width={12}>
                                    <p className={styles.infoContent}>
                                        Request is in process of being
                                        fulfilled.
                                    </p>
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row
                                columns="equal"
                                verticalAlign="middle"
                                className="sempraTinyPaddingBottom sempraTinyPaddingTop"
                            >
                                <ATMGrid.Column width={4}>
                                    <ATMLabel
                                        customcolor="lightteal"
                                        className={styles.statusLabel}
                                    >
                                        <DoneAll sx={{ fontSize: 15 }} />
                                        Completed
                                    </ATMLabel>
                                </ATMGrid.Column>
                                <ATMGrid.Column width={12}>
                                    <p className={styles.infoContent}>
                                        Request has been fulfilled.
                                    </p>
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row
                                columns="equal"
                                verticalAlign="middle"
                                className="sempraTinyPaddingBottom sempraTinyPaddingTop"
                            >
                                <ATMGrid.Column width={4}>
                                    <ATMLabel
                                        customcolor="lightyellow"
                                        className={styles.statusLabel}
                                    >
                                        <Close sx={{ fontSize: 15 }} />
                                        Cancelled
                                    </ATMLabel>
                                </ATMGrid.Column>
                                <ATMGrid.Column width={12}>
                                    <p className={styles.infoContent}>
                                        Request has been cancelled.
                                    </p>
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <ATMGrid.Row
                                columns="equal"
                                verticalAlign="middle"
                                className="sempraTinyPaddingBottom sempraTinyPaddingTop"
                            >
                                <ATMGrid.Column width={4}>
                                    <ATMLabel
                                        customcolor="lightred"
                                        className={styles.statusLabel}
                                    >
                                        <DoNotDisturb sx={{ fontSize: 15 }} />
                                        Denied
                                    </ATMLabel>
                                </ATMGrid.Column>
                                <ATMGrid.Column width={12}>
                                    <p className={styles.infoContent}>
                                        Request has been denied.
                                    </p>
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                        </ATMGrid>
                    }
                />
            )}
        </>
    );
};

export default DataRequestLog;
