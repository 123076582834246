export default {
    PLC_SEARCH: "Search",
    PLC_SELECT: "Select",
    PLC_ORGANIZATION_NAME: "eg. MIT, Georgia Tech...",
    PLC_ADDRESS_LINE1: "eg. 14, street number, block number...",
    PLC_ADDRESS_LINE2: "eg. landmark etc.",
    PLC_CITY: "eg. Philadelphia, Buffalo, etc.",
    PLC_ZIP_CODE: "eg. 65432",
    PLC_COUNTY: "eg. LA",
    PLC_EMPLOYER_IDENTIFICATION_NUMBER: "eg. 12-1234567",
    PLC_OTHERS: "eg. school student etc.",
    PLC_TITLE_ROLE: "eg. Head of Department, Energy Sciences",
    PLC_NAME: "eg. John Doe",
    PLC_OFFICIAL_EMAIL_ADDRESS: "eg. John.Doe@institue.com",
    PLC_COMPANY: "eg. xyz company",
    PLC_CONFIRM_OFFICIAL_EMAIL_ADDRESS: "Please re-enter your email again",
    PLC_PLEASE_ENTER_TEXT_HERE: "Please enter text here",
    PLC_PLEASE_ENTER_DETAILS_HERE: "Please enter Details here",
    PLC_SELECT_START_DATE: "Select start Date",
    PLC_SELECT_END_DATE: "Select end Date",
    PLC_SELECT_YOUR_STATE: "Select your state",
    PLC_PHONE_NUMBER: "eg. +11-1234567899",
    PLC_UNIVERSITY_SCHOOL_NAME: "University / School Name",
    PLC_DEPT_JOB_TITLE: "Energy Resources",
    PLC_SEARCH_DATA_REQ_LOG: "ID, Name, Description",
    PLC_SELECT_DATE: "Select Date",
};

