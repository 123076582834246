export const restrictAngularBracketSymbols = (value) => {
    const newValue = value?.toString().replace(/[<>]/g, '');
    return newValue;
};

export const restrictDecimalSymbol = (value) => {
    const newValue = value?.toString().replace(/[.]/g, '');
    return newValue;
};

export const restrictNegativeSignSymbol = (value) => {
    const newValue = value?.toString().replace(/[-]/g, '');
    return newValue;
};

export const restrictAlphabetsAndSpclChar = (value) => {
    const newValue = value
        ?.toString()
        .replace(/[a-zA-Z&,!@#$%^&*()_+=:;""?/{}|]/g, '');
    return newValue;
};

export const restrictSpecialCharacters = (value) => {
    const newValue = value?.toString().replace(/[^a-zA-Z0-9,.;/ ]/g, '');
    return newValue;
    // this allows alphanumeric along with only allowable special characters
};
