export default {
    MSG_QUARTERLY_DATA: `Quarterly reports on gas usage by ZIP Code are available below.
     Each report includes number of customers, therms billed and average therms per customer by ZIP Code.
     Select the year range and click the link to download the quarterly report.`,
    MSG_SUBMIT_A_REQUEST: `Organizations such as local governments, academic researchers, 
    state and federal agencies, and community services 
    & development may request energy usage data from SoCalGas.`,
    MSG_EDRP_WORKSHEET: `An offline worksheet is available for 
    download to assist in gathering the required information 
    for the online request form. Once ready, the information 
    from the worksheet can be transferred to the online form for submission.`,
    MSG_RESEND_LINK: `To have the email verification link resent, 
    please select the 'Enter Email' option below.`,
    MSG_DATA_REQUEST_LOG:
        'Access the log to view current and past energy data requests that have been submitted to SoCalGas.',
    MSG_DATA_REQUEST_LOG_DESC: `Southern California Gas Company has received the following data requests from third parties. 
    If you have a question regarding a data request you have submitted, you may contact us at `,
    MSG_SUBMIT_REQ_SUCCESS:
        'Congratulations your request has been successfully submitted.',
    MSG_SUBMIT_REQ_SUCCESS_MSG: `A unique Request ID # has been generated for your reference. Kindly check your inbox, 
    junk, or spam folder to verify the email.`,

    MSG_EMAIL_VERIFICATION_SUCCESS:
        'Congratulations your email has been successfully verified.',
    MSG_EMAIL_VERIFICATION_SUCCESS_REPEAT:
        'Email already verified',
    MSG_EMAIL_VERIFICATION_SUCCESS_MSG: `Please click on exit or (X) to return back to the application.`,

    MSG_INFORMATION_HEADER: `The Energy Data Request Program (EDRP) is an offering from SoCalGas® that provides 
    specific third parties; such as local governments, state/federal agencies, community service development 
    organizations and researchers of accredited academic institutions; access to energy usage and usage-related data.`,

    MSG_CALIFORNIA_USAGE: `California law stipulates researchers seeking customer information must demonstrate that
    their research advances California energy use and conservation.`,

    MSG_ACCESS_READILY_1: `To ensure the wide availability of certain
    types of energy data that is both releasable
    and of interest to the public, SoCalGas
    meets the aggregation standard set forth by
    the CPUC without requiring a non-disclosure
    agreement, and posts this data on its
    publicly available website.`,

    MSG_ACCESS_READILY_2: `Posted quarterly reports contain the total
    monthly sum and average of customer gas
    usage by ZIP Code and customer class
    (residential, commercial, and industrial).
    These reports follow CPUC procedures
    regarding aggregation rules that protect the
    privacy of the customer.`,

    MSG_ACCESS_READILY_3: `They can be downloaded using Excel and
    stored for your convenience. These readily
    available and aggregated gas usage reports
    can be accessed on the company website at:`,

    MSG_COMMON_EMAIL_ADDRESS: `https://energydatarequest.socalgas.com`,

    MSG_ONLINE_SUBMISSION_1: `If the aggregated reports online do not meet
    your needs, you can submit a request for a
    custom data report. Going forward, all
    custom data requests under this program must
    be submitted using the online request form
    located at:`,

    MSG_ONLINE_SUBMISSION_2: `SoCalGas will post a data request log online
    that will allow requestors to see the status
    of their submitted requests.`,

    MSG_THINGS_TO_KNOW: `Requesting parties must note that any data
    received must be used for the purpose
    specified in the request, and is not
    permitted to be shared with another third
    party or publicly disclosed. If the customer
    is using the services of a third party, the
    third party will need to comply and complete
    a non-disclosure agreement document as part
    of the data request approval process.`,

    MSG_TIME_FRAMES_1: `Within seven business days of a request for
    custom data, a representative from SoCalGas
    will contact the requesting party to confirm
    that all information needed was provided in
    the request. If the request is incomplete, a
    SoCalGas representative will inform them of
    what additional information is required to
    process the request.`,

    MSG_TIME_FRAMES_2: `Once SoCalGas has received all the required
    information, 15 business days are needed to
    review and determine if the request can be
    fulfilled.`,

    MSG_TIME_FRAMES_3_1: `If the request is approved, SoCalGas
    will notify the CPUC executive director,
    and communicate a proposed fulfillment
    date to the requestor.`,

    MSG_TIME_FRAMES_3_2: `SoCalGas
    must wait a minimum of four weeks from
    the date the CPUC executive director is
    notified before the data may be
    transferred to the requestor.`,

    MSG_TIME_FRAMES_3_3: `If the request is not approved, SoCalGas
    will provide reasons and or offer other
    options`,

    MSG_AGGREGATION_1: `Below are aggregation and anonymization
    standards set forth by the CPUC for custom
    reports requested through the EDRP:`,

    MSG_AGGREGATION_2: `15/20 Rule: Aggregated over a group
    consisting of 15 customers in a single
    customer class. No single customer
    accounts for more than 20 percent of the
    total energy consumption in an
    individual month. Such data should be
    aggregated to at least the monthly
    level.`,

    MSG_AGGREGATION_3: `5/25 Rule: Aggregated over a group
    consisting of five customers in a single
    customer class. No single customer
    accounts for more than 25 percent of the
    total energy consumption in an
    individual month. Such data should be
    aggregated to at least the monthly
    level.`,

    MSG_AGGREGATION_4: `100/10 Rule: Anonymized over a group
    consisting of 100 accounts in a single
    customer class. No single account
    amounts to more than 10 percent of the
    total energy consumption in an
    individual month. Such data should be
    aggregated to at least the monthly
    level.`,

    MSG_PROGRAM_BENEFITS: `The CPUC envisions a future in which the
    increased availability of energy usage data
    will better enable California policymakers
    to promote energy efficiency, predict demand
    response, reduce greenhouse emissions and
    improve the California economy. Data can
    also enable new and innovative services and
    offerings for customers.`,

    MSG_PRIVACY1 : `NIST 800-53 R5 is a comprehensive set of
    security controls to ensure that
    sensitive information is safeguarded
    appropriately. NIST 800-53 includes the
    following families of controls that
    third parties should expect to adhere to
    before receiving consumer information:`,

    MSG_PRIVACY2: `Southern California Gas Company values your privacy. 
    For more information, click here`,

    MSG_CONTACT_US: `If you have any questions about the EDRP at
    SoCalGas, please send an email to the
    following email address:`,

    MSG_HOME_ADDITIONAL_INFO: `This page contains reports of quarterly gas usage in our service
     territory by ZIP Code. SoCalGas® can also supply more specific reports to qualifying 
     academic researchers, local government entities or state and federal agencies`,

    MSG_ERROR_REQUIRED: 'This field is required',
    MSG_HTTP_ERROR_BAD_REQUEST: 'Request failed with status code {0}',
    MSG_HTTP_ERROR_REQUEST_CANCELLED: 'The request was cancelled',
    MSG_TOAST_SUCCESS: 'Changes saved successfully',
    MSG_TOAST_ERROR: 'An error occurred while saving changes',
    MSG_TOAST_FETCHING_ERROR: 'An error occured while fetching data',
    MSG_ERROR_PDF_DOWNLOAD:
        'Something went wrong while downloading PDF. Please try again',
    MSG_USER_DELETE_SUCCESS: 'The user was deleted successfully',
    MSG_TOAST_ERROR_SEARCH: 'An error occurred while searching keyword',
    MSG_TOAST_DUPLICATE: 'Name already present as archived',
    MSG_API_500: 'The data cannot be fetched. Please try again later',
    MSG_RECAPTCHA_FAILED: 'Google Recaptcha Failed, Please try again!',

    // Resources tab
    MSG_ACADEMIC_RESEARCHER_1: `Institutional Review Board Approval`,

    MSG_ACADEMIC_RESEARCHER_1_1: `IRB approval from an administrator or institutional officer is required`,
    MSG_ACADEMIC_RESEARCHER_1_2: `Exempt researchers can access our publicly available usage data files.`,
    MSG_ACADEMIC_RESEARCHER_1_3: `Expedited IRB approval is needed from a signed administrator 
    for customer-specific data.`,
    MSG_ACADEMIC_RESEARCHER_1_4: `Full Committee IRB approval is required from a signed 
    administrator for customer-specific data.,`,

    MSG_ACADEMIC_RESEARCHER_2: `Statement of Purpose (SOP)`,
    MSG_ACADEMIC_RESEARCHER_2_1: `A Statement of Purpose (SOP) is also required from qualified researchers. 
    Please provide a concise explanation of your intended use of the energy usage data.`,

    MSG_ACADEMIC_RESEARCHER_3: `Non-Disclosure Agreement (NDA) `,
    MSG_ACADEMIC_RESEARCHER_3_1: `Qualified researchers seeking energy usage data must sign a standard 
    Non-Disclosure Agreement (NDA) before accessing the information. 
    Department directors or higher-level faculty members are required to execute the NDA. 
    Please click the button below to download, sign, and return the document.`,

    MSG_ACADEMIC_RESEARCHER_4: `Any requests for modifications to the language of the Non-Disclosure Agreement (NDA)
     or Terms of Service (ToS) will not be accommodated.`,

    MSG_LOCAL_GOVERNMENT_1: `If data is requested directly by Local Governments:`,

    MSG_LOCAL_GOVERNMENT_1_1: `Terms of Service Agreement (TOS)`,

    MSG_LOCAL_GOVERNMENT_2: `If data is shared with agents/consultants/external representatives of Local Government:`,
    MSG_LOCAL_GOVERNMENT_2_1: `Terms of Service Agreement (TOS)`,
    MSG_LOCAL_GOVERNMENT_2_2: `Non-Disclosure Agreement (NDA)`,

    MSG_LOCAL_GOVERNMENT_3: `If agents/consultants/external representatives are acting on behalf of Local Government:`,
    MSG_LOCAL_GOVERNMENT_3_1: `Terms of Service Agreement (TOS)`,
    MSG_LOCAL_GOVERNMENT_3_2: `Contract between Local Government and consulting agent`,
    MSG_LOCAL_GOVERNMENT_3_3: `Non-Disclosure Agreement (NDA)`,

    MSG_LOCAL_GOVERNMENT_4: `Terms of Service (TOS) and Non-Disclosure Agreements (NDAs) 
     must be signed by the City Manager or another City employee of equivalent authority who is authorized to execute 
     legal documents on behalf of the City.`,

    MSG_STATE_1_1: `Provide evidence of statutory obligation to receive requested information.`,
    MSG_STATE_1_2: `Some types of requests may require a Non-Disclosure Agreement (NDA).`,

    MSG_CALIFORNIA_1_1: `Terms of Service Agreement (TOS)`,
    MSG_CALIFORNIA_1_2: `Non-Disclosure Agreement (NDA)`,

    MSG_ENERGY_INFO_1_1: `EIA possesses statutory authority to obtain energy usage data and 
      is exempt from signing an Non-Disclosure Agreement (NDA) or Terms of Service Agreement (TOS).`,


    MSG_CERTIFICATE_OF_DESTRUCTION_1: `For the preservation of customer privacy, third parties who receive 
    customer information from SoCalGas should securely dispose of this information after all business, 
    legal and regulatory purposes for retaining it have passed, and some third parties, such as service 
    providers to SoCalGas may be obligated to do so. Third parties will be asked to provide an appropriate 
    date for when the data can be securely disposed of 30 days before that date, SoCalGas will contact the 
    third party to request disposal.`,
    MSG_CERTIFICATE_OF_DESTRUCTION_2: `The Certificate of Destruction form is completed by the 
    third parties and returned to SoCalGas to provide evidence of data disposal.`,
    
    MSG_PRIMARY_CONTACT: 'The Primary contact is a legally authorized representative of the organization. If there are questions about your data request, SoCalGas will contact the Primary Contact.'
};
