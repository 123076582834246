import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Input, InputProps } from 'semantic-ui-react';
import {
    restrictAngularBracketSymbols,
    restrictDecimalSymbol,
    restrictNegativeSignSymbol,
    restrictAlphabetsAndSpclChar,
    restrictSpecialCharacters,
} from '../../../utils/security';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';
import styles from './ATMInput.module.scss';

export type IATMInputProps = InputProps & {
    isPhone?: boolean;
    isCurrency?: boolean;
    currency?: string;
    thousandSeparator?: boolean;
    isZip?: boolean;
    isEIN?: boolean;
    clearable?: boolean;
    restrictAngularBrackets?: boolean;
    restrictDecimal?: boolean;
    restrictNegativeSign?: boolean;
    tiny?: boolean;
    onlyNumber?: boolean;
};

// sizes available : mini | small | large | big | huge | massive
export const REGEX_PHONE =
    /^$|(\(\d{3}\)\s\d{3}\s-\s\d{4}|\+1\s\(\d{3}\)\s\d{3}\s-\s\d{4})$/;

export const REGEX_ZIP = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const REGEX_EIN = /(^\d{2}-\d{7}$)/;

export const formatPhoneNumber = (value = '', originalValue = '') => {
    let formattedValue = value;

    if (formattedValue.length > originalValue.length) {
        formattedValue = (value ?? '').replace(/[^\d]/g, '').trim();

        if (/^[A-Za-z]+$/.exec(formattedValue) || !formattedValue.length) {
            return '';
        }

        if (formattedValue[0] === '1') {
            formattedValue = `+1 (${formattedValue.slice(
                1,
                4
            )}) ${formattedValue.slice(4, 7)}-${formattedValue.slice(7, 11)}`;
        } else {
            formattedValue = `(${formattedValue.slice(
                0,
                3
            )}) ${formattedValue.slice(3, 6)}-${formattedValue.slice(6, 10)}`;
        }
    }

    return formattedValue;
};

export const currencyRegex: any = (value: any) => {
    let newValue = value
        ?.toString()
        .replace(/(?!\.)\D/g, '')
        .replace(/(?:\..|$)\./g, '')
        // eslint-disable-next-line security/detect-unsafe-regex
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (newValue !== undefined && newValue.includes('.')) {
        newValue = newValue.split('.');
        newValue = `${newValue[0]}.${newValue[1].substring(0, 2)}`;
    }
    return newValue;
};

export const formatCurrency: any = (value: string | number) => {
    let currencyValue = value;
    currencyValue = currencyRegex(currencyValue);

    if (typeof value === 'string' && value?.indexOf('-') > -1) {
        currencyValue = `-${currencyValue}`;
    }

    if (typeof value === 'number') {
        currencyValue = value.toString();
        if (
            typeof currencyValue === 'string' &&
            currencyValue?.indexOf('-') > -1
        ) {
            currencyValue = currencyRegex(currencyValue);
            currencyValue = `-${currencyValue}`;
        } else {
            currencyValue = currencyRegex(currencyValue);
        }
    }

    return currencyValue;
};

export const formatZipCode = (value = '') => {
    const zipCodes = value.split(',');
    const formattedZipCodes = zipCodes.map((zipCode) => {
        const trimmedZipCode = zipCode.trim();
        if (trimmedZipCode.length > 0) {
            const formattedZipCode = (trimmedZipCode ?? '')
                .replace(/[^\d]/g, '')
                .trim();
            if (
                /^[A-Za-z]+$/.exec(formattedZipCode) ||
                !formattedZipCode.length
            ) {
                return '';
            }
            if (formattedZipCode.length < 6) {
                return formattedZipCode;
            }
            return `${formattedZipCode.slice(0, 5)}-${formattedZipCode.slice(
                5,
                9
            )}`;
        }
        return '';
    });
    return formattedZipCodes.join(', ');
};

export const formatEINCode = (value = '') => {
    let formattedValue = value;

    if (formattedValue.length > 0) {
        formattedValue = (value ?? '').replace(/[^\d]/g, '').trim();

        if (/^[A-Za-z]+$/.exec(formattedValue) || !formattedValue.length) {
            return '';
        }

        if (formattedValue.length < 9) {
            return formattedValue;
        }

        formattedValue = `${formattedValue.slice(0, 2)}-${formattedValue.slice(
            2,
            9
        )}`;
    }
    return formattedValue;
};

export const ATMInput: React.FC<IATMInputProps> = ({
    isPhone,
    isCurrency,
    currency = 'US $',
    thousandSeparator,
    isZip,
    isEIN,
    clearable,
    restrictAngularBrackets = true,
    restrictDecimal = false,
    restrictNegativeSign = false,
    tiny = false,
    onlyNumber = false,
    noSpecialCharacters = false,
    ...props
}) => {
    const inputRef = useRef(null);
    const { onChange, defaultValue, value: originalValue } = props;
    const [value, setValue] = useState<string>(
        // eslint-disable-next-line no-nested-ternary
        restrictAngularBrackets
            ? restrictAngularBracketSymbols(defaultValue)
            : defaultValue || restrictAngularBrackets
            ? restrictAngularBracketSymbols(originalValue)
            : originalValue
    );

    const [clearNode, setClearNode] = useState<React.ReactNode>(<></>);

    const isCustom = isPhone || isCurrency;

    // This will handle onChange from react hook form
    const handleOnChange = useCallback(
        async (event, data, currentValue) => {
            let newVal = currentValue;

            const cursor = event.target?.selectionStart ?? undefined;

            if (onChange) {
                newVal = await onChange(event, {
                    ...data,
                    value: currentValue,
                });

                // If onChange doesn't return anything, we'll use the current value
                if (newVal === undefined) {
                    newVal = currentValue;
                }
            }

            setValue(newVal);

            // eslint-disable-next-line react/no-find-dom-node
            const element = ReactDOM.findDOMNode(inputRef.current) as Element;

            if (element && cursor !== undefined) {
                const input = element.querySelector(
                    'input'
                ) as HTMLInputElement;
                if (input) {
                    const { type } = input;
                    input.type = 'text';
                    input.selectionStart = cursor;
                    input.selectionEnd = cursor;
                    input.type = type;
                }
            }

            return newVal;
        },
        [onChange, setValue]
    );

    const handleClear = useCallback(() => {
        // eslint-disable-next-line react/no-find-dom-node
        const element = ReactDOM.findDOMNode(inputRef.current) as Element;

        if (element) {
            const input = element.querySelector('input') as HTMLInputElement;
            if (input) {
                Object.getOwnPropertyDescriptor(
                    window.HTMLInputElement.prototype,
                    'value'
                )?.set?.call(input, '');

                input.dispatchEvent(new Event('change', { bubbles: true }));
            }
        }

        setValue('');
    }, [props, setValue]);

    const handleInputChange = useCallback(
        async (event, data) => {
            if (clearable && data?.value && data?.value?.length) {
                setClearNode(
                    <ATMIcon name="delete" link onClick={handleClear} />
                );
            }

            let dataValue = event.currentTarget?.value;
            if (restrictAngularBrackets) {
                dataValue = restrictAngularBracketSymbols(dataValue);
            }

            if (restrictDecimal) {
                dataValue = restrictDecimalSymbol(dataValue);
            }

            if (restrictNegativeSign) {
                dataValue = restrictNegativeSignSymbol(dataValue);
            }

            if (onlyNumber) {
                dataValue = restrictAlphabetsAndSpclChar(dataValue);
            }

            if (noSpecialCharacters) {
                dataValue = restrictSpecialCharacters(dataValue);
            }

            return handleOnChange(event, data, dataValue);
        },
        [setClearNode, setValue, handleOnChange]
    );

    useEffect(() => {
        if (
            clearable &&
            ((props.value && props.value.length) ||
                (props.defaultValue && props.defaultValue.length))
        ) {
            setClearNode(<ATMIcon name="delete" link onClick={handleClear} />);
        }

        if (props.value && props.value.length) {
            setValue(props.value);
            return;
        }

        if (props.defaultValue && props.defaultValue.length) {
            setValue(props.defaultValue);
            return;
        }

        if (isCustom) {
            setValue(defaultValue);
            return;
        }

        setValue(props.value);
    }, [
        clearable,
        props.value,
        props.defaultValue,
        setValue,
        setClearNode,
        defaultValue,
        isCustom,
        setValue,
    ]);

    useEffect(() => {
        if (isCustom) {
            setValue(originalValue);
        }
    }, [originalValue, isCustom, setValue]);

    const handleOnBlur = useCallback(
        (event, data) => {
            event.persist();
            let result = event.currentTarget?.value;
            if (restrictAngularBrackets) {
                result = restrictAngularBracketSymbols(result);
            }

            if (restrictDecimal) {
                result = restrictDecimalSymbol(result);
            }

            if (restrictNegativeSign) {
                result = restrictNegativeSignSymbol(result);
            }

            if (onlyNumber) {
                result = restrictAlphabetsAndSpclChar(result);
            }

            setValue(() => {
                // This will handle onBlur from react hook form
                if (props.onBlur) {
                    props.onBlur(event, {
                        ...data,
                        value: result,
                    });
                }
                return result;
            });
            return result;
        },
        [props.onBlur, setValue]
    );

    const handlePhoneChange = useCallback(
        (event, data) => {
            event.persist();

            let result = event.currentTarget?.value;
            if (restrictAngularBrackets) {
                result = restrictAngularBracketSymbols(result);
            }
            if (restrictDecimal) {
                result = restrictDecimalSymbol(event.currentTarget?.value);
            }
            if (restrictNegativeSign) {
                result = restrictNegativeSignSymbol(event.currentTarget?.value);
            }
            if (onlyNumber) {
                result = restrictAlphabetsAndSpclChar(
                    event.currentTarget?.value
                );
            }

            if (clearable && result && result.length) {
                setClearNode(
                    <ATMIcon name="delete" link onClick={handleClear} />
                );
            }

            setValue((prevState) => {
                result = formatPhoneNumber(result ?? '', prevState ?? '');

                // This will handle onChange from react hook form
                if (onChange) {
                    onChange(event, {
                        ...data,
                        error: !REGEX_PHONE.test(result),
                        value: result,
                    });
                }

                return result;
            });

            return result;
        },
        [onChange, setValue]
    );

    const handleCurrency = useCallback(
        async (event, data) => {
            event.persist();
            let result = event.currentTarget?.value;

            if (restrictAngularBrackets) {
                result = restrictAngularBracketSymbols(result);
            }

            if (clearable && result && result.length) {
                setClearNode(
                    <ATMIcon name="delete" link onClick={handleClear} />
                );
            }

            return handleOnChange(event, data, result);
        },
        [onChange, setValue, handleOnChange]
    );

    const handleOnBlurCurrency = useCallback(
        (event, data) => {
            event.persist();
            let result = event.currentTarget?.value;
            if (restrictAngularBrackets) {
                result = restrictAngularBracketSymbols(
                    event.currentTarget?.value
                );
            }

            if (restrictDecimal) {
                result = restrictDecimalSymbol(event.currentTarget?.value);
            }

            if (restrictNegativeSign) {
                result = restrictNegativeSignSymbol(event.currentTarget?.value);
            }
            const resultArray = result.split('.');

            if (clearable && result && result.length) {
                setClearNode(
                    <ATMIcon name="delete" link onClick={handleClear} />
                );
            }

            if (!(resultArray.length > 1) && resultArray[0] !== '') {
                result += '.00';
            }

            setValue(() => {
                // This will handle onBlur from react hook form
                if (props.onBlur) {
                    props.onBlur(event, {
                        ...data,
                        value: result,
                    });
                }

                return result;
            });

            return result;
        },
        [props.onBlur, setValue]
    );

    const handleZipChange = useCallback(
        async (event, data) => {
            event.persist();
            let result = event.currentTarget?.value;
            if (restrictAngularBrackets) {
                result = restrictAngularBracketSymbols(result);
            }

            if (clearable && result && result.length) {
                setClearNode(
                    <ATMIcon name="delete" link onClick={handleClear} />
                );
            }

            // Split the input value into individual zip codes
            const zipCodes = result.split(',');

            // Format each zip code individually
            const formattedZipCodes = zipCodes.map((zipCode) => {
                const trimmedZipCode = zipCode.trim();
                if (trimmedZipCode.length > 0) {
                    const formattedZipCode = (trimmedZipCode ?? '')
                        .replace(/[^\d]/g, '')
                        .trim();
                    if (
                        /^[A-Za-z]+$/.exec(formattedZipCode) ||
                        !formattedZipCode.length
                    ) {
                        return '';
                    }
                    if (formattedZipCode.length < 6) {
                        return formattedZipCode;
                    }
                    return `${formattedZipCode.slice(
                        0,
                        5
                    )}-${formattedZipCode.slice(5, 9)}`;
                }
                return '';
            });

            // Join the formatted zip codes back into a single string
            result = formattedZipCodes.join(', ');

            // If the user is backspacing over a comma, remove the trailing comma and space
            if (event.nativeEvent.inputType === 'deleteContentBackward') {
                result = result.replace(/, $/, '');
            }

            return handleOnChange(
                event,
                {
                    ...data,
                    error: !REGEX_ZIP.test(result),
                },
                result
            );
        },
        [onChange]
    );

    const handleEINChange = useCallback(
        async (event, data) => {
            event.persist();
            let result = event.currentTarget?.value;
            if (restrictAngularBrackets) {
                result = restrictAngularBracketSymbols(result);
            }

            if (clearable && result && result.length) {
                setClearNode(
                    <ATMIcon name="delete" link onClick={handleClear} />
                );
            }

            result = formatEINCode(result ?? '');

            return handleOnChange(
                event,
                {
                    ...data,
                    error: !REGEX_EIN.test(result),
                },
                result
            );
        },
        [onChange]
    );

    const handleKeyDown = useCallback(
        (e) => ['e', 'E'].includes(e.key) && e.preventDefault(),
        []
    );

    const icon = clearable && value && value.length ? clearNode : undefined;

    if (isPhone) {
        return (
            <Input
                size={props.size ? props.size : 'small'}
                icon={icon}
                ref={inputRef}
                {...{
                    ...props,
                    onChange: handlePhoneChange,
                    onPaste: handlePhoneChange,
                    value,
                }}
            />
        );
    }

    if (tiny) {
        return (
            <Input
                className={styles.tinyHeight}
                icon={icon}
                ref={inputRef}
                {...{
                    ...props,
                    onChange: handleInputChange,
                    onPaste: handleInputChange,
                    onBlur: handleOnBlur,
                    value: value || '',
                }}
            />
        );
    }

    if (isCurrency) {
        return (
            <Input
                size={props.size ? props.size : 'small'}
                icon={icon}
                ref={inputRef}
                {...{
                    ...props,
                    label: currency,
                    labelPosition: 'left',
                    onChange: handleCurrency,
                    onPaste: handleCurrency,
                    onBlur: handleOnBlurCurrency,
                    value:
                        thousandSeparator || thousandSeparator === undefined
                            ? formatCurrency(value)
                            : value,
                }}
            />
        );
    }

    if (isZip) {
        return (
            <Input
                size={props.size ? props.size : 'small'}
                icon={icon}
                ref={inputRef}
                {...{
                    ...props,
                    onChange: handleZipChange,
                    onPaste: handleZipChange,
                    value: value || '',
                }}
            />
        );
    }

    if (isEIN) {
        return (
            <Input
                size={props.size ? props.size : 'small'}
                icon={icon}
                ref={inputRef}
                {...{
                    ...props,
                    onChange: handleEINChange,
                    onPaste: handleEINChange,
                    value: value || '',
                }}
            />
        );
    }

    if (props.type === 'number') {
        return (
            <Input
                size={props.size ? props.size : 'small'}
                icon={icon}
                ref={inputRef}
                {...{
                    ...props,
                    onChange: handleInputChange,
                    onPaste: handleInputChange,
                    onBlur: handleOnBlur,
                    onKeyDown: handleKeyDown,
                    value,
                }}
            />
        );
    }

    if (clearable) {
        return (
            <Input
                size={props.size ? props.size : 'small'}
                icon={icon}
                ref={inputRef}
                {...{
                    ...props,
                    onChange: handleInputChange,
                    onPaste: handleInputChange,
                    onBlur: handleOnBlur,
                    value: value || '',
                }}
            />
        );
    }

    return <Input size={props.size ? props.size : 'small'} {...props} />;
};
