import React, { useEffect, useRef, useState } from 'react';
import HeaderSection from 'src/components/common/HeaderSection/headerSection.component';
import { ATMHeader } from 'src/components/common/atoms/ATMHeader/ATMHeader.component';
import { ATMSegment } from 'src/components/common/atoms/ATMSegment/ATMSegment.component';
import styles from './SubmitRequest.module.scss';
import './SubmitRequest.module.scss';
import { ATMGrid } from 'src/components/common/atoms/ATMGrid/ATMGrid.component';
import { ATMButton } from 'src/components/common/atoms/ATMButton/ATMButton.component';
import { ATMForm } from 'src/components/common/atoms/ATMForm/ATMForm.component';
import { useNavigate } from 'react-router-dom';
import PrivacyAndSecurity from './PrivacyAndSecurity.component';
import DataRequest from './DataRequest.component';
import Organization from './Organization.component';
import moment from 'moment';
import Lang from 'src/libraries/language';
import useSubmitRequest from 'src/hooks/useSubmitRequest';
import { RemoveRedEye } from '@mui/icons-material';
import { Dimmer } from 'semantic-ui-react';
import { ATMLoader } from 'src/components/common/atoms/ATMLoader/ATMLoader.component';
import CustomModal from 'src/components/common/CustomModal/CustomModal.component';
import {
    californiaUtility,
    customerSegment,
    encryptGCPSKey,
    geographicalArea,
    validateForm,
} from 'src/utils/common.utils';
import {
    useSubmitRequestDispatch,
    useSubmitRequestState,
} from 'src/contexts/submitRequest.context';
import {
    useSubmitRequestErrorsDispatch,
    useSubmitRequestErrorsState,
} from 'src/contexts/submitRequestErrors.context';
import { useNotificationDispatch } from 'src/contexts/notification.context';
import ReCAPTCHA from 'react-google-recaptcha';
import { submitRequestFields } from 'src/constants/submitRequest';
import { ATMModal } from 'src/components/common/atoms/ATMModal/ATMModal.component';
import useGoogleRecaptcha from 'src/hooks/useGoogleRecaptcha';

const SubmitRequest: React.FC = () => {
    const navigate = useNavigate();
    const [OrganizationRequestToggle, setOrganizationRequestToggle] =
        useState(false);
    const [DataRequestToggle, setDataRequestToggle] = useState(false);
    const [SecurityToggle, setSecurityToggle] = useState(false);
    const [reviewAndSubmitButton, setReviewAndSubmitButton] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { submitRequestForm } = useSubmitRequestState();
    const dispatchForm = useSubmitRequestDispatch();

    const { submitRequestErrors } = useSubmitRequestErrorsState();
    const dispatchErrors = useSubmitRequestErrorsDispatch();

    const [submitResponse, setSubmitResponse] = useState(false);
    const [exitModalOpen, setExitModalOpen] = useState(false);
    const [requestId, setRequestId] = useState<any>('');
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);

    const notificationDispatch = useNotificationDispatch();

    const {
        mutate: submitRequest,
        data: submitReqResponse,
        isLoading: submitRequestLoading,
        isError: submitRequestError,
    } = useSubmitRequest();

    const {
        mutate: submitGoogleRecaptcha,
        data: googleReponse,
        isLoading: googleReponseLoading,
    } = useGoogleRecaptcha();

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    useEffect(() => {
        if (submitReqResponse && submitReqResponse?.data) {
            setRequestId(submitReqResponse?.data?.resultRequestId);
            setSubmitResponse(true);
        }
    }, [submitReqResponse]);

    useEffect(() => {
        if (googleReponse && googleReponse?.data) {
            if (googleReponse?.data?.body?.score < 0.9) {
                return notificationDispatch({
                    type: 'ERROR',
                    payload: Lang.MSG_RECAPTCHA_FAILED,
                });
            } else {
                setDisableSubmitButton(false);
            }
        }
    }, [googleReponse]);

    useEffect(() => {
        if (submitRequestError) {
            notificationDispatch({
                type: 'ERROR',
                payload: Lang.MSG_API_500,
            });

            setOrganizationRequestToggle(true);
            setDataRequestToggle(true);
            setSecurityToggle(true);
        }
    }, [submitRequestError]);

    const handleExitButtonClick = () => {
        if (reviewAndSubmitButton) {
            setOrganizationRequestToggle(true);
            setDataRequestToggle(true);
            setSecurityToggle(true);
            setReviewAndSubmitButton(false);
        } else {
            setExitModalOpen(true);
        }
    };

    const handleSubmit = () => {
        if (
            reviewAndSubmitButton &&
            Object.values(submitRequestForm)?.every((ele) => ele !== '')
        ) {
            setReviewAndSubmitButton(false);
            const payload = {
                DateSubmitted: moment(new Date()).format(
                    'YYYY-MM-DD'
                ),
                StatusModifiedDate: moment(new Date()).format(
                    'YYYY-MM-DD'
                ),
                ...submitRequestForm,
            };

            payload.RequestorType = payload.OrganizationType;
            payload.RequestorName = payload.RequesterName;

            payload.StartDate = moment(payload.StartDate).format(
                'YYYY-MM-DD'
            );
            payload.EndDate = moment(payload.EndDate).format(
                'YYYY-MM-DD'
            );
            payload.DataDisposalDate = moment(payload.DataDisposalDate).format(
                'YYYY-MM-DD'
            );

            Object.keys(payload).forEach((key) => {
                if (payload[key] === 'Yes') {
                    payload[key] = true;
                } else if (payload[key] === 'No') {
                    payload[key] = false;
                }
            });

            payload['GeographicalArea'] = geographicalArea(payload);
            payload['OtherUtilities'] = californiaUtility(payload);
            payload['CustomerSegmentInfo'] = customerSegment(payload);

            const customerSegments = [
                'AllSectors',
                'Residential',
                'NonResidential',
                'Commercial',
                'Industrial',
            ];

            customerSegments.forEach((segment) => {
                delete payload[segment];
            });
            payload['AWSEnvironment'] = process.env.REACT_APP_ENVIRONMENT;
            const encryptGCPSKeyData = encryptGCPSKey();
            payload['data'] = encryptGCPSKeyData === 'null'  ? 'null' : encryptGCPSKeyData;

            // condition to check if organizationType == 'Others (Please specify)'
            if (payload.OrganizationType === 'Others (Please Specify)') {
                payload.OrganizationType = payload.OrganizationTypeOthers as string;
            }
            delete payload.OrganizationTypeOthers;

            //   Deleting unwanted field while submitting request
            delete payload.confirmEmailAddress;
            delete payload.confirmYourEmailAddress;
            const formValues = { ...submitRequestFields, ...payload };

            submitRequest(formValues);
            setOrganizationRequestToggle(false);
            setDataRequestToggle(false);
            setSecurityToggle(false);
        }
    };

    const handleReview = () => {
        const errors = {} as any;
        const formValues = { ...submitRequestForm };
        const formErrors = { ...submitRequestErrors };

        Object.keys(formValues).forEach((key) => {
            if (formValues[key] === '' || formErrors[key] != null) {
                errors[key] = 'This field is required';
            }
        });
        if(!formValues.confirmEmailAddress){
            errors.confirmEmailAddress="This field is required"
        }
        const geographicalAreaFields = [
            'GeographicalAreaZipCode',
            'GeographicalAreaCity',
            'GeographicalAreaCounty',
            'GeographicalAreaOthers',
        ];

        const selectedFields = geographicalAreaFields.filter(
            (field) => formValues[field]
        );

        if (selectedFields.length > 0) {
            selectedFields.forEach((field) => {
                if (formValues[`${field}Input`] === '') {
                    errors[`${field}Input`] = 'This field is required';
                } else {
                    errors[`${field}Input`] = null;
                }
            });

            geographicalAreaFields.forEach((field) => {
                if (!selectedFields.includes(field)) {
                    delete formValues[field];
                    delete formValues[`${field}Input`];
                    errors[field] = null;
                    errors[`${field}Input`] = null;
                }
            });
        } else {
            geographicalAreaFields.forEach((field) => {
                errors[field] = 'This field is required';
                delete formValues[field];
                delete formValues[`${field}Input`];
            });
        }

        const utilityFields = [
            'PacificGasAndElectric',
            'SouthernCaliforniaEdison',
            'SanDiegoGasAndElectric',
            'IsOtherUtilities',
        ];

        const isAnyUtilityFieldSelected = utilityFields.some(
            (field) => formValues[field]
        );

        if (isAnyUtilityFieldSelected) {
            utilityFields.forEach((field) => {
                errors[field] = null;
                errors[`${field}Input`] = null;
            });
        } else {
            utilityFields.forEach((field) => {
                errors[field] = null;
                delete formValues[`${field}`];
                delete formValues[`${field}Input`];
            });
        }

        const customerSegments = [
            'AllSectors',
            'Residential',
            'NonResidential',
            'Commercial',
            'Industrial',
        ];

        const isCustomerSegmentFieldSelected = customerSegments.some(
            (field) => formValues[field]
        );

        if (isCustomerSegmentFieldSelected) {
            customerSegments.forEach((field) => {
                errors[field] = null;
            });
        } else {
            customerSegments.forEach((field) => {
                errors[field] = 'This field is required';
                delete formValues[`${field}`];
            });
        }

        dispatchForm({
            type: 'UPDATE_SUBMIT_REQUEST',
            payload: formValues,
        });

        dispatchErrors({
            type: 'UPDATE_SUBMIT_REQUEST_ERRORS',
            payload: errors,
        });

        if (
            Object.values(errors).some((error: any) =>
                error?.includes('This field is required')
            )
        ) {
            setReviewAndSubmitButton(false);
            setOrganizationRequestToggle(true);
            setDataRequestToggle(true);
            setSecurityToggle(true);

            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            setReviewAndSubmitButton(true);
        }
    };

    const handleDataChange = ([_, data]: any) => {
        const { name } = data;
        const value =
            typeof data?.checked === 'boolean' && !data?.radio
                ? data.checked
                : data.value;

        submitRequestForm[name] = value;
        // const formValues = { ...submitRequestForm, [name]: value }; // Update the form values
        const { errors, formData } = validateForm(
            name,
            submitRequestForm,
            submitRequestErrors
        );
        dispatchForm({
            type: 'UPDATE_SUBMIT_REQUEST',
            payload: formData,
        });
        dispatchErrors({
            type: 'UPDATE_SUBMIT_REQUEST_ERRORS',
            payload: errors,
        });

        return value;
    };

    const handleRadioButtonChange = (_, data: any) => {
        const { name, value } = data;
        // let formValues = { ...submitRequestForm };
        let fieldName = '';
        switch (name) {
            case 'IsPrimaryContact':
                fieldName = 'IsPrimaryContact';
                break;

            case 'IsFromCaliforniaUtility':
                fieldName = 'IsFromCaliforniaUtility';
                break;

            case 'CSDComplyStatutoryObligation':
                fieldName = 'CSDComplyStatutoryObligation';
                break;

            case 'CompyStatutoryObligation':
                fieldName = 'CompyStatutoryObligation';
                break;

            case 'AgreeNDASign':
                fieldName = 'AgreeNDASign';
                break;

            case 'HasCertifiedByReviewBoard':
                fieldName = 'HasCertifiedByReviewBoard';
                break;

            case 'HasProjectCertified':
                fieldName = 'HasProjectCertified';
                break;

            case 'Monthly':
            case 'Quarterly':
            case 'Annually':
                fieldName = 'RecurringFrequency';
                break;

            case 'RecurringRequest':
                fieldName = 'RecurringRequest';
                break;

            case 'CustomerSegmentInfo':
                fieldName = 'CustomerSegmentInfo';
                break;

            case 'IsThirdPartyAccess':
                fieldName = 'IsThirdPartyAccess';
                break;

            case 'Local Government':
            case 'Academic Researcher':
            case 'State/Federal Agency':
            case 'CA Department of Community Services & Development (CSD)':
            case 'Others (Please Specify)':
                fieldName = 'OrganizationType';
                break;

            case 'IsFacultyMember':
                fieldName = 'IsFacultyMember';
                break;

            default:
                submitRequestForm[name] = value;
        }

        submitRequestForm[fieldName] = value;
        const { errors, formData } = validateForm(
            fieldName,
            submitRequestForm,
            submitRequestErrors
        );
        dispatchForm({
            type: 'UPDATE_SUBMIT_REQUEST',
            payload: formData,
        });
        dispatchErrors({
            type: 'UPDATE_SUBMIT_REQUEST_ERRORS',
            payload: errors,
        });

        return value;
    };

    const handleCaptchaChange = (value) => {
        if (value !== null) {
            try {
                submitGoogleRecaptcha(value);
            } catch (error) {
                notificationDispatch({
                    type: 'ERROR',
                    payload: Lang.MSG_RECAPTCHA_FAILED,
                });
            }
        }
    };

    return (
        <>
            <Dimmer
                active={submitRequestLoading || googleReponseLoading}
                inverted
            >
                <ATMLoader size="large">Loading</ATMLoader>
            </Dimmer>

            <>
                <HeaderSection sections={'Submit a Request'} />
                <div className={styles.headerTitleStyle}>
                    <ATMHeader className={styles.header}>
                        Submit a Request
                    </ATMHeader>
                    <span className={styles.indicatorMsg}>
                        <p className={styles.indicator}>* </p>
                        <p className={styles.indicatorParagrapgh}>
                            &nbsp; indicates a required field
                        </p>
                    </span>
                </div>

                <div className={styles.backgroundColor}>
                    {/* Organization Request Data Segment */}
                    <ATMForm
                        size="tiny"
                        className="sempraTinyMarginBottom"
                        ref={formRef}
                        mode="onChange"
                        autocomplete="off"
                    >
                        {({ control }) => {
                            return (
                                <>
                                    {/* Review static segment */}
                                    {Object.values(submitRequestForm)?.every(
                                        (ele) => ele !== ''
                                    ) &&
                                        reviewAndSubmitButton && (
                                            <ATMSegment
                                                size="tiny"
                                                className={styles.commonMargin}
                                            >
                                                <ATMGrid>
                                                    <ATMGrid.Row>
                                                        <ATMGrid.Column
                                                            width={8}
                                                            className={
                                                                styles.segmentsStyle
                                                            }
                                                        >
                                                            <ATMHeader
                                                                as="h2"
                                                                className={
                                                                    styles.headerStyle
                                                                }
                                                            >
                                                                <RemoveRedEye />
                                                                {
                                                                    'Review Information'
                                                                }
                                                            </ATMHeader>
                                                        </ATMGrid.Column>
                                                    </ATMGrid.Row>
                                                </ATMGrid>
                                            </ATMSegment>
                                        )}

                                    {/*Organization Segment */}
                                    <Organization
                                        setOrganizationRequestToggle={
                                            setOrganizationRequestToggle
                                        }
                                        OrganizationRequestToggle={
                                            OrganizationRequestToggle
                                        }
                                        handleDataChange={handleDataChange}
                                        reviewAndSubmitButton={
                                            reviewAndSubmitButton
                                        }
                                        handleRadioButtonChange={
                                            handleRadioButtonChange
                                        }
                                        setReviewAndSubmitButton={
                                            setReviewAndSubmitButton
                                        }
                                    />

                                    {/* Data Request Segment */}
                                    <DataRequest
                                        setDataRequestToggle={
                                            setDataRequestToggle
                                        }
                                        DataRequestToggle={DataRequestToggle}
                                        handleRadioButtonChange={
                                            handleRadioButtonChange
                                        }
                                        handleDataChange={handleDataChange}
                                        control={control}
                                        reviewAndSubmitButton={
                                            reviewAndSubmitButton
                                        }
                                        setReviewAndSubmitButton={
                                            setReviewAndSubmitButton
                                        }
                                    />

                                    {/* Privacy and Security Segment */}
                                    <PrivacyAndSecurity
                                        setSecurityToggle={setSecurityToggle}
                                        SecurityToggle={SecurityToggle}
                                        handleDataChange={handleDataChange}
                                        control={control}
                                        reviewAndSubmitButton={
                                            reviewAndSubmitButton
                                        }
                                        setReviewAndSubmitButton={
                                            setReviewAndSubmitButton
                                        }
                                    />

                                    {/* Action Buttons Segment */}
                                    <ATMSegment
                                        size="tiny"
                                        className={styles.commonMargin}
                                    >
                                        <ATMGrid>
                                            <ATMGrid.Row
                                                className={styles.actionButtons}
                                            >
                                                <ATMGrid.Column
                                                    computer={
                                                        reviewAndSubmitButton
                                                            ? 4
                                                            : 8
                                                    }
                                                    tablet={16}
                                                    mobile={16}
                                                >
                                                    <ATMButton
                                                        secondary
                                                        content={
                                                            reviewAndSubmitButton
                                                                ? 'Back'
                                                                : 'Exit'
                                                        }
                                                        size="tiny"
                                                        onClick={
                                                            handleExitButtonClick
                                                        }
                                                        data-testid="exitButton"
                                                        icon="left chevron"
                                                        className={
                                                            styles.exitButton
                                                        }
                                                    />
                                                </ATMGrid.Column>
                                                {reviewAndSubmitButton && (
                                                    <ATMGrid.Column
                                                        computer={8}
                                                        tablet={16}
                                                        mobile={16}
                                                        className={
                                                            styles.googleCaptcha
                                                        }
                                                    >
                                                        <ReCAPTCHA
                                                            sitekey={
                                                                process.env
                                                                    .REACT_APP_GOOGLE_SITE_KEY
                                                            }
                                                            onChange={
                                                                handleCaptchaChange
                                                            }
                                                        />
                                                    </ATMGrid.Column>
                                                )}

                                                <ATMGrid.Column
                                                    computer={
                                                        reviewAndSubmitButton
                                                            ? 4
                                                            : 8
                                                    }
                                                    tablet={16}
                                                    mobile={16}
                                                >
                                                    <ATMButton
                                                        content={
                                                            reviewAndSubmitButton
                                                                ? `${Lang.LBL_SUBMIT_REQUEST}`
                                                                : `${Lang.LBL_REVIEW}`
                                                        }
                                                        size="tiny"
                                                        onClick={
                                                            reviewAndSubmitButton
                                                                ? handleSubmit
                                                                : handleReview
                                                        }
                                                        data-testid="reviewButton"
                                                        className={
                                                            reviewAndSubmitButton
                                                                ? styles.submitButton
                                                                : styles.reviewButton
                                                        }
                                                        disabled={
                                                            reviewAndSubmitButton &&
                                                            disableSubmitButton
                                                        }
                                                        icon="right chevron"
                                                        labelPosition="right"
                                                    ></ATMButton>
                                                </ATMGrid.Column>
                                            </ATMGrid.Row>
                                        </ATMGrid>
                                    </ATMSegment>

                                    {/* Request Submitted Modal */}
                                    {submitResponse && (
                                        <CustomModal
                                            modalOpen={submitResponse}
                                            setModalClose={setSubmitResponse}
                                            header={Lang.LBL_REQUEST_SUBMITTED}
                                            subHeader={
                                                Lang.MSG_SUBMIT_REQ_SUCCESS
                                            }
                                            content={`A unique Request ID ${requestId} has been generated for your reference. 
                                            Kindly check your inbox, 
                                            junk, or spam folder to verify the email.`}
                                            exitButton={true}
                                            navigate={true}
                                            navigatePath="/"
                                        />
                                    )}
                                </>
                            );
                        }}
                    </ATMForm>
                </div>
            </>

            <ATMModal
                onClose={() => setExitModalOpen(false)}
                open={exitModalOpen}
                size="tiny"
            >
                <ATMModal.Header>Leaving This Page?</ATMModal.Header>
                <ATMModal.Content className={styles.modalContent}>
                    Are you sure you want to exit? <br />
                    Your unsaved changes could be lost.
                </ATMModal.Content>
                <ATMModal.Actions>
                    <ATMButton
                        onClick={() => setExitModalOpen(false)}
                        secondary
                    >
                        Cancel
                    </ATMButton>
                    <ATMButton
                        onClick={() => {
                            setExitModalOpen(false);
                            navigate('/');
                        }}
                        primary
                    >
                        Exit
                    </ATMButton>
                </ATMModal.Actions>
            </ATMModal>
        </>
    );
};

export default SubmitRequest;
