/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';

const Erro404: React.FC = () => {
    return (
        <div>
            <h2>Error 404</h2>
            <p>Page not found.</p>
            <Link to="/">Click to return home</Link>
        </div>
    );
};

export default Erro404;
