/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import ATMBrandLogo from '../atoms/ATMBrandLogo/ATMBrandLogo.component';
import { ATMMenu } from '../atoms/ATMMenu/ATMMenu.component';
import { ATMGrid } from '../atoms/ATMGrid/ATMGrid.component';
import FooterComponent from '../Footer/footer.component';
import styles from './private.module.scss';
import { useNavigate } from 'react-router-dom';
import { applicationOptions } from 'src/constants/home';
import { MOLIdleTime } from '../molecules/MOLIdleTime/MOLIdleTime.component';

const PrivateLayout: React.FC<any> = ({ children }) => {
    const [selectedValue, setSelectedValue] = useState('');
    const navigateTo = useNavigate();

    useEffect(() => {
        setSelectedValue('');
    }, [selectedValue]);

    const handleMenuSelect = (value) => {
        setSelectedValue(value);
        if (value == 'edrpHome') {
            navigateTo('/');
        } else if (value == 'userGuide') {
            window.open(
                `${require('../../../assets/files/Energy Data Request Program - External App User Guide.pdf')}`,
                '_blank',
                'noopener noreferrer'
            );
        } else {
            navigateTo(`/${value}`);
        }
    };

    const renderList = (list) => {
        return list?.map((menu_option) => (
            <ATMMenu.Item
                key={menu_option.key}
                name={menu_option.key}
                active={selectedValue === menu_option.key}
                onClick={() => handleMenuSelect(menu_option.key)}
                content={menu_option.name}
            />
        ));
    };

    return (
        <>
            <ATMGrid fluid vertical className={styles.container}>
                <ATMGrid.Row>
                    <ATMGrid.Column computer={2} tablet={4} mobile={16}>
                        <a
                            className={styles.brandLogo}
                            href="https://www.socalgas.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ATMBrandLogo
                                height={40}
                                width={102.26}
                                color="#ffffff"
                                logoName={'socal gas'}
                            />
                        </a>
                    </ATMGrid.Column>

                    <ATMGrid.Column
                        className={styles.alignMenus}
                        computer={12}
                        tablet={8}
                        mobile={16}
                    >
                        <ATMMenu secondary size="small">
                            {renderList(applicationOptions)}
                        </ATMMenu>
                    </ATMGrid.Column>
                </ATMGrid.Row>
            </ATMGrid>

            {/* child components */}
            <div className={styles.wrapper}>{children}</div>
            <MOLIdleTime
                timeout={Number(process.env.REACT_APP_IDLE_TIMEOUT_MINUTES)}
            />

            {/* footer component */}
            <FooterComponent />
        </>
    );
};

export default PrivateLayout;
