import { getServiceInstance } from './base.service';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchQuarterlyData = async () => {
    try {
        const response = await getServiceInstance().get(
            `${BASE_URL}/download-quarterly-data`,
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const downloadQuarterlyData = async (requestBody) => {
    try {
        const response = await getServiceInstance().get(
            `${BASE_URL}/download-quarterly-data?fileName=${requestBody}`
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

