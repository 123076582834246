import React from 'react';

import { SubmitRequestErrorsTypes } from 'src/types/submitRequestErrors.types';

import { submitRequestErrorsDefaultValues } from 'src/constants/submitRequest';

type State = {
    submitRequestErrors: SubmitRequestErrorsTypes;
};

type Action =
    | {
          type: 'UPDATE_SUBMIT_REQUEST_ERRORS';
          payload: SubmitRequestErrorsTypes | { key: string; value: any };
      }
    | {
          type: 'RESET_FIELD_ERRORS_STATE';
      };

type Dispatch = (action: Action) => void;

const SubmitRequestErrorsStateContext = React.createContext<State | undefined>(
    undefined
);
const SubmitRequestErrorsDispatchContext = React.createContext<Dispatch | undefined>(
    undefined
);

const INITIAL_STATE = {
    submitRequestErrors: submitRequestErrorsDefaultValues,
};

function SubmitRequestErrorsReducer(state, action) {
    switch (action.type) {
        case 'UPDATE_SUBMIT_REQUEST_ERRORS': {
            return { ...state, submitRequestErrors: { ...state.submitRequestErrors, ...action.payload } };
        }
        case 'RESET_FIELD_ERRORS_STATE': {
            return { ...INITIAL_STATE };
        }
        default:
            return state;
    }
}

function SubmitRequestErrorsProvider({ children, newState = null }) {
    const [state, dispatch] = React.useReducer(
        SubmitRequestErrorsReducer,
        newState || INITIAL_STATE
    );

    return (
        <SubmitRequestErrorsStateContext.Provider value={state}>
            <SubmitRequestErrorsDispatchContext.Provider value={dispatch}>
                {children}
            </SubmitRequestErrorsDispatchContext.Provider>
        </SubmitRequestErrorsStateContext.Provider>
    );
}

function useSubmitRequestErrorsState() {
    const context = React.useContext(SubmitRequestErrorsStateContext);

    if (context === undefined) {
        throw new Error(
            'userSubmitRequestErrorsState must be used within SubmitRequestErrorsProvider'
        );
    }
    return context;
}

function useSubmitRequestErrorsDispatch() {
    const context = React.useContext(SubmitRequestErrorsDispatchContext);

    if (context === undefined) {
        throw new Error(
            'userSubmitRequestErrorsDispatch must be used within SubmitRequestErrorsProvider'
        );
    }

    return context;
}

export {
    SubmitRequestErrorsProvider,
    useSubmitRequestErrorsState,
    useSubmitRequestErrorsDispatch,
};
