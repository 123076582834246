import routerFactory, { } from 'src/libraries/router.library';
import Home from './components/pages/Home/Home.component';
import Lang from './libraries/language';
import SubmitRequest from './components/pages/SubmitRequest/SubmitRequest.component';
import DataRequestLog from './components/pages/DataRequestLog/DataRequestLog.component';
import Information from './components/pages/Information/Information.component';
import Resources from './components/pages/Resources/Resources.component';

/**
 * This will centralized all the routes for this application,
 * which makes them easier to access and maintain.
 * This will also avoid route duplications.
 */

const EDRPRoute = {
    label: 'EDRP Home',
    path: '/',
    component: Home,
};


const SubmitRequestRoute = {
    label: Lang.LBL_SUBMIT_A_REQUEST,
    path: '/submitRequest',
    component: SubmitRequest,
};

const DataRequestLogRoute = {
    label: Lang.LBL_DATA_REQ_LOG,
    path: '/dataRequestLog',
    component: DataRequestLog,
};

const InformationRoute = {
    label: Lang.LBL_INFORMATION,
    path: '/information',
    component: Information,
};

const ResourcesRoute = {
    label: Lang.LBL_RESOURCES,
    path: '/resources',
    component: Resources,
};


export const routes = [
    EDRPRoute,
    SubmitRequestRoute,
    DataRequestLogRoute,
    InformationRoute,
    ResourcesRoute,
];

export type IRouter = typeof routes[number];

export default routerFactory(routes);
