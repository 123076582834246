import React from 'react';
import { Icon } from 'semantic-ui-react';
import { ATMButton } from 'src/components/common/atoms/ATMButton/ATMButton.component';
import { ATMGrid } from 'src/components/common/atoms/ATMGrid/ATMGrid.component';
import { ATMHeader } from 'src/components/common/atoms/ATMHeader/ATMHeader.component';
import { ATMModal } from 'src/components/common/atoms/ATMModal/ATMModal.component';
import Lang from 'src/libraries/language';
import styles from 'src/components/pages/SubmitRequest/SubmitRequest.module.scss';
import { useNavigate } from 'react-router-dom';

interface CustomModalProps {
    modalOpen: boolean;
    setModalClose: (value: boolean) => void;
    navigate?: boolean;
    navigatePath?: string;
    header?: string;
    subHeader?: string;
    content?: any;
    exitButton?: boolean;
    size?: any;
}

const CustomModal: React.FC<CustomModalProps> = ({
    modalOpen,
    setModalClose,
    navigate = false,
    navigatePath = '/',
    header = 'Default Header',
    subHeader = '',
    content = '',
    exitButton = false,
    size = 'tiny',
}) => {
    const navigateTo = useNavigate();

    return (
        <ATMModal
            onClose={() => setModalClose(false)}
            closeOnDimmerClick={false}
            open={modalOpen}
            size={size}
        >
            <ATMModal.Header>
                <ATMGrid>
                    <ATMGrid.Row columns="equal" verticalAlign="middle">
                        <ATMGrid.Column textAlign="left" width={12}>
                            <ATMHeader className={styles.modalHeader}>
                                {header}
                            </ATMHeader>
                        </ATMGrid.Column>
                        <ATMGrid.Column textAlign="right" width={4}>
                            <Icon
                                circular
                                name="close"
                                className={styles.crossIcon}
                                onClick={() => {
                                    setModalClose(false);
                                    if (navigate) navigateTo(navigatePath);
                                }}
                            />
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>
            </ATMModal.Header>

            <ATMModal.Content>
                <ATMGrid>
                    {subHeader && (
                        <>
                            <ATMGrid.Row columns="equal" verticalAlign="middle">
                                <ATMGrid.Column textAlign="left">
                                    <ATMHeader className={styles.modalContent}>
                                        {subHeader}
                                    </ATMHeader>
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                        </>
                    )}
                    {content && (
                        <ATMGrid.Row columns="equal" verticalAlign="middle">
                            <ATMGrid.Column textAlign="left">
                                <ATMHeader className={styles.modalContent}>
                                    {content}
                                </ATMHeader>
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    )}
                </ATMGrid>
            </ATMModal.Content>

            {exitButton && (
                <ATMModal.Actions>
                    <ATMGrid>
                        <ATMGrid.Row>
                            <ATMGrid.Column textAlign="right" computer={16}>
                                <ATMButton
                                    onClick={() => {
                                        setModalClose(false);
                                        if (navigate) navigateTo(navigatePath);
                                    }}
                                    size="tiny"
                                    className={styles.submitSuccessButton}
                                >
                                    {Lang.LBL_EXIT}
                                </ATMButton>
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>
                </ATMModal.Actions>
            )}
        </ATMModal>
    );
};

export default CustomModal;
