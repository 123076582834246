/* eslint-disable max-len */
import Lang from 'src/libraries/language';

export const homePageTableData = [
    {
        year: 2012,
        Q1: 'SCG-GAS-2012-Q1.csv',
        Q2: 'SCG-GAS-2012-Q2.csv',
        Q3: 'SCG-GAS-2012-Q3.csv',
        Q4: 'SCG-GAS-2012-Q4.csv',
    },
    {
        year: 2013,
        Q1: 'SCG-GAS-2013-Q1.csv',
        Q2: 'SCG-GAS-2013-Q2.csv',
        Q3: 'SCG-GAS-2013-Q3.csv',
        Q4: 'SCG-GAS-2013-Q4.csv',
    },
    {
        year: 2014,
        Q1: 'SCG-GAS-2014-Q1.csv',
        Q2: 'SCG-GAS-2014-Q2.csv',
        Q3: 'SCG-GAS-2014-Q3.csv',
        Q4: 'SCG-GAS-2014-Q4.csv',
    },
    {
        year: 2015,
        Q1: 'SCG-GAS-2015-Q1.csv',
        Q2: 'SCG-GAS-2015-Q2.csv',
        Q3: 'SCG-GAS-2015-Q3.csv',
        Q4: 'SCG-GAS-2015-Q4.csv',
    },
    {
        year: 2016,
        Q1: 'SCG-GAS-2016-Q1.csv',
        Q2: 'SCG-GAS-2016-Q2.csv',
        Q3: 'SCG-GAS-2016-Q3.csv',
        Q4: 'SCG-GAS-2016-Q4.csv',
    },
    {
        year: 2017,
        Q1: 'SCG-GAS-2017-Q1.csv',
        Q2: 'SCG-GAS-2017-Q2.csv',
        Q3: 'SCG-GAS-2017-Q3.csv',
        Q4: 'SCG-GAS-2017-Q4.csv',
    },
    {
        year: 2018,
        Q1: 'SCG-GAS-2018-Q1.csv',
        Q2: 'SCG-GAS-2018-Q2.csv',
        Q3: 'SCG-GAS-2018-Q3.csv',
        Q4: 'SCG-GAS-2018-Q4.csv',
    },
    {
        year: 2019,
        Q1: 'SCG-GAS-2019-Q1.csv',
        Q2: 'SCG-GAS-2019-Q2.csv',
        Q3: 'SCG-GAS-2019-Q3.csv',
        Q4: 'SCG-GAS-2019-Q4.csv',
    },
    {
        year: 2020,
        Q1: 'SCG-GAS-2020-Q1.csv',
        Q2: 'SCG-GAS-2020-Q2.csv',
        Q3: 'SCG-GAS-2020-Q3.csv',
        Q4: 'SCG-GAS-2020-Q4.csv',
    },
    {
        year: 2021,
        Q1: 'SCG-GAS-2021-Q1.csv',
        Q2: 'SCG-GAS-2021-Q2.csv',
        Q3: 'SCG-GAS-2021-Q3.csv',
        Q4: 'SCG-GAS-2021-Q4.csv',
    },
    {
        year: 2022,
        Q1: 'SCG-GAS-2022-Q1.csv',
        Q2: 'SCG-GAS-2022-Q2.csv',
        Q3: 'SCG-GAS-2022-Q3.csv',
        Q4: 'SCG-GAS-2022-Q4.csv',
    },
    {
        year: 2023,
        Q1: 'SCG-GAS-2023-Q1.csv',
        Q2: 'SCG-GAS-2023-Q2.csv',
        Q3: 'SCG-GAS-2023-Q3.csv',
        Q4: 'SCG-GAS-2023-Q4.csv',
    },
    {
        year: 2024,
        Q1: 'SCG-GAS-2024-Q1.csv',
        Q2: 'SCG-GAS-2024-Q2.csv',
    },
];

export const statusOptions = [
    {
        key: 'Cancelled',
        value: 'Cancelled',
        text: 'Cancelled',
    },
    {
        key: 'Completed',
        value: 'Completed',
        text: 'Completed',
    },
    {
        key: 'Denied',
        value: 'Denied',
        text: 'Denied',
    },
    {
        key: 'In Progress',
        value: 'In Progress',
        text: 'In Progress',
    },
    {
        key: 'In Review',
        value: 'In Review',
        text: 'In Review',
    },
];

export const requestorTypeOptions = [
    {
        key: 'Academic Researcher',
        value: 'Academic Researcher',
        text: 'Academic Researcher',
    },
    {
        key: 'CA Department of Community Services & Development (CSD)',
        value: 'CA Department of Community Services & Development (CSD)',
        text: 'CA Department of Community Services & Development (CSD)',
    },
    {
        key: 'Local Government',
        value: 'Local Government',
        text: 'Local Government',
    },
    {
        key: 'Others',
        value: 'Others',
        text: 'Others',
    },
    {
        key: 'State/Federal Agency',
        value: 'State/Federal Agency',
        text: 'State/Federal Agency',
    },
];

export const typeOfOrganizations = [
    'Academic Researcher',
    'CA Department of Community Services & Development (CSD)',
    'Local Government',
    'State/Federal Agency',
];

export const noDataFound =
    'Oops! Couldn’t find any data. Try searching again with different keywords and filters.';

export const panels = {
    OrganizationRequest: false,
    DataRequest: false,
    Security: false,
};

export const statesOptions = [
    { key: 'Alabama', text: 'Alabama', value: 'Alabama' },
    { key: 'Alaska', text: 'Alaska', value: 'Alaska' },
    { key: 'Arizona', text: 'Arizona', value: 'Arizona' },
    { key: 'Arkansas', text: 'Arkansas', value: 'Arkansas' },
    { key: 'California', text: 'California', value: 'California' },
    { key: 'Colorado', text: 'Colorado', value: 'Colorado' },
    { key: 'Connecticut', text: 'Connecticut', value: 'Connecticut' },
    { key: 'Delaware', text: 'Delaware', value: 'Delaware' },
    { key: 'Florida', text: 'Florida', value: 'Florida' },
    { key: 'Georgia', text: 'Georgia', value: 'Georgia' },
    { key: 'Hawaii', text: 'Hawaii', value: 'Hawaii' },
    { key: 'Idaho', text: 'Idaho', value: 'Idaho' },
    { key: 'Illinois', text: 'Illinois', value: 'Illinois' },
    { key: 'Indiana', text: 'Indiana', value: 'Indiana' },
    { key: 'Iowa', text: 'Iowa', value: 'Iowa' },
    { key: 'Kansas', text: 'Kansas', value: 'Kansas' },
    { key: 'Kentucky', text: 'Kentucky', value: 'Kentucky' },
    { key: 'Louisiana', text: 'Louisiana', value: 'Louisiana' },
    { key: 'Maine', text: 'Maine', value: 'Maine' },
    { key: 'Maryland', text: 'Maryland', value: 'Maryland' },
    { key: 'Massachusetts', text: 'Massachusetts', value: 'Massachusetts' },
    { key: 'Michigan', text: 'Michigan', value: 'Michigan' },
    { key: 'Minnesota', text: 'Minnesota', value: 'Minnesota' },
    { key: 'Mississippi', text: 'Mississippi', value: 'Mississippi' },
    { key: 'Missouri', text: 'Missouri', value: 'Missouri' },
    { key: 'Montana', text: 'Montana', value: 'Montana' },
    { key: 'Nebraska', text: 'Nebraska', value: 'Nebraska' },
    { key: 'Nevada', text: 'Nevada', value: 'Nevada' },
    { key: 'New Hampshire', text: 'New Hampshire', value: 'New Hampshire' },
    { key: 'New Jersey', text: 'New Jersey', value: 'New Jersey' },
    { key: 'New Mexico', text: 'New Mexico', value: 'New Mexico' },
    { key: 'New York', text: 'New York', value: 'New York' },
    { key: 'North Carolina', text: 'North Carolina', value: 'North Carolina' },
    { key: 'North Dakota', text: 'North Dakota', value: 'North Dakota' },
    { key: 'Ohio', text: 'Ohio', value: 'Ohio' },
    { key: 'Oklahoma', text: 'Oklahoma', value: 'Oklahoma' },
    { key: 'Oregon', text: 'Oregon', value: 'Oregon' },
    { key: 'Pennsylvania', text: 'Pennsylvania', value: 'Pennsylvania' },
    { key: 'Rhode Island', text: 'Rhode Island', value: 'Rhode Island' },
    { key: 'South Carolina', text: 'South Carolina', value: 'South Carolina' },
    { key: 'South Dakota', text: 'South Dakota', value: 'South Dakota' },
    { key: 'Tennessee', text: 'Tennessee', value: 'Tennessee' },
    { key: 'Texas', text: 'Texas', value: 'Texas' },
    { key: 'Utah', text: 'Utah', value: 'Utah' },
    { key: 'Vermont', text: 'Vermont', value: 'Vermont' },
    { key: 'Virginia', text: 'Virginia', value: 'Virginia' },
    { key: 'Washington', text: 'Washington', value: 'Washington' },
    { key: 'West Virginia', text: 'West Virginia', value: 'West Virginia' },
    { key: 'Wisconsin', text: 'Wisconsin', value: 'Wisconsin' },
    { key: 'Wyoming', text: 'Wyoming', value: 'Wyoming' },
];

export const geographicalAreaOptions = [
    {
        label: Lang.LBL_ZIP_CODE,
        name: 'GeographicalAreaZipCode',
        inputPlaceholder: Lang.PLC_ZIP_CODE,
    },
    {
        label: Lang.LBL_CITY,
        name: 'GeographicalAreaCity',
        inputPlaceholder: Lang.PLC_CITY,
    },
    {
        label: Lang.LBL_COUNTY,
        name: 'GeographicalAreaCounty',
        inputPlaceholder: Lang.PLC_COUNTY,
    },
    {
        label: Lang.LBL_OTHER,
        name: 'GeographicalAreaOthers',
        inputPlaceholder: Lang.PLC_PLEASE_ENTER_DETAILS_HERE,
    },
];

export const typeOfOrganizationOptions = [
    'Local Government',
    'Academic Researcher',
    'State/Federal Agency',
    'CA Department of Community Services & Development (CSD)',
    'Others (Please Specify)',
];

export const customerSegmentInfoOptions = [
    {
        label: 'All sectors',
        name: 'AllSectors',
    },
    {
        label: 'Residential',
        name: 'Residential',
    },
    {
        label: 'Commercial',
        name: 'Commercial',
    },
    {
        label: 'Industrial',
        name: 'Industrial',
    },
];

export const radioToggleOptions = ['Yes', 'No'];

export const utilityFieldsOptions = [
    {
        name: 'PacificGasAndElectric',
        label: Lang.LBL_PACIFIC_GAS_AND_ELECTRIC,
    },
    {
        name: 'SouthernCaliforniaEdison',
        label: Lang.LBL_SOUTHERN_CALIFORNIA_EDISON,
    },
    {
        name: 'SanDiegoGasAndElectric',
        label: Lang.LBL_SANDIEGO_GAS_AND_ELECTRIC,
    },
    {
        name: 'IsOtherUtilities',
        label: Lang.LBL_OTHER,
    },
];

export const applicationOptions = [
    { key: 'edrpHome', name: 'EDRP Home' },
    { key: 'information', name: 'Information' },
    { key: 'resources', name: 'Resources' },
    { key: 'userGuide', name: 'User Guide' },
];

export const informationAccordionData = {
    [Lang.LBL_ACCCESS_READILY_AVAILABLE]: {
        open: false,
    },
    [Lang.LBL_ONLINE_SUBMISSION]: {
        open: false,
    },
    [Lang.LBL_THINGS_TO_KNOW]: {
        open: false,
    },
    [Lang.LBL_TIME_FRAMES]: {
        open: false,
    },
    [Lang.LBL_UPDATED_AGGREGATION]: {
        open: false,
    },
    [Lang.LBL_PROGRAM_BENEFITS]: {
        open: false,
    },
    [Lang.LBL_PRIVACY_AND_SECURITY_REQ]: {
        open: false,
    },
    [Lang.LBL_HOW_TO_CONTACT_US]: {
        open: false,
    },
};

export const resoucesAccordionData = {
    [Lang.LBL_ACADEMIC_INSTITUTIONS_AND_RESEARCHERS]: {
        open: false,
    },
    [Lang.LBL_LOCAL_GOVERNMENTS]: {
        open: false,
    },
    [Lang.LBL_STATE_FEDERAL_AGENCIES]: {
        open: false,
    },
    [Lang.LBL_CALIFORNIA_COMMUNITY_SERVICES_AND_DEVELOPMENT]: {
        open: false,
    },
    [Lang.LBL_ENERGY_INFO_ADMINISTRATION]: {
        open: false,
    },
    [Lang.LBL_CERTIFICATE_OF_DESTRUCTION]: {
        open: false,
    },
};
