import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { TableAction } from '../../../../../constants/table.constant';

import {
  IORGDataTableQueryState,
  IORGDataTableOnUpdate,
  IORGDataTableColumn,
} from '../ORGDataTable.component';
import styles from './MOLTableToolBar.module.scss';
import MOLTableFilter, {
  FilterItem,
  IMOLTableFilter,
  IMOLTableFilterItemProps,
} from '../MOLTable-filter/MOLTable-filter.component';
import { ATMResponsive } from '../../../atoms/ATMResponsive/ATMResponsive.component';
import { ATMButton } from '../../../atoms/ATMButton/ATMButton.component';
import { ATMLabel } from '../../../atoms/ATMLabel/ATMLabel.component';
import { ATMIcon } from '../../../atoms/ATMIcon/ATMIcon.component';
export type IMOLTableToolbar = () => React.ReactNode | React.ReactNode;
export type IMOLTableToolbarFilteredBy =
  | boolean
  | ((values: IORGDataTableQueryState['filters']) => string[]);

export type IMOLTableToolbarProps = {
  state: IORGDataTableQueryState;
  counter: boolean;
  total: number;
  count: number;
  toolbars?: IMOLTableToolbar[] | IMOLTableFilter;
  filters?: IMOLTableFilter;
  handleChange: IORGDataTableOnUpdate;
  filteredBy?: IMOLTableToolbarFilteredBy;
  tableLabel?: any;
  tableError?: any;
  customFilter?: boolean;
  customFilterContent?: React.ReactNode;
  customFilterBtn?: React.ReactNode;
  removeCancelApplyinfilters?: boolean;
  columnFilter?: boolean;
  columns: IORGDataTableColumn<any>[];
  handleColumnFilterApply?: (columns: IORGDataTableColumn<any>[]) => void;
  dragDropIcon?: React.ReactNode;
  filterCollapsed?: boolean;
  columnSettingsHeader?: string;
  buttonSize?: string;
  toggleSize?: string;
  getFromToData?: (any) => void;
};

const FILTER_DISPLAY_LIMIT = 2;

export const MOLTableToolBar: React.FC<IMOLTableToolbarProps> = ({
  state,
  counter,
  total = 0,
  count = 0,
  toolbars = [],
  filters,
  handleChange,
  filteredBy,
  tableLabel,
  tableError,
  customFilter = false,
  customFilterContent,
  customFilterBtn,
  removeCancelApplyinfilters = false,
  columnFilter = false,
  columns,
  handleColumnFilterApply,
  dragDropIcon,
  filterCollapsed = false,
  columnSettingsHeader = 'Column Settings',
  buttonSize = 'tiny',
  toggleSize,
  getFromToData,
}) => {
  const [isFilterToggle, setIsFilterToggle] = useState(filterCollapsed);
  const [isOpenColumnFilter, setIsOpenColumnFilter] = useState(false);
  const [columnsData, setColumnsData] = useState(columns);

  if(isOpenColumnFilter){

  }else if(columnsData){

  }

  const [fromData, setFromData] = useState(
    count + (state.page - 1) * state.limit - (count - 1)
  );
  const [toData, setToData] = useState(count + (state.page - 1) * state.limit);

  useEffect(() => {
    setColumnsData(columns);
  }, [columns, setColumnsData]);

  useEffect(() => {
    setFromData(count + (state.page - 1) * state.limit - (count - 1));
    setToData(count + (state.page - 1) * state.limit);
  }, [state, count]);

  useEffect(() => {
    getFromToData?.({ fromData, toData });
  }, [fromData, toData]);

  const [errors, setErrors] = useState<Record<string, any>>({});

  const handleError = useCallback(
    (field, error: any) => {
      setErrors((values) => {
        return Object.entries({
          ...values,
          [field]: error,
        }).reduce((items: Record<string, any>, [name, err]) => {
          const list = { ...items };

          if (err) {
            list[String(name)] = error;
          }

          return list;
        }, {}) as any;
      });
    },
    [setErrors]
  );


  const handleFilterChange = useCallback(
    (name, value) => {
      const data = (state.filters || []).find((v) => v.name === name);
      const list = (state.filters || []).filter((val) => val.name !== name);

      handleChange(
        {
          ...state,
          filters: [...list, { ...(data || {}), name, value }].filter(
            (item) => {
              // Filter out empty values
              if (
                (typeof item.value === 'string' && !item.value.trim().length) ||
                (Array.isArray(item.value) && !item.value.length) ||
                item.value === null ||
                item.value === undefined
              ) {
                return false;
              }

              return true;
            }
          ),
        },
        {
          action: TableAction.FILTER,
        }
      );
    },
    [state, handleChange]
  );

  const handleOperatorChange = useCallback(
    (name, op: string) => {
      const data = (state.filters || []).find((v) => v.name === name);
      const list = (state.filters || []).filter((val) => val.name !== name);

      handleChange(
        {
          ...state,
          filters: [
            ...list,
            {
              ...(data || {
                name,
                value: undefined,
              }),
              name,
              op,
            },
          ].filter((item) => {
            // Filter out empty values
            if (
              (typeof item.value === 'string' && !item.value.trim().length) ||
              (Array.isArray(item.value) && !item.value.length) ||
              item.value === null ||
              item.value === undefined
            ) {
              return false;
            }

            return true;
          }),
        },
        {
          action: TableAction.FILTER,
        }
      );
    },
    [state, handleChange]
  );


  if (
    !counter &&
    !filters &&
    (!toolbars || (Array.isArray(toolbars) && !toolbars.length))
  ) {
    return null;
  }

  let filterTextList =
    filteredBy !== false ? (state.filters ?? []).map((val) => val.name) : [];

  if (typeof filteredBy === 'function') {
    filterTextList = filteredBy(state.filters ?? []);
  }

  const handleColumnFilterOpenClose = (flag: boolean) => {
    setIsOpenColumnFilter(flag);
  };

  const filterCount = (state.filters || []).filter(
    (value) => value.name
  ).length;

  return (
    <>
      <div className={styles.toolbar}>
        <div className={styles.tableInfo}>
          {counter && total > 0 && (
            <span className={styles.tableHeader}>{`Showing ${
              count + (state.page - 1) * state.limit - (count - 1)
            } - ${count + (state.page - 1) * state.limit} of ${total}`}</span>
          )}

          {counter && total === 0 && (
            <span className={styles.tableHeader}>No records</span>
          )}
         
          {filters && filterTextList.length > 0 && (
            <ATMResponsive greaterThan="mobile">
              <div
                className={classNames({
                  [styles.filteredBy]: true,
                  [styles.filterDot]: counter,
                })}
              >
                <span className={styles.tableHeader}>Filtered By:</span>
                <ul>
                  {[...filterTextList]
                    .slice(0, FILTER_DISPLAY_LIMIT)
                    .map((val, key) => (
                      <li key={`filter-label-${key}`}>
                        <span
                          onClick={() => setIsFilterToggle((v) => !v)}
                          role="button"
                        >
                          <strong>{val}</strong>
                        </span>
                      </li>
                    ))}
                  {filterTextList.length > FILTER_DISPLAY_LIMIT && (
                    <li key="filter-label-more">
                      <span
                        onClick={() => setIsFilterToggle((v) => !v)}
                        role="button"
                      >
                        {`+ ${filterTextList.length - FILTER_DISPLAY_LIMIT}`}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </ATMResponsive>
          )}
        </div>

        <ul className={styles.list}>
          {Array.isArray(toolbars)
            ? toolbars.map((item, key) => (
                <li key={`toolbar-${key}`}>
                  {typeof item === 'function' ? item() : item}
                </li>
              ))
            : Object.entries(toolbars)
                .map(([name, filter]: [string, IMOLTableFilterItemProps]) => ({
                  name,
                  filter,
                }))
                .map((itemProps, key) => (
                  <li key={`toolbar-${key}`}>
                    <FilterItem
                      {...itemProps}
                      value={
                        state.filters?.find(
                          (val) => val.name === itemProps.name
                        )?.value || null
                      }
                      operator={
                        state.filters?.find(
                          (val) => val.name === itemProps.name
                        )?.op
                      }
                      handleChange={handleFilterChange}
                      handleOperator={handleOperatorChange}
                      errors={errors}
                      handleError={handleError}
                      state={state}
                      setState={handleChange}
                    />
                  </li>
                ))}
          {filters && (
            <li key="toolbar-filter">
              <ATMResponsive media="mobile">
                <ATMButton
                  secondary
                  onClick={() => setIsFilterToggle((value) => !value)}
                  className={styles.filter}
                  icon
                >
                  {filterCount > 0 && (
                    <ATMLabel
                      color="blue"
                      floating
                      className={styles.filterCount}
                    >
                      {filterCount}
                    </ATMLabel>
                  )}
                  <ATMIcon name="filter" />
                </ATMButton>
              </ATMResponsive>

              <ATMResponsive greaterThan="mobile">
                <ATMButton
                  secondary
                  size={(buttonSize as any) || 'tiny'}
                  onClick={() => setIsFilterToggle((value) => !value)}
                  className={styles.filter}
                >
                  {filterCount > 0 && (
                    <ATMLabel
                      color="blue"
                      floating
                      className={styles.filterCount}
                    >
                      {filterCount}
                    </ATMLabel>
                  )}
                  <ATMIcon name="filter" />
                  Filters
                </ATMButton>
              </ATMResponsive>
            </li>
          )}
          {customFilter && customFilterBtn}

          {columnFilter && (
            <li>
              <ATMButton
                icon="setting"
                size={(buttonSize as any) || 'tiny'}
                secondary
                data-testid='setting'
                onClick={() => handleColumnFilterOpenClose(true)}
              />
            </li>
          )}
        </ul>

        {tableLabel !== undefined && (
            <span
              className={counter ? styles.tableCustomLabel : styles.tableLabel}
            >
              {tableLabel}
            </span>
          )}

        {tableError !== undefined && (
            <span
              className={counter ? styles.tableCustomLabel : styles.tableError}
            >
              {tableError}
            </span>
          )}
      </div>

      {customFilterContent && <div>{customFilterContent}</div>}
      {filters && isFilterToggle && (
        <MOLTableFilter
          handleChange={handleChange}
          filterCollapsed={filterCollapsed}
          state={state}
          filters={filters}
          handleToggle={() => setIsFilterToggle((value) => !value)}
          errors={errors}
          handleError={handleError}
          removeCancelApplyinfilters={removeCancelApplyinfilters}
        />
      )}
    </>
  );
};
