import React, { FC, useEffect, useState } from 'react';
import { Grid, Menu, MenuItem, MenuMenu } from 'semantic-ui-react';
import HeaderSection from 'src/components/common/HeaderSection/headerSection.component';
import Lang from 'src/libraries/language';
import styles from './Information.module.scss';
import { informationAccordionData } from 'src/constants/home';
import { ExpandCircleDownOutlined } from '@mui/icons-material';
import { ATMHeader } from 'src/components/common/atoms/ATMHeader/ATMHeader.component';
import { ATMDivider } from 'src/components/common/atoms/ATMDivider/ATMDivider.component';

const Information: FC = () => {
    const [selectedValue, setSelectedValue] = useState(
        'Data Request and Release Process'
    );
    const [openCloseValue, setMenuOpenCloseValue] = useState('');
    const [accordionData, setAccordionData] = useState(
        informationAccordionData as any
    );

    useEffect(() => {
        setSelectedValue('Data Request and Release Process');
        setMenuOpenCloseValue('');
        setAccordionData(informationAccordionData);
    }, []);

    const handleMenuOpenClose = (value) => {
        setMenuOpenCloseValue(value);
        const newAccordionData = { ...informationAccordionData };
        if (value == 'expand all') {
            for (const property in newAccordionData) {
                newAccordionData[property].open = true;
            }
            setAccordionData(newAccordionData);
        } else if (value == 'collapse all') {
            for (const property in newAccordionData) {
                newAccordionData[property].open = false;
            }
            setAccordionData(newAccordionData);
        }
    };

    const handleToggleClick = (key) => {
        const newAccordionData = { ...informationAccordionData };
        newAccordionData[key].open = !newAccordionData[key].open;
        setAccordionData(newAccordionData);
    };

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                        {/* Breadcrumb section */}
                        <HeaderSection sections={'Information'} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Menu pointing secondary>
                <MenuItem
                    name="Data Request and Release Process"
                    active={
                        selectedValue === 'Data Request and Release Process'
                    }
                    className={styles.dataReq}
                />

                <MenuMenu position="right">
                    <MenuItem
                        name="expand all"
                        active={openCloseValue === 'expand all'}
                        onClick={() => handleMenuOpenClose('expand all')}
                    />
                    <MenuItem
                        name="collapse all"
                        active={openCloseValue === 'collapse all'}
                        onClick={() => handleMenuOpenClose('collapse all')}
                    />
                </MenuMenu>
            </Menu>

            <Grid>
                <Grid.Row>
                    <Grid.Column
                        computer={16}
                        tablet={16}
                        mobile={16}
                        className={styles.informationHeader}
                    >
                        <p>{Lang.MSG_INFORMATION_HEADER}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Grid>
                {/* First Accordion */}
                <>
                    <Grid.Row
                        key={Lang.LBL_ACCCESS_READILY_AVAILABLE}
                        className="sempraNoPaddingBottom"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(
                                        Lang.LBL_ACCCESS_READILY_AVAILABLE
                                    )
                                }
                            >
                                {Lang.LBL_ACCCESS_READILY_AVAILABLE}
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[Lang.LBL_ACCCESS_READILY_AVAILABLE]
                                ?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_ACCCESS_READILY_AVAILABLE
                                        )
                                    }
                                    className={styles.expandMore}
                                    data-testid="ExpandMore"
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_ACCCESS_READILY_AVAILABLE
                                        )
                                    }
                                    className={styles.expandLess}
                                    data-testid="ExpandLess"
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    {accordionData[Lang.LBL_ACCCESS_READILY_AVAILABLE]
                        ?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <p>{Lang.MSG_ACCESS_READILY_1}</p>
                                <p>{Lang.MSG_ACCESS_READILY_2}</p>

                                <p>
                                    {Lang.MSG_ACCESS_READILY_3}{' '}
                                    <a
                                        href="https://energydatarequest.socalgas.com."
                                        className={styles.anchorTags}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {Lang.MSG_COMMON_EMAIL_ADDRESS}
                                    </a>
                                    .
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>

                {/* Second Accordion */}
                <>
                    <Grid.Row
                        key={Lang.LBL_ONLINE_SUBMISSION}
                        className="sempraNoPaddingBottom"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(
                                        Lang.LBL_ONLINE_SUBMISSION
                                    )
                                }
                            >
                                {Lang.LBL_ONLINE_SUBMISSION}
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[Lang.LBL_ONLINE_SUBMISSION]?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_ONLINE_SUBMISSION
                                        )
                                    }
                                    className={styles.expandMore}
                                    data-testid="ExpandMore"
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_ONLINE_SUBMISSION
                                        )
                                    }
                                    className={styles.expandLess}
                                    data-testid="ExpandLess"
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    {accordionData[Lang.LBL_ONLINE_SUBMISSION]?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <p>
                                    {Lang.MSG_ONLINE_SUBMISSION_1}{' '}
                                    <a
                                        href="https://energydatarequest.socalgas.com."
                                        className={styles.anchorTags}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {Lang.MSG_COMMON_EMAIL_ADDRESS}.
                                    </a>{' '}
                                    {Lang.MSG_ONLINE_SUBMISSION_2}
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>

                {/* Third Accordion */}
                <>
                    <Grid.Row
                        key={Lang.LBL_THINGS_TO_KNOW}
                        className="sempraNoPaddingBottom"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(Lang.LBL_THINGS_TO_KNOW)
                                }
                            >
                                {Lang.LBL_THINGS_TO_KNOW}
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[Lang.LBL_THINGS_TO_KNOW]?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_THINGS_TO_KNOW
                                        )
                                    }
                                    className={styles.expandMore}
                                    data-testid="ExpandMore"
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_THINGS_TO_KNOW
                                        )
                                    }
                                    className={styles.expandLess}
                                    data-testid="ExpandLess"
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    {accordionData[Lang.LBL_THINGS_TO_KNOW]?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <p>{Lang.MSG_THINGS_TO_KNOW}</p>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>

                {/* Fourth Accordion */}
                <>
                    <Grid.Row
                        key={Lang.LBL_TIME_FRAMES}
                        className="sempraNoPaddingBottom"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(Lang.LBL_TIME_FRAMES)
                                }
                            >
                                {Lang.LBL_TIME_FRAMES}
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[Lang.LBL_TIME_FRAMES]?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(Lang.LBL_TIME_FRAMES)
                                    }
                                    className={styles.expandMore}
                                    data-testid="ExpandMore"
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(Lang.LBL_TIME_FRAMES)
                                    }
                                    className={styles.expandLess}
                                    data-testid="ExpandLess"
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    {accordionData[Lang.LBL_TIME_FRAMES]?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <p>{Lang.MSG_TIME_FRAMES_1}</p>
                                <p>{Lang.MSG_TIME_FRAMES_2}</p>
                                <ul>
                                    <li>{Lang.MSG_TIME_FRAMES_3_1}</li>
                                    <li>
                                        <strong>IMPORTANT:</strong> 
                                        {Lang.MSG_TIME_FRAMES_3_2}
                                    </li>
                                    <li>{Lang.MSG_TIME_FRAMES_3_3}</li>
                                </ul>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>

                {/* Fifth Accordion */}
                <>
                    <Grid.Row
                        key={Lang.LBL_UPDATED_AGGREGATION}
                        className="sempraNoPaddingBottom"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(
                                        Lang.LBL_UPDATED_AGGREGATION
                                    )
                                }
                            >
                                {Lang.LBL_UPDATED_AGGREGATION}
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[Lang.LBL_UPDATED_AGGREGATION]
                                ?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_UPDATED_AGGREGATION
                                        )
                                    }
                                    className={styles.expandMore}
                                    data-testid="ExpandMore"
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_UPDATED_AGGREGATION
                                        )
                                    }
                                    className={styles.expandLess}
                                    data-testid="ExpandLess"
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    {accordionData[Lang.LBL_UPDATED_AGGREGATION]?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <p>{Lang.MSG_AGGREGATION_1}</p>
                                <strong>Aggregated Data</strong>
                                <p>Residential, Commercial Consumption Data</p>

                                <ul>
                                    <li>{Lang.MSG_AGGREGATION_2}</li>
                                </ul>

                                <p>Industrial Consumption Data</p>

                                <ul>
                                    <li>{Lang.MSG_AGGREGATION_3}</li>
                                </ul>

                                <strong>Anonymized Data</strong>
                                <p>
                                    Residential, Commercial, or Industrial
                                    Consumption Data
                                </p>

                                <ul>
                                    <li>{Lang.MSG_AGGREGATION_4}</li>
                                </ul>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>

                {/* Sixth Accordion */}
                <>
                    <Grid.Row
                        key={Lang.LBL_PROGRAM_BENEFITS}
                        className="sempraNoPaddingBottom"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(Lang.LBL_PROGRAM_BENEFITS)
                                }
                            >
                                {Lang.LBL_PROGRAM_BENEFITS}
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[Lang.LBL_PROGRAM_BENEFITS]?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_PROGRAM_BENEFITS
                                        )
                                    }
                                    className={styles.expandMore}
                                    data-testid="ExpandMore"
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_PROGRAM_BENEFITS
                                        )
                                    }
                                    className={styles.expandLess}
                                    data-testid="ExpandLess"
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    {accordionData[Lang.LBL_PROGRAM_BENEFITS]?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <p>{Lang.MSG_PROGRAM_BENEFITS}</p>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>

                {/* Seventh Accordion */}
                <>
                    <Grid.Row
                        key={Lang.LBL_PRIVACY_AND_SECURITY_REQ}
                        className="sempraNoPaddingBottom"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(
                                        Lang.LBL_PRIVACY_AND_SECURITY_REQ
                                    )
                                }
                            >
                                {Lang.LBL_PRIVACY_AND_SECURITY_REQ}
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[Lang.LBL_PRIVACY_AND_SECURITY_REQ]
                                ?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_PRIVACY_AND_SECURITY_REQ
                                        )
                                    }
                                    className={styles.expandMore}
                                    data-testid="ExpandMore"
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_PRIVACY_AND_SECURITY_REQ
                                        )
                                    }
                                    className={styles.expandLess}
                                    data-testid="ExpandLess"
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    {accordionData[Lang.LBL_PRIVACY_AND_SECURITY_REQ]?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <p>
                                    {Lang.MSG_PRIVACY1}{' '}
                                    <a
                                        className={styles.anchorTag}
                                        href="https://doi.org/10.6028/NIST.SP.800-53r5"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://doi.org/10.6028/NIST.SP.800-53r5
                                    </a>
                                </p>
                                <p>
                                    {Lang.MSG_PRIVACY2}{' '}
                                    <a
                                        className={styles.anchorTag}
                                        href="https://www.socalgas.com/privacy-center"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://www.socalgas.com/privacy-center
                                    </a>
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>

                {/* Eighth Accordion */}
                <>
                    <Grid.Row
                        key={Lang.LBL_HOW_TO_CONTACT_US}
                        className="sempraNoPaddingBottom"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(
                                        Lang.LBL_HOW_TO_CONTACT_US
                                    )
                                }
                            >
                                {Lang.LBL_HOW_TO_CONTACT_US}
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[Lang.LBL_HOW_TO_CONTACT_US]?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_HOW_TO_CONTACT_US
                                        )
                                    }
                                    className={styles.expandMore}
                                    data-testid="ExpandMore"
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_HOW_TO_CONTACT_US
                                        )
                                    }
                                    className={styles.expandLess}
                                    data-testid="ExpandLess"
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    {accordionData[Lang.LBL_HOW_TO_CONTACT_US]?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <p>
                                    {Lang.MSG_CONTACT_US}{' '}
                                    <a
                                        className={styles.anchorTags}
                                        href="mailto:energydatarequest@socalgas.com"
                                    >
                                        energydatarequest@socalgas.com.
                                    </a>
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>
            </Grid>
        </>
    );
};

export default Information;
