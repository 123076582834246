import React, { FC, useEffect, useState } from 'react';
import { Grid, Menu, MenuItem, MenuMenu } from 'semantic-ui-react';
import HeaderSection from 'src/components/common/HeaderSection/headerSection.component';
import Lang from 'src/libraries/language';
import styles from './Resources.module.scss';
import { resoucesAccordionData } from 'src/constants/home';
import { ExpandCircleDownOutlined } from '@mui/icons-material';
import { ATMHeader } from 'src/components/common/atoms/ATMHeader/ATMHeader.component';
import { ATMDivider } from 'src/components/common/atoms/ATMDivider/ATMDivider.component';

const Resources: FC = () => {
    const [selectedValue, setSelectedValue] = useState(
        `${Lang.LBL_REQUIRED_AGREEMENTS}`
    );
    const [openCloseValue, setMenuOpenCloseValue] = useState('');
    const [accordionData, setAccordionData] = useState(
        resoucesAccordionData as any
    );

    useEffect(() => {
        setSelectedValue(`${Lang.LBL_REQUIRED_AGREEMENTS}`);
        setMenuOpenCloseValue('');
        setAccordionData(resoucesAccordionData);
    }, []);

    const handleMenuOpenClose = (value) => {
        setMenuOpenCloseValue(value);
        const newAccordionData = { ...resoucesAccordionData };
        if (value == 'expand all') {
            for (const property in newAccordionData) {
                newAccordionData[property].open = true;
            }
            setAccordionData(newAccordionData);
        } else if (value == 'collapse all') {
            for (const property in newAccordionData) {
                newAccordionData[property].open = false;
            }
            setAccordionData(newAccordionData);
        }
    };

    const handleToggleClick = (key) => {
        const newAccordionData = { ...resoucesAccordionData };
        newAccordionData[key].open = !newAccordionData[key].open;
        setAccordionData(newAccordionData);
    };

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                        {/* Breadcrumb section */}
                        <HeaderSection sections={'Resources'} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Menu pointing secondary>
                <MenuItem
                    name={Lang.LBL_REQUIRED_AGREEMENTS}
                    active={selectedValue === `${Lang.LBL_REQUIRED_AGREEMENTS}`}
                    className={styles.dataReq}
                />

                <MenuMenu position="right">
                    <MenuItem
                        name="expand all"
                        active={openCloseValue === 'expand all'}
                        onClick={() => handleMenuOpenClose('expand all')}
                    />
                    <MenuItem
                        name="collapse all"
                        active={openCloseValue === 'collapse all'}
                        onClick={() => handleMenuOpenClose('collapse all')}
                    />
                </MenuMenu>
            </Menu>

            <Grid>
                {/* First Accordion */}
                <>
                    <Grid.Row
                        key={Lang.LBL_ACADEMIC_INSTITUTIONS_AND_RESEARCHERS}
                        className="sempraNoPaddingBottom sempraLargeLargePaddingTop"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(
                                        Lang.LBL_ACADEMIC_INSTITUTIONS_AND_RESEARCHERS
                                    )
                                }
                            >
                                {Lang.LBL_ACADEMIC_INSTITUTIONS_AND_RESEARCHERS}
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[
                                Lang.LBL_ACADEMIC_INSTITUTIONS_AND_RESEARCHERS
                            ]?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_ACADEMIC_INSTITUTIONS_AND_RESEARCHERS
                                        )
                                    }
                                    className={styles.expandMore}
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_ACADEMIC_INSTITUTIONS_AND_RESEARCHERS
                                        )
                                    }
                                    className={styles.expandLess}
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    {accordionData[
                        Lang.LBL_ACADEMIC_INSTITUTIONS_AND_RESEARCHERS
                    ]?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <p>{Lang.MSG_ACADEMIC_RESEARCHER_1}</p>
                                <ul>
                                    <li>{Lang.MSG_ACADEMIC_RESEARCHER_1_1}</li>
                                    <li>{Lang.MSG_ACADEMIC_RESEARCHER_1_2}</li>
                                    <li>{Lang.MSG_ACADEMIC_RESEARCHER_1_3}</li>
                                    <li>{Lang.MSG_ACADEMIC_RESEARCHER_1_4}</li>
                                </ul>

                                <p>{Lang.MSG_ACADEMIC_RESEARCHER_2}</p>
                                <ul>
                                    <li>{Lang.MSG_ACADEMIC_RESEARCHER_2_1}</li>
                                </ul>

                                <p>
                                    {Lang.MSG_ACADEMIC_RESEARCHER_3}
                                    {'  '}
                                    <a
                                        href={require('../../../assets/files/socalGasNDA.pdf')}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className={styles.anchorTags}
                                    >
                                        {Lang.LBL_VIEW_SAMPLE_AGREEMENT}
                                    </a>
                                </p>
                                <ul>
                                    <li>{Lang.MSG_ACADEMIC_RESEARCHER_3_1}</li>
                                </ul>

                                <p>
                                    <strong>NOTE:</strong> {' '}
                                    {Lang.MSG_ACADEMIC_RESEARCHER_4}
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>

                {/* Second Accordion */}
                <>
                    <Grid.Row
                        key={Lang.LBL_LOCAL_GOVERNMENTS}
                        className="sempraNoPaddingBottom"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(
                                        Lang.LBL_LOCAL_GOVERNMENTS
                                    )
                                }
                            >
                                {Lang.LBL_LOCAL_GOVERNMENTS}
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[Lang.LBL_LOCAL_GOVERNMENTS]?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_LOCAL_GOVERNMENTS
                                        )
                                    }
                                    className={styles.expandMore}
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_LOCAL_GOVERNMENTS
                                        )
                                    }
                                    className={styles.expandLess}
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    {accordionData[Lang.LBL_LOCAL_GOVERNMENTS]?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <p>{Lang.MSG_LOCAL_GOVERNMENT_1}</p>
                                <ul>
                                    <li>
                                        {Lang.MSG_LOCAL_GOVERNMENT_1_1}
                                        {'  '}
                                        <a
                                            href={require('../../../assets/files/socalGasT&C.pdf')}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className={styles.anchorTags}
                                        >
                                            {Lang.LBL_VIEW_SAMPLE_AGREEMENT}
                                        </a>
                                    </li>
                                </ul>

                                <p>{Lang.MSG_LOCAL_GOVERNMENT_2}</p>
                                <ul>
                                    <li>
                                        {Lang.MSG_LOCAL_GOVERNMENT_2_1}
                                        {'  '}
                                        <a
                                            href={require('../../../assets/files/socalGasT&C.pdf')}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className={styles.anchorTags}
                                        >
                                            {Lang.LBL_VIEW_SAMPLE_AGREEMENT}
                                        </a>
                                    </li>
                                    <li>
                                        {Lang.MSG_LOCAL_GOVERNMENT_2_2}
                                        {'  '}
                                        <a
                                            href={require('../../../assets/files/socalGasNDA.pdf')}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className={styles.anchorTags}
                                        >
                                            {Lang.LBL_VIEW_SAMPLE_AGREEMENT}
                                        </a>
                                    </li>
                                </ul>

                                <p>{Lang.MSG_LOCAL_GOVERNMENT_3}</p>
                                <ul>
                                    <li>
                                        {Lang.MSG_LOCAL_GOVERNMENT_3_1}
                                        {'  '}
                                        <a
                                            href={require('../../../assets/files/socalGasT&C.pdf')}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className={styles.anchorTags}
                                        >
                                            {Lang.LBL_VIEW_SAMPLE_AGREEMENT}
                                        </a>
                                    </li>

                                    <li>{Lang.MSG_LOCAL_GOVERNMENT_3_2}</li>
                                    <li>
                                        {Lang.MSG_LOCAL_GOVERNMENT_3_3}
                                        {'  '}
                                        <a
                                            href={require('../../../assets/files/socalGasNDA.pdf')}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className={styles.anchorTags}
                                        >
                                            {Lang.LBL_VIEW_SAMPLE_AGREEMENT}
                                        </a>
                                    </li>
                                </ul>

                                <p>
                                    <strong>NOTE:</strong> {' '}
                                    {Lang.MSG_LOCAL_GOVERNMENT_4}
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>

                {/* Third Accordion */}
                <>
                    <Grid.Row
                        key={Lang.LBL_STATE_FEDERAL_AGENCIES}
                        className="sempraNoPaddingBottom"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(
                                        Lang.LBL_STATE_FEDERAL_AGENCIES
                                    )
                                }
                            >
                                {Lang.LBL_STATE_FEDERAL_AGENCIES}
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[Lang.LBL_STATE_FEDERAL_AGENCIES]
                                ?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_STATE_FEDERAL_AGENCIES
                                        )
                                    }
                                    className={styles.expandMore}
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_STATE_FEDERAL_AGENCIES
                                        )
                                    }
                                    className={styles.expandLess}
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    {accordionData[Lang.LBL_STATE_FEDERAL_AGENCIES]?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <ul>
                                    <li>{Lang.MSG_STATE_1_1}</li>
                                    <li>{Lang.MSG_STATE_1_2}</li>
                                </ul>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>

                {/* Fourth Accordion */}
                <>
                    <Grid.Row
                        key={
                            Lang.LBL_CALIFORNIA_COMMUNITY_SERVICES_AND_DEVELOPMENT
                        }
                        className="sempraNoPaddingBottom"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(
                                        Lang.LBL_CALIFORNIA_COMMUNITY_SERVICES_AND_DEVELOPMENT
                                    )
                                }
                            >
                                {
                                    Lang.LBL_CALIFORNIA_COMMUNITY_SERVICES_AND_DEVELOPMENT
                                }
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[
                                Lang
                                    .LBL_CALIFORNIA_COMMUNITY_SERVICES_AND_DEVELOPMENT
                            ]?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_CALIFORNIA_COMMUNITY_SERVICES_AND_DEVELOPMENT
                                        )
                                    }
                                    className={styles.expandMore}
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_CALIFORNIA_COMMUNITY_SERVICES_AND_DEVELOPMENT
                                        )
                                    }
                                    className={styles.expandLess}
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    {accordionData[
                        Lang.LBL_CALIFORNIA_COMMUNITY_SERVICES_AND_DEVELOPMENT
                    ]?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <ul>
                                    <li>
                                        {Lang.MSG_CALIFORNIA_1_1}
                                        {'  '}
                                        <a
                                            href={require('../../../assets/files/socalGasT&C.pdf')}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className={styles.anchorTags}
                                        >
                                            {Lang.LBL_VIEW_SAMPLE_AGREEMENT}
                                        </a>
                                    </li>
                                    <li>
                                        {Lang.MSG_CALIFORNIA_1_2}
                                        {'  '}
                                        <a
                                            href={require('../../../assets/files/socalGasNDA.pdf')}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className={styles.anchorTags}
                                        >
                                            {Lang.LBL_VIEW_SAMPLE_AGREEMENT}
                                        </a>
                                    </li>
                                </ul>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>

                {/* Fifth Accordion */}
                <>
                    <Grid.Row
                        key={Lang.LBL_ENERGY_INFO_ADMINISTRATION}
                        className="sempraNoPaddingBottom"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(
                                        Lang.LBL_ENERGY_INFO_ADMINISTRATION
                                    )
                                }
                            >
                                {Lang.LBL_ENERGY_INFO_ADMINISTRATION}
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[Lang.LBL_ENERGY_INFO_ADMINISTRATION]
                                ?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_ENERGY_INFO_ADMINISTRATION
                                        )
                                    }
                                    className={styles.expandMore}
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_ENERGY_INFO_ADMINISTRATION
                                        )
                                    }
                                    className={styles.expandLess}
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    {accordionData[Lang.LBL_ENERGY_INFO_ADMINISTRATION]
                        ?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <ul>
                                    <li>{Lang.MSG_ENERGY_INFO_1_1}</li>
                                </ul>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>

                {/* Sixth Accordion */}
                <>
                    <Grid.Row
                        key={Lang.LBL_CERTIFICATE_OF_DESTRUCTION}
                        className="sempraNoPaddingBottom"
                    >
                        <Grid.Column computer={8} tablet={8} mobile={8}>
                            <ATMHeader
                                as="h2"
                                className={styles.headerStyle}
                                onClick={() =>
                                    handleToggleClick(
                                        Lang.LBL_CERTIFICATE_OF_DESTRUCTION
                                    )
                                }
                            >
                                {Lang.LBL_CERTIFICATE_OF_DESTRUCTION}
                            </ATMHeader>
                        </Grid.Column>

                        <Grid.Column
                            computer={8}
                            tablet={8}
                            mobile={8}
                            floated="right"
                            textAlign="right"
                        >
                            {accordionData[Lang.LBL_CERTIFICATE_OF_DESTRUCTION]
                                ?.open ? (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_CERTIFICATE_OF_DESTRUCTION
                                        )
                                    }
                                    className={styles.expandMore}
                                />
                            ) : (
                                <ExpandCircleDownOutlined
                                    onClick={() =>
                                        handleToggleClick(
                                            Lang.LBL_CERTIFICATE_OF_DESTRUCTION
                                        )
                                    }
                                    className={styles.expandLess}
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    {accordionData[Lang.LBL_CERTIFICATE_OF_DESTRUCTION]
                        ?.open && (
                        <Grid.Row>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                mobile={16}
                                className={styles.informationHeader}
                            >
                                <p>{Lang.MSG_CERTIFICATE_OF_DESTRUCTION_1}</p>
                                <br />
                                <p>{Lang.MSG_CERTIFICATE_OF_DESTRUCTION_2}</p>
                                <br />
                                <a
                                    className={styles.anchorTags}
                                    href={require('../../../assets/files/SCG_Certificate_Data_Destruction.pdf')}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {
                                        Lang.LBL_DOWNLOAD_CERTIFICATE_OF_DESTRUCTION
                                    }
                                </a>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <ATMDivider className={styles.divider} />
                    </Grid.Row>
                </>
            </Grid>
        </>
    );
};

export default Resources;
