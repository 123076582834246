import { utilityFieldsOptions } from 'src/constants/home';
import Lang from 'src/libraries/language';
import CryptoJS from "crypto-js"
// Encrypt google reCaptcha site key for api validation
export const encryptGCPSKey = (): string => {
    try {
        // The data to be encrypted
        const gSiteKey: any = process.env.REACT_APP_GOOGLE_SITE_KEY;
        // The secret key for encryption
        const secretKey: any = process.env.REACT_APP_AES_SECRET_KEY;
        // Encrypting the data
        const encryptedData = CryptoJS.AES.encrypt(gSiteKey, secretKey).toString();
        return encryptedData;
        // return encryptedData;
    } catch (err) {
        console.log("EncryptErr", err);
        return "null";
    }
};

export const textTruncate = (str: any, length: number) => {
    const ending = '...';
    if (str?.length > length) {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return str.substring(0, length - ending?.length) + ending;
    }
    return str;
};

export const filterYears = (arr, fromYear, toYear) => {
    const result = arr.filter(
        (item) => item.year >= Number(fromYear) && item.year <= Number(toYear)
    );
    return result;
};

export const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

export const isBlacklistedDomain = (email: string) => {
    const blackListedDomains = [
        'facebook',
        'google',
        'amazon',
        'netflix',
        'hotmail',
        'microsoft',
        'gmail',
        'yahoo',
        'orkut',
    ];

    const domain = email.substring(email.indexOf('@') + 1, email.indexOf('.'));
    return blackListedDomains.includes(domain);
};

export const isAllowedDomain = (email: string) => {
    const allowedDomains = [
        'socalgas.com',
        'sempra.com',
        'sdge.com',
        'scgcontractor.com',
        'sdgecontractor.com',
        'seuutilities.com',
    ];

    const domain = email.split('@')[1].toLowerCase();
    return allowedDomains.includes(domain);
};

export const validateForm = (name, formData, fieldErrors) => {
    const errors = fieldErrors as any;
    console.log(formData, name)

    switch (name) {
        // Organization
        case 'OrganizationName':
            if (!formData?.OrganizationName) {
                errors.OrganizationName = 'This field is required';
            } else if (
                formData?.OrganizationName.length < 2 ||
                formData?.OrganizationName.length > 100
            ) {
                errors.OrganizationName = 'Min 2 and Max 100 characters';
            } else {
                errors.OrganizationName = null;
            }
            break;
        case 'AddressLine1':
            if (
                !formData?.AddressLine1 ||
                formData?.AddressLine1.length < 2 ||
                formData?.AddressLine1.length > 100
            ) {
                errors.AddressLine1 = 'Min 2 and Max 100 characters';
            } else {
                errors.AddressLine1 = null;
            }
            break;

        case 'AddressLine2':
            if (formData?.AddressLine2 == '') {
                delete formData.AddressLine2;
            }
            break;

        case 'City':
            if (
                !formData?.City ||
                formData?.City.length < 2 ||
                formData?.City.length > 100
            ) {
                errors.City = 'Min 2 and Max 100 characters';
            } else {
                errors.City = null;
            }
            break;
        case 'State':
            if (!formData?.State) {
                errors.State = 'This field is required';
            } else {
                errors.State = null;
            }
            break;
        case 'ZipCode':
            const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
            if (!formData?.ZipCode || !zipRegex.test(formData?.ZipCode)) {
                errors.ZipCode = 'Please enter a valid zip code';
            } else if (/^0+$/.test(formData?.ZipCode.replace(/\D+/g, ''))) {
                errors.ZipCode = `Zip Code cannot have all the 0's.`;
            } else {
                errors.ZipCode = null;
            }
            break;
        case 'EIN':
            const einRegex = /^\d{2}-?\d{7}$/;
            if (!formData?.EIN || !einRegex.test(formData?.EIN)) {
                errors.EIN = 'Please enter a valid EIN number';
            } else if (/^0+$/.test(formData?.EIN.replace(/\D+/g, ''))) {
                errors.EIN = `EIN cannot have all the 0's.`;
            } else {
                errors.EIN = null;
            }
            break;
        case 'OrganizationType':
            if (!formData?.OrganizationType) {
                errors.OrganizationType = 'This field is required';
            } else {
                errors.OrganizationType = null;
            }
            break;
        case 'RequesterName':
            if (
                !formData?.RequesterName ||
                formData?.RequesterName.length < 2 ||
                formData?.RequesterName.length > 100
            ) {
                errors.RequesterName = 'Min 2 and Max 100 characters';
            } else {
                errors.RequesterName = null;
            }
            break;
        case 'Role':
            if (
                !formData?.Role ||
                formData?.Role.length < 2 ||
                formData?.Role.length > 100
            ) {
                errors.Role = 'Min 2 and Max 100 characters';
            } else {
                errors.Role = null;
            }
            break;
        case 'ContactNumber':
            if (!formData?.ContactNumber) {
                errors.ContactNumber = 'This field is required';
            } else if (
                formData?.ContactNumber?.length !== 14 &&
                formData?.ContactNumber?.length !== 17
            ) {
                errors.ContactNumber = 'Phone Number is not valid.';
            } else if (
                /^0+$/.test(formData?.ContactNumber.replace(/\D+/g, ''))
            ) {
                errors.ContactNumber = `Phone Number cannot have all the 0's.`;
            } else {
                errors.ContactNumber = null;
            }
            break;
        case 'Email':
            if (
                !formData?.Email ||
                !isValidEmail(formData?.Email) ||
                isBlacklistedDomain(formData?.Email)
            ) {
                errors.Email = 'Not a valid email or blacklisted domain';
            } else {
                errors.Email = null;
            }
            if (( formData?.confirmEmailAddress!==undefined ) && formData?.confirmEmailAddress !== formData.Email) {
                console.log(formData?.confirmEmailAddress)
                errors.confirmEmailAddress = 'Email should match!';
            }
            break;
        case 'confirmEmailAddress':
            if(formData?.confirmEmailAddress === "" || formData?.confirmEmailAddress === undefined ){
                errors.confirmEmailAddress = "This field is required"
            }
            else if (formData?.confirmEmailAddress !== formData.Email) {
                errors.confirmEmailAddress = 'Email should match!';
            } else {
                errors.confirmEmailAddress = null;
            }
            break;

        case 'IsPrimaryContact':
            if (formData?.IsPrimaryContact === 'Yes') {
                errors.IsPrimaryContact = null;
                delete formData.AdditionalRequesterName;
                delete formData.AdditionalRequesterCompany;
                delete formData.AdditionalRequesterContact;
                delete formData.AdditionalRequesterEmail;
                delete formData.confirmYourEmailAddress;
            } else {
                errors.IsPrimaryContact = null;
                formData['AdditionalRequesterName'] = '';
                formData['AdditionalRequesterCompany'] = '';
                formData['AdditionalRequesterContact'] = '';
                formData['AdditionalRequesterEmail'] = '';
                formData['confirmYourEmailAddress'] = '';

                errors.AdditionalRequesterName = null;
                errors.AdditionalRequesterCompany = null;
                errors.AdditionalRequesterContact = null;
                errors.AdditionalRequesterEmail = null;
                errors.confirmYourEmailAddress = null;
            }
            break;

        case 'AdditionalRequesterName':
            if (!formData?.AdditionalRequesterName) {
                errors.AdditionalRequesterName = 'This field is required';
            } else if (
                formData?.AdditionalRequesterName?.length < 2 ||
                formData?.AdditionalRequesterName?.length > 100
            ) {
                errors.AdditionalRequesterName = 'Min 2 and Max 100 characters';
            } else {
                errors.AdditionalRequesterName = null;
            }

            break;

        case 'AdditionalRequesterCompany':
            if (!formData?.AdditionalRequesterCompany) {
                errors.AdditionalRequesterCompany = 'This field is required';
            } else if (
                formData?.AdditionalRequesterCompany?.length < 2 ||
                formData?.AdditionalRequesterCompany?.length > 100
            ) {
                errors.AdditionalRequesterCompany =
                    'Min 2 and Max 100 characters';
            } else {
                errors.AdditionalRequesterCompany = null;
            }
            break;

        case 'AdditionalRequesterContact':
            if (!formData?.AdditionalRequesterContact) {
                errors.AdditionalRequesterContact = 'This field is required';
            } else if (
                formData?.AdditionalRequesterContact?.length !== 14 &&
                formData?.AdditionalRequesterContact?.length !== 17
            ) {
                errors.AdditionalRequesterContact =
                    'Phone Number is not valid.';
            } else if (
                /^0+$/.test(
                    formData?.AdditionalRequesterContact.replace(/\D+/g, '')
                )
            ) {
                errors.AdditionalRequesterContact = `Phone Number cannot have all the 0's.`;
            } else {
                errors.AdditionalRequesterContact = null;
            }
            break;

        case 'AdditionalRequesterEmail':
            if (!formData?.AdditionalRequesterEmail) {
                errors.AdditionalRequesterEmail = 'This field is required';
            } else if (
                !isValidEmail(formData?.AdditionalRequesterEmail) ||
                isBlacklistedDomain(formData?.AdditionalRequesterEmail)
            ) {
                errors.AdditionalRequesterEmail =
                    'Not a valid email or blacklisted domain';
            } else {
                errors.AdditionalRequesterEmail = null;
            }
            break;

        case 'confirmYourEmailAddress':
            // confirmYourEmailAddress
            if (!formData?.confirmYourEmailAddress) {
                errors.confirmYourEmailAddress = 'This field is required';
            } else if (
                formData?.confirmYourEmailAddress !==
                formData.AdditionalRequesterEmail
            ) {
                errors.confirmYourEmailAddress = 'Email should match!';
            } else {
                errors.confirmYourEmailAddress = null;
            }
            break;

        //SoCalGas fields

        case 'SoCalGasContactName':
            if (formData?.SoCalGasContactName == '') {
                delete formData.SoCalGasContactName;
                errors.SoCalGasContactName = null;
            }
            break;

        case 'SoCalGasContactEmail':
            if (formData?.SoCalGasContactEmail == '') {
                delete formData.SoCalGasContactEmail;
                errors.SocalGasContactEmail = null;
            }
            break;

        // Data Request
        case 'RequestDescription':
            if (
                !formData?.RequestDescription ||
                formData?.RequestDescription.length < 10 ||
                formData?.RequestDescription.length > 1000
            ) {
                errors.RequestDescription = 'Min 10 and Max 1000 characters';
            } else {
                errors.RequestDescription = null;
            }
            break;
        case 'RequestElements':
            if (
                !formData?.RequestElements ||
                formData?.RequestElements.length < 10 ||
                formData?.RequestElements.length > 500
            ) {
                errors.RequestElements = 'Min 10 and Max 500 characters';
            } else {
                errors.RequestElements = null;
            }
            break;

        case 'DataAudience':
            if (
                !formData?.DataAudience ||
                formData?.DataAudience.length < 10 ||
                formData?.DataAudience.length > 500
            ) {
                errors.DataAudience = 'Min 10 and Max 500 characters';
            } else {
                errors.DataAudience = null;
            }
            break;
        case 'StartDate':
            if (!formData.StartDate) {
                errors.StartDate = 'This field is required';
            } else {
                errors.StartDate = null;
            }
            break;
        case 'EndDate':
            if (!formData.EndDate) {
                errors.EndDate = 'This field is required';
            } else {
                errors.EndDate = null;
            }
            break;

        // Is Third Party Access
        case 'IsThirdPartyAccess':
            if (!formData.IsThirdPartyAccess) {
                errors.IsThirdPartyAccess = 'This field is required';
            } else {
                errors.IsThirdPartyAccess = null;
            }
            break;

        // Recurring Request
        case 'RecurringRequest':
            if (!formData?.RecurringRequest) {
                errors.RecurringRequest = 'This field is required';
            } else if (formData?.RecurringRequest == 'Yes') {
                formData = { ...formData, RecurringFrequency: '' };
                errors.RecurringRequest = null;
            } else if (formData?.RecurringRequest == 'No') {
                delete formData.RecurringFrequency;
                errors.RecurringRequest = null;
                errors.RecurringFrequency = null;
            }
            break;

        // Is From California Utility
        case 'IsFromCaliforniaUtility':
            if (!formData.IsFromCaliforniaUtility) {
                errors.IsFromCaliforniaUtility = 'This field is required';
            } else if (formData?.IsFromCaliforniaUtility === 'Yes') {
                errors.IsFromCaliforniaUtility = null;
                const obj = {} as any;
                utilityFieldsOptions.forEach((utilityField) => {
                    obj[utilityField.name];
                });
                formData = { ...formData, ...obj };
            } else if (formData?.IsFromCaliforniaUtility === 'No') {
                errors.IsFromCaliforniaUtility = null;
                utilityFieldsOptions.forEach((utilityField) => {
                    delete formData[utilityField.name];
                });
            }
            break;

        case 'PacificGasAndElectric':
            if (!formData?.PacificGasAndElectric) {
                errors.PacificGasAndElectric = 'This field is required';
            } else {
                errors.PacificGasAndElectric = null;
            }
            break;

        case 'SouthernCaliforniaEdison':
            if (!formData?.SouthernCaliforniaEdison) {
                errors.SouthernCaliforniaEdison = 'This field is required';
            } else {
                errors.SouthernCaliforniaEdison = null;
            }
            break;

        case 'SanDiegoGasAndElectric':
            if (!formData?.SanDiegoGasAndElectric) {
                errors.SanDiegoGasAndElectric = 'This field is required';
            } else {
                errors.SanDiegoGasAndElectric = null;
            }
            break;

        case 'IsOtherUtilities':
            if (!formData?.IsOtherUtilities) {
                errors.IsOtherUtilities = 'This field is required';
            } else {
                errors.IsOtherUtilities = null;
            }
            break;

        case 'IsOtherUtilitiesInput':
            if (!formData?.IsOtherUtilitiesInput) {
                errors.IsOtherUtilitiesInput = 'This field is required';
            } else {
                errors.IsOtherUtilitiesInput = null;
            }
            break;

        // Privacy and Security
        case 'SecureDataTransmitPlan':
            if (
                !formData?.SecureDataTransmitPlan ||
                formData?.SecureDataTransmitPlan?.length < 10 ||
                formData?.SecureDataTransmitPlan?.length > 500
            ) {
                errors.SecureDataTransmitPlan = 'Min 10 and Max 500 characters';
            } else {
                errors.SecureDataTransmitPlan = null;
            }
            break;
        case 'SecureDataStore':
            if (
                !formData?.SecureDataStore ||
                formData?.SecureDataStore?.length < 10 ||
                formData?.SecureDataStore?.length > 500
            ) {
                errors.SecureDataStore = 'Min 10 and Max 500 characters';
            } else {
                errors.SecureDataStore = null;
            }
            break;
        case 'SecureDataUsageProcess':
            if (
                !formData?.SecureDataUsageProcess ||
                formData?.SecureDataUsageProcess?.length < 10 ||
                formData?.SecureDataUsageProcess?.length > 500
            ) {
                errors.SecureDataUsageProcess = 'Min 10 and Max 500 characters';
            } else {
                errors.SecureDataUsageProcess = null;
            }
            break;
        case 'DataAccessControlProcess':
            if (
                !formData?.DataAccessControlProcess ||
                formData?.DataAccessControlProcess?.length < 10 ||
                formData?.DataAccessControlProcess?.length > 500
            ) {
                errors.DataAccessControlProcess =
                    'Min 10 and Max 500 characters';
            } else {
                errors.DataAccessControlProcess = null;
            }
            break;
        case 'DataDisposalProcess':
            if (
                !formData?.DataDisposalProcess ||
                formData?.DataDisposalProcess?.length < 10 ||
                formData?.DataDisposalProcess?.length > 500
            ) {
                errors.DataDisposalProcess = 'Min 10 and Max 500 characters';
            } else {
                errors.DataDisposalProcess = null;
            }
            break;
        case 'DataDisposalDate':
            if (
                formData?.DataDisposalDate == '' ||
                formData?.DataDisposalDate == null
            ) {
                errors.DataDisposalDate = 'This This field is required';
            } else {
                errors.DataDisposalDate = null;
            }
            break;

        // Geographical Area Fields are mentioned below

        case 'GeographicalAreaZipCode':
            if (!formData?.GeographicalAreaZipCode) {
                errors.GeographicalAreaZipCode = 'This field is required';
                delete formData.GeographicalAreaZipCodeInput;
            } else {
                errors.GeographicalAreaZipCode = null;
                errors.GeographicalAreaZipCodeInput = 'This field is required';
            }
            break;

        case 'GeographicalAreaZipCodeInput':
            // const zipCodes = formData?.GeographicalAreaZipCodeInput?.split(',');
            const zipCodes = formData && formData.GeographicalAreaZipCodeInput && formData?.GeographicalAreaZipCodeInput?.split(',');
            let isValid = true;
            let errorMessage = '';

            if (!formData?.GeographicalAreaZipCodeInput) {
                isValid = false;
                errorMessage = 'This field is required';
            } else if (formData?.GeographicalAreaZipCodeInput.trim() === '') {
                isValid = false;
                errorMessage = 'This field is required';
            } else {
                zipCodes.forEach((zipCode) => {
                    const trimmedZipCode = zipCode.trim();
                    if (!trimmedZipCode) return; // ignore empty strings

                    const zipRegex = /^(\d{5}-\d{4}|\d{5})$/; // Regex for 5 or 5-4 digit zip codes
                    if (!zipRegex.test(trimmedZipCode)) {
                        isValid = false;
                        errorMessage = 'Please enter a valid zip code';
                    } else if (
                        /^0+$/.test(trimmedZipCode.replace(/\D+/g, ''))
                    ) {
                        isValid = false;
                        errorMessage = `Zip Code cannot have all the 0's.`;
                    }
                });
            }

            if (!isValid) {
                errors.GeographicalAreaZipCodeInput = errorMessage;
            } else {
                errors.GeographicalAreaZipCodeInput = null;
            }
            break;

        case 'GeographicalAreaCity':
            if (!formData?.GeographicalAreaCity) {
                errors.GeographicalAreaCity = 'This field is required';
                delete formData.GeographicalAreaCityInput;
            } else {
                errors.GeographicalAreaCity = null;
                errors.GeographicalAreaCityInput = 'This field is required';
            }
            break;

        case 'GeographicalAreaCityInput':
            if (!formData?.GeographicalAreaCityInput) {
                errors.GeographicalAreaCityInput = 'This field is required';
            } else {
                errors.GeographicalAreaCityInput = null;
            }
            break;

        case 'GeographicalAreaCounty':
            if (!formData?.GeographicalAreaCounty) {
                errors.GeographicalAreaCounty = 'This field is required';
                delete formData.GeographicalAreaCountyInput;
            } else {
                errors.GeographicalAreaCounty = null;
                errors.GeographicalAreaCountyInput = 'This field is required';
            }
            break;

        case 'GeographicalAreaCountyInput':
            if (!formData?.GeographicalAreaCountyInput) {
                errors.GeographicalAreaCountyInput = 'This field is required';
            } else {
                errors.GeographicalAreaCountyInput = null;
            }
            break;

        case 'GeographicalAreaOthers':
            if (!formData?.GeographicalAreaOthers) {
                errors.GeographicalAreaOthers = 'This field is required';
                delete formData.GeographicalAreaOthersInput;
            } else {
                errors.GeographicalAreaOthers = null;
                errors.GeographicalAreaOthersInput = 'This field is required';
            }
            break;

        case 'GeographicalAreaOthersInput':
            if (!formData?.GeographicalAreaOthersInput) {
                errors.GeographicalAreaOthersInput = 'This field is required';
            } else {
                errors.GeographicalAreaOthersInput = null;
            }
            break;

        case 'AccreditedInstituteName':
            if (!formData?.AccreditedInstituteName) {
                errors.AccreditedInstituteName = 'This field is required';
            } else if (
                formData?.AccreditedInstituteName.length < 2 ||
                formData?.AccreditedInstituteName?.length > 60
            ) {
                errors.AccreditedInstituteName =
                    'Please enter a description of at least 2 characters.';
            } else {
                errors.AccreditedInstituteName = null;
            }
            break;

        case 'IsFacultyMember':
            if (!formData?.IsFacultyMember) {
                errors.IsFacultyMember = 'This field is required';
            } else if (formData?.IsFacultyMember == 'Yes') {
                delete formData.FacultyMemberSponsored;
                errors.IsFacultyMember = null;
            } else if (formData?.IsFacultyMember == 'No') {
                formData = { ...formData, FacultyMemberSponsored: '' };
                errors.IsFacultyMember = null;
            }
            break;

        case 'AcademicDepartment':
            if (!formData?.AcademicDepartment) {
                errors.AcademicDepartment = 'This field is required';
            } else if (
                formData?.AcademicDepartment.length < 2 ||
                formData?.AcademicDepartment?.length > 50
            ) {
                errors.AcademicDepartment =
                    'Please enter a description of at least 2 characters.';
            } else {
                errors.AcademicDepartment = null;
            }
            break;

        case 'HowProjectAdvancesEnergy':
            // data Request tab
            if (!formData?.HowProjectAdvancesEnergy) {
                errors.HowProjectAdvancesEnergy = 'This field is required';
            } else if (
                formData.HowProjectAdvancesEnergy?.length < 10 ||
                formData.HowProjectAdvancesEnergy?.length > 500
            ) {
                errors.HowProjectAdvancesEnergy =
                    'Min 10 and Max 500 characters';
            } else {
                errors.HowProjectAdvancesEnergy = null;
            }
            break;

        case 'HasProjectCertified':
            if (!formData.HasProjectCertified) {
                errors.HasProjectCertified = 'This field is required';
            } else {
                errors.HasProjectCertified = null;
            }
            break;

        case 'HasCertifiedByReviewBoard':
            if (!formData.HasCertifiedByReviewBoard) {
                errors.HasCertifiedByReviewBoard = 'This field is required';
            } else {
                errors.HasCertifiedByReviewBoard = null;
            }
            break;

        case 'AgreeNDASign':
            if (!formData.AgreeNDASign) {
                errors.AgreeNDASign = 'This field is required';
            } else {
                errors.AgreeNDASign = null;
            }
            break;

        case 'AllSectors':
            if (!formData.AllSectors) {
                errors.AllSectors = 'This field is required';
            } else {
                errors.AllSectors = null;
            }
            break;

        case 'Residential':
            if (!formData.Residential) {
                errors.Residential = 'This field is required';
            } else {
                errors.Residential = null;
            }
            break;

        case 'NonResidential':
            if (!formData.NonResidential) {
                errors.NonResidential = 'This field is required';
            } else {
                errors.NonResidential = null;
            }
            break;

        case 'Commercial':
            if (!formData.Commercial) {
                errors.Commercial = 'This field is required';
            } else {
                errors.Commercial = null;
            }
            break;

        case 'Industrial':
            if (!formData.Industrial) {
                errors.Industrial = 'This field is required';
            } else {
                errors.Industrial = null;
            }
            break;

        case 'CompyStatutoryObligation':
            if (!formData?.CompyStatutoryObligation) {
                errors.CompyStatutoryObligation = 'This field is required';
            } else if (formData?.CompyStatutoryObligation == 'Yes') {
                errors.CompyStatutoryObligation = null;
                formData = { ...formData, DescHowDataComply: '' };
            } else if (formData?.CompyStatutoryObligation == 'No') {
                delete formData.DescHowDataComply;
                errors.CompyStatutoryObligation = null;
            }
            break;

        case 'DescHowDataComply':
            if (!formData?.DescHowDataComply) {
                errors.DescHowDataComply = 'This field is required';
            } else if (
                formData?.DescHowDataComply.length < 10 ||
                formData?.DescHowDataComply?.length > 1000
            ) {
                errors.DescHowDataComply =
                    'Please enter a description of at least 10 characters.';
            } else {
                errors.DescHowDataComply = null;
            }
            break;

            break;

        case 'CSDComplyStatutoryObligation':
            if (!formData?.CSDComplyStatutoryObligation) {
                errors.CSDComplyStatutoryObligation = 'This field is required';
            } else if (formData?.CSDComplyStatutoryObligation == 'Yes') {
                errors.CSDComplyStatutoryObligation = null;
                formData = { ...formData, CSDDescHowDataComply: '' };
            } else if (formData?.CSDComplyStatutoryObligation == 'No') {
                delete formData.CSDDescHowDataComply;
                errors.CSDComplyStatutoryObligation = null;
            }
            break;

        case 'CSDDescHowDataComply':
            if (!formData?.CSDDescHowDataComply) {
                errors.CSDDescHowDataComply = 'This field is required';
            } else if (
                formData?.CSDDescHowDataComply.length < 10 ||
                formData?.CSDDescHowDataComply?.length > 500
            ) {
                errors.CSDDescHowDataComply =
                    'Please enter a description of at least 10 characters.';
            } else {
                errors.CSDDescHowDataComply = null;
            }
            break;

        case 'RecurringFrequency':
            if (!formData.RecurringFrequency) {
                errors.RecurringFrequency = 'This field is required';
            } else {
                errors.RecurringFrequency = null;
            }
            break;

        // faculty Member - No , formData?.IsFacultyMember == 'No':
        case 'FacultyMemberSponsored':
            if (
                !formData?.FacultyMemberSponsored ||
                formData?.FacultyMemberSponsored.length < 2 ||
                formData?.FacultyMemberSponsored?.length > 50
            ) {
                errors.FacultyMemberSponsored =
                    'Please enter a description of at least 2 characters.';
            } else {
                errors.FacultyMemberSponsored = null;
            }
            break;
    }

    // Start and End Date
    switch (true) {
        case !!formData?.StartDate && !!formData?.EndDate:
            const dateErrors = validateStartAndEndDate(formData);
            Object.assign(errors, dateErrors);
    }

    // Organization Type cases

    // others
    if (formData?.OrganizationType === 'Others (Please Specify)') {
        if (formData?.OrganizationTypeOthers === '') {
            errors.OrganizationTypeOthers = 'This is a required field.';
        } else {
            // academic fields
            delete formData.AccreditedInstituteName;
            delete formData.IsFacultyMember;
            delete formData.FacultyMemberSponsored;
            delete formData.AcademicDepartment;

            // data Request tab
            delete formData.HowProjectAdvancesEnergy;
            delete formData.HasProjectCertified;
            delete formData.HasCertifiedByReviewBoard;
            delete formData.AgreeNDASign;

            // federal fields
            delete formData.CompyStatutoryObligation;
            delete formData.DescHowDataComply;

            // csd fields
            delete formData.CSDComplyStatutoryObligation;
            delete formData.CSDDescHowDataComply;

            errors.OrganizationTypeOthers = null;
        }
    }

    // Local Government
    if (formData.OrganizationType == 'Local Government') {
        // academic fields
        delete formData.AccreditedInstituteName;
        delete formData.IsFacultyMember;
        delete formData.FacultyMemberSponsored;
        delete formData.AcademicDepartment;

        // data Request tab
        delete formData.HowProjectAdvancesEnergy;
        delete formData.HasProjectCertified;
        delete formData.HasCertifiedByReviewBoard;
        delete formData.AgreeNDASign;

        // federal fields
        delete formData.CompyStatutoryObligation;
        delete formData.DescHowDataComply;

        // csd fields
        delete formData.CSDComplyStatutoryObligation;
        delete formData.CSDDescHowDataComply;

        //Others
        // delete formData.OrganizationTypeOthers;
    }

    if (formData?.OrganizationType === 'Academic Researcher') {
        // federal fields
        delete formData.CompyStatutoryObligation;
        delete formData.DescHowDataComply;

        // csd fields
        delete formData.CSDComplyStatutoryObligation;
        delete formData.CSDDescHowDataComply;

        //Others
        // delete formData.OrganizationTypeOthers;
    }

    if (formData?.OrganizationType === 'State/Federal Agency') {
        // academic fields
        delete formData.AccreditedInstituteName;
        delete formData.IsFacultyMember;
        delete formData.FacultyMemberSponsored;
        delete formData.AcademicDepartment;

        // data Request tab
        delete formData.HowProjectAdvancesEnergy;
        delete formData.HasProjectCertified;
        delete formData.HasCertifiedByReviewBoard;
        delete formData.AgreeNDASign;

        // csd fields
        delete formData.CSDComplyStatutoryObligation;
        delete formData.CSDDescHowDataComply;

        //Others
        // delete formData.OrganizationTypeOthers;
    }

    if (
        formData?.OrganizationType ===
        'CA Department of Community Services & Development (CSD)'
    ) {
        // federal fields
        delete formData.CompyStatutoryObligation;
        delete formData.DescHowDataComply;

        // academic fields
        delete formData.AccreditedInstituteName;
        delete formData.IsFacultyMember;
        delete formData.FacultyMemberSponsored;
        delete formData.AcademicDepartment;

        // data Request tab
        delete formData.HowProjectAdvancesEnergy;
        delete formData.HasProjectCertified;
        delete formData.HasCertifiedByReviewBoard;
        delete formData.AgreeNDASign;

        //Others
        // delete formData.OrganizationTypeOthers;
    }

    return { errors, formData };
};

const validateStartAndEndDate = (submitRequestForm: any) => {
    const errors: any = {};
    // Check if start date is within the last 3 years
    const startDate = new Date(submitRequestForm?.StartDate);
    const threeYearsAgo = new Date();
    threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);
    if (startDate < threeYearsAgo) {
        errors.StartDate = 'Start date must be within the last 3 years';
    } else {
        errors.StartDate = null;
    }

    // Check if start date is less than end date
    if (
        submitRequestForm?.EndDate &&
        startDate >= new Date(submitRequestForm?.EndDate)
    ) {
        errors.StartDate = 'Start date must be less than End date';
    } else {
        errors.StartDate = null;
    }

    // Check if end date is valid
    if (submitRequestForm?.EndDate && !startDate) {
        errors.EndDate = 'End date is required';
    } else {
        errors.EndDate = null;
    }
    return errors;
};

export const downloadBase64File = (base64Data, fileName) => {
    const base64String = base64Data;
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName; // set the file name
    a.click();
};

export const extractYears = (data) => {
    const allYears: any = data?.map((item) => ({
        key: item?.year,
        text: item?.year?.toString(),
        value: item?.year,
    }));
    const yearsRange: any = [];
    const nextYearsRange: any = [];

    allYears?.sort((a, b) => a?.key - b?.key);

    for (let i = 0; i < allYears?.length; i++) {
        yearsRange?.push({
            key: `${allYears[i]?.key}`,
            text: `${allYears[i]?.text}`,
            value: `${allYears[i]?.value}`,
        });

        if (i < allYears?.length - 1) {
            nextYearsRange?.push({
                key: `${allYears[i + 1]?.key}`,
                text: `${allYears[i + 1]?.text}`,
                value: `${allYears[i + 1]?.value}`,
            });
        }
    }

    return { yearsRange, nextYearsRange };
};

export const californiaUtility = (submitRequestForm) => {
    let response = '';

    if (submitRequestForm.PacificGasAndElectric) {
        response = response + Lang.LBL_PACIFIC_GAS_AND_ELECTRIC;
    }
    if (
        submitRequestForm.PacificGasAndElectric &&
        submitRequestForm.SouthernCaliforniaEdison
    ) {
        response = response + `, `;
    }
    if (submitRequestForm.SouthernCaliforniaEdison) {
        response = response + Lang.LBL_SOUTHERN_CALIFORNIA_EDISON;
    }
    if (
        submitRequestForm.SanDiegoGasAndElectric &&
        (submitRequestForm.PacificGasAndElectric ||
            submitRequestForm.SouthernCaliforniaEdison)
    ) {
        response = response + `, `;
    }
    if (submitRequestForm.SanDiegoGasAndElectric) {
        response = response + Lang.LBL_SANDIEGO_GAS_AND_ELECTRIC;
    }
    if (
        submitRequestForm.IsOtherUtilities &&
        (submitRequestForm.SanDiegoGasAndElectric ||
            submitRequestForm.PacificGasAndElectric ||
            submitRequestForm.SouthernCaliforniaEdison)
    ) {
        response = response + `, `;
    }
    if (submitRequestForm.IsOtherUtilities) {
        response =
            response +
            `${Lang.LBL_OTHER} - (${submitRequestForm.IsOtherUtilitiesInput})`;
    }
    return response;
};

export const geographicalArea = (submitRequestForm) => {
    let response = '';

    if (submitRequestForm.GeographicalAreaZipCode) {
        response =
            response +
            `${Lang.LBL_ZIP_CODE} (${submitRequestForm?.GeographicalAreaZipCodeInput})`;
    }
    if (
        submitRequestForm.GeographicalAreaZipCode &&
        submitRequestForm.GeographicalAreaCity
    ) {
        response = response + `, `;
    }
    if (submitRequestForm.GeographicalAreaCity) {
        response =
            response +
            `${Lang.LBL_CITY} (${submitRequestForm?.GeographicalAreaCityInput})`;
    }
    if (
        submitRequestForm.GeographicalAreaCounty &&
        (submitRequestForm.GeographicalAreaZipCode ||
            submitRequestForm.GeographicalAreaCity)
    ) {
        response = response + `, `;
    }
    if (submitRequestForm.GeographicalAreaCounty) {
        response =
            response +
            `${Lang.LBL_COUNTY} (${submitRequestForm?.GeographicalAreaCountyInput})`;
    }
    if (
        submitRequestForm.GeographicalAreaOthers &&
        (submitRequestForm.GeographicalAreaCounty ||
            submitRequestForm.GeographicalAreaZipCode ||
            submitRequestForm.GeographicalAreaCity)
    ) {
        response = response + `, `;
    }
    if (submitRequestForm.GeographicalAreaOthers) {
        response =
            response +
            `${Lang.LBL_OTHER} - (${submitRequestForm?.GeographicalAreaOthersInput})`;
    }

    return response;
};

export const customerSegment = (submitRequestForm) => {
    let response = '';

    if (submitRequestForm.AllSectors) {
        response = response + 'All Sectors';
    }
    if (submitRequestForm.AllSectors && submitRequestForm.Residential) {
        response = response + `, `;
    }
    if (submitRequestForm.Residential) {
        response = response + 'Residential';
    }
    if (
        submitRequestForm.NonResidential &&
        (submitRequestForm.AllSectors || submitRequestForm.Residential)
    ) {
        response = response + `, `;
    }
    if (submitRequestForm.NonResidential) {
        response = response + 'NonResidential';
    }
    if (
        submitRequestForm.Commercial &&
        (submitRequestForm.NonResidential ||
            submitRequestForm.AllSectors ||
            submitRequestForm.Residential)
    ) {
        response = response + `, `;
    }
    if (submitRequestForm.Commercial) {
        response = response + `Commercial`;
    }
    if (
        submitRequestForm.Commercial &&
        (submitRequestForm.NonResidential ||
            submitRequestForm.AllSectors ||
            submitRequestForm.Residential ||
            submitRequestForm.Industrial)
    ) {
        response = response + `, `;
    }
    if (submitRequestForm.Industrial) {
        response = response + `Industrial`;
    }
    return response;
};
