/* istanbul ignore file */
import React from 'react';
import styles from './footer.module.scss';
import { ATMGrid } from '../atoms/ATMGrid/ATMGrid.component';
import socalLogo from '../../../assets/images/socalLogo.png';
// import digitalCert from '../../../assets/images/digitalCert.png';
import { ImageGroup, Image } from 'semantic-ui-react';
import moment from 'moment';

const FooterComponent: React.FC = () => {
    const footerHeader = `© 1998 - ${moment().year()} `
    return (
        <>
            <div className={styles.footer}>
                <hr className={styles.firstHR} />
                <ATMGrid stackable padded columns="equal">
                    <ATMGrid.Row
                        columns={2}
                        className={styles.firstLine}
                    >
                        <ATMGrid.Column computer={16} tablet={16}>
                            <ImageGroup size="tiny">
                                <Image src={socalLogo} alt="socalLogo" />
                                {/* <Image src={digitalCert} alt="digitalCert" /> */}
                            </ImageGroup>
                        </ATMGrid.Column>
                        <ATMGrid.Column
                            textAlign="left"
                            className={styles.footerText}
                            computer={16}
                            tablet={16}
                        >
                            <p>
                            Note: The reports on this website are made available pursuant to California 
                            Public Utilities Commission Decision 14-05-016. Accordingly, 
                            all such reports are provided subject to the requirements and 
                            restrictions of that Decision, including but not limited to the 
                            standards for aggregating and anonymizing customer data. SoCalGas 
                            customer accounts that cannot conform to the applicable requirements 
                            and restrictions of that Decision, as determined by SoCalGas, are 
                            excluded from such reports. The information in each such report is 
                            based on gas usage data set forth in SoCalGas customer bills, and 
                            therefore excludes gas usage data from facilities owned by SoCalGas. 
                            Although SoCalGas has used reasonable efforts to assure that the reports 
                            are up-to-date and accurate when posted on this website, SoCalGas disclaims 
                            all warranties or representations, express or implied, of any kind regarding the reports. 
                            Prior reports will NOT be adjusted once they are posted on this website.
                            </p>
                            <p>
                                Southern California Gas Company is a subsidiary of Sempra®. {footerHeader} 
                                Southern California Gas Company. SoCalGas® is a registered trademark of Southern California Gas Company. 
                                The trademarks used herein are the property of their respective owners. All rights reserved.
                            </p>
                        </ATMGrid.Column>
                    </ATMGrid.Row>

                    <ATMGrid.Row className="sempraNoPaddingTop">
                        <hr className={styles.secondHR} />
                    </ATMGrid.Row>

                    <ATMGrid.Row className="sempraNoPaddingTop">
                        <ATMGrid.Column computer={3} tablet={4} className={styles.footerText}>
                            <a href='https://www.socalgas.com/terms-and-conditions' target='_blank' rel="noreferrer" className='sempraFooterLink'>Terms & Conditions</a>
                        </ATMGrid.Column>
                        <ATMGrid.Column computer={3} tablet={4} className={styles.footerText}>
                            <a href='https://doi.org/10.6028/NIST.SP.800-53r5' target='_blank' rel="noreferrer" className='sempraFooterLink'>Security Controls Criteria</a>
                        </ATMGrid.Column>
                        <ATMGrid.Column computer={3} tablet={4} className={styles.footerText}>
                            <a href='https://www.socalgas.com/privacy-center' target='_blank' rel="noreferrer" className='sempraFooterLink'>Privacy Center</a>
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>
            </div>
        </>
    );
};

export default FooterComponent;
