import React from 'react';

import { SubmitRequestTypes } from 'src/types/submitRequest.types';

import { submitFormDefaultValues } from 'src/constants/submitRequest';

type State = {
    submitRequestForm: SubmitRequestTypes;
};

type Action =
    | {
          type: 'UPDATE_SUBMIT_REQUEST';
          payload: SubmitRequestTypes | { key: string; value: any };
      }
    | {
          type: 'RESET_SUBMIT_REQUEST_STATE';
      };

type Dispatch = (action: Action) => void;

const SubmitRequestStateContext = React.createContext<State | undefined>(
    undefined
);
const SubmitRequestDispatchContext = React.createContext<Dispatch | undefined>(
    undefined
);

const INITIAL_STATE = {
    submitRequestForm: submitFormDefaultValues,
};

function SubmitRequestReducer(state, action) {
    switch (action.type) {
        case 'UPDATE_SUBMIT_REQUEST': {
            return { ...state, submitRequestForm: { ...state.submitRequestForm, ...action.payload } };
        }
        case 'RESET_SUBMIT_REQUEST_STATE': {
            return { ...INITIAL_STATE };
        }
        default:
            return state;
    }
}

function SubmitRequestProvider({ children, newState = null }) {
    const [state, dispatch] = React.useReducer(
        SubmitRequestReducer,
        newState || INITIAL_STATE
    );

    return (
        <SubmitRequestStateContext.Provider value={state}>
            <SubmitRequestDispatchContext.Provider value={dispatch}>
                {children}
            </SubmitRequestDispatchContext.Provider>
        </SubmitRequestStateContext.Provider>
    );
}

function useSubmitRequestState() {
    const context = React.useContext(SubmitRequestStateContext);

    if (context === undefined) {
        throw new Error(
            'userSubmitRequestState must be used within SubmitRequestProvider'
        );
    }
    return context;
}

function useSubmitRequestDispatch() {
    const context = React.useContext(SubmitRequestDispatchContext);

    if (context === undefined) {
        throw new Error(
            'userSubmitRequestDispatch must be used within SubmitRequestProvider'
        );
    }

    return context;
}

export {
    SubmitRequestProvider,
    useSubmitRequestState,
    useSubmitRequestDispatch,
};
