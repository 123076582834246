import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import './assets/styles/main.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Notification } from './components/common/Notification/Notification.component';
import { NotificationProvider } from './contexts/notification.context';
import { SubmitRequestProvider } from './contexts/submitRequest.context';
import { SubmitRequestErrorsProvider } from './contexts/submitRequestErrors.context';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <SubmitRequestProvider>
            <SubmitRequestErrorsProvider>
                <NotificationProvider>
                    <QueryClientProvider client={queryClient}>
                        <App />
                        <Notification />
                    </QueryClientProvider>
                </NotificationProvider>
            </SubmitRequestErrorsProvider>
        </SubmitRequestProvider>
    </React.StrictMode>
);
