/* eslint-disable max-len */
import React from 'react';

type IProps = {
  width?: number;
  height?: number;
  color?: string;
  logoName?: string;
};

export const ATMBrandLogo: React.FC<IProps> = ({
  height,
  width,
  color,
  logoName = 'socal gas',
}) => {
  let h = 119.6;
  let w = 482.17334;

  if (width && height) {
    h = height;
    w = width;
  } else if (width) {
    const percent = width / w;

    w = width;
    h *= percent;
  } else if (height) {
    const percent = height / h;

    h = height;
    w *= percent;
  }

  const getLogo = () => {
  
    const socalGasLogo = (
      <svg
        viewBox="0 0 185.66666 75.653336"
        height="40.653336"
        width="80.66666"
        id="svg2"
        version="1.1"
        style={{ width, height: height ?? 'auto', fill: color ?? '#001A70' }}
      >
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,75.653333)" id="g10">
          <g transform="scale(0.1)" id="g12">
            <path
              id="path14"
              style={{ fill: '#001A70' }}
              d="M 0,567.422 H 305.426 V 9.35547 h -92.988 c 17.488,58.63283 16.523,130.51553 4.941,191.38653 -4.805,25.238 -10.09,50.512 -16.934,75.281 l -0.332,0.61 -0.976,-2.934 C 176.898,191.895 161.801,93.6914 187.457,9.35547 H 161.703 C 136.617,44.9805 118.48,84.6953 111.449,127.766 c -2.988,23.699 -3.398,50.769 -0.965,74.976 7.996,65.641 29.883,125.555 54.102,184.446 10.918,26.5 24.727,50.625 36.766,77 l 0.078,78.046 C 152.133,456.676 107.055,367.09 81.9102,270.859 66.2148,210.648 61,137.488 81.0898,77.9102 88.9688,54.1797 99.7695,30.7813 113.59,9.35547 H 0 V 567.422"
            />
            <path
              id="path16"
              style={{ fill: '#001A70' }}
              d="m 486.188,210.938 v 6.406 c 0,21.308 -2.11,30.133 -10.313,40.746 -9.445,11.59 -27.668,18.238 -51.113,18.238 -39.817,0 -62.969,-21.558 -62.969,-58.664 0,-15.203 4.871,-27.391 13.992,-35.926 7.61,-6.679 21.016,-15.215 35.586,-22.793 30.129,-15.547 35.285,-20.683 35.285,-37.109 0,-15.203 -7.91,-23.6837 -21.613,-23.6837 -16.688,0 -21.277,7.5237 -21.883,35.4997 h -43.176 c 0,-24.875 1.536,-32.773 7.305,-42.8825 8.496,-14.8632 29.211,-23.0742 55.938,-23.0742 21.906,0 40.168,5.1953 50.519,14.2852 9.734,8.789 15.527,25.5395 15.527,44.7385 0,26.797 -9.757,41.328 -37.101,55.976 l -17.988,9.082 c -20.664,10.957 -28.868,20.403 -28.868,33.477 0,12.812 7.286,20.664 19.172,20.664 14.258,0 18.535,-7.852 18.848,-34.98 h 42.852"
            />
            <path
              id="path18"
              style={{ fill: '#001A70' }}
              d="m 618.949,146.152 c 0,14.922 -1.219,29.192 -3.301,37.403 -4.609,19.492 -21.648,28.925 -52.664,28.925 -18.261,0 -30.714,-3.667 -39.211,-11.25 -9.441,-8.535 -13.406,-23.769 -13.406,-51.992 l 0.332,-4.277 0.262,-17.371 c 0.324,-28.0002 2.453,-37.0822 10.969,-46.2228 8.222,-8.4766 21.855,-12.5195 42.265,-12.5195 18.602,0 30.145,2.7734 40.145,10.7031 10.351,8.2422 13.976,18.8476 14.297,44.4332 z m -69.031,-0.261 v 16.714 c 0,18.528 4.258,25.84 14.277,25.84 13.407,0 15.496,-6.98 15.496,-52.066 v -9.082 l -0.593,-10.949 c -0.586,-18.2035 -3.926,-23.6917 -14.571,-23.6917 -11.574,0 -14.609,6.6679 -14.609,31.9337 v 2.129 19.172"
            />
            <path
              id="path20"
              style={{ fill: '#001A70' }}
              d="m 730.855,138.262 c -1.183,-32.227 -5.46,-39.512 -22.832,-39.512 -8.8,0 -15.48,3.914 -17.941,10.918 -3.027,7.898 -4.559,26.777 -4.559,60.527 0,21.016 0.606,36.2 1.532,49.293 1.203,17.621 8.222,25.239 22.5,25.239 14.004,0 20.41,-7.618 20.656,-24.922 0.332,-2.75 0.332,-6.703 0.644,-11.883 h 41.942 v 11.566 c 0,9.731 -1.172,17.938 -2.996,24.969 -6.106,19.777 -28.633,31.906 -59.629,31.906 -20.684,0 -37.422,-5.179 -48.965,-14.875 -14.902,-12.832 -18.574,-30.148 -18.574,-90.093 0,-23.133 0.918,-44.676 2.148,-56.864 3.653,-31.3279 24.637,-46.8357 63.555,-46.8357 22.812,0 41.648,5.4883 51.414,15.1953 9.699,9.4922 12.766,22.2264 13.047,55.3714 h -41.942"
            />
            <path
              id="path22"
              style={{ fill: '#001A70' }}
              d="m 833.473,169.91 c 0.312,17.641 2.097,21.301 11.855,21.301 10.027,0 12.469,-4.277 12.469,-21.301 v -11.57 c -6.387,0.312 -9.442,0.312 -12.774,0.312 -39.246,0 -52.937,-12.5 -52.937,-48.984 0,-27.9805 10.371,-40.7539 31.98,-40.7539 13.704,0 23.106,4.5429 33.731,17.3359 l 0.586,-15.5195 h 38.945 c -1.211,11.2812 -1.523,15.832 -1.523,21.3008 v 9.7457 56.563 c 0,25.879 -1.512,33.144 -8.504,41.367 -6.692,8.215 -21.613,12.758 -40.774,12.758 -15.214,0 -27.988,-2.695 -37.41,-8.207 -10.637,-6.035 -13.39,-13.047 -13.672,-34.348 z m 24.324,-32.508 V 120 c 0,-20.1055 -4.246,-27.6172 -15.234,-27.6172 -9.09,0 -12.469,5.3906 -12.469,20.3122 0,19.825 4.277,24.707 21.316,24.707 h 6.387"
            />
            <path
              id="path24"
              style={{ fill: '#001A70' }}
              d="M 960.016,273.301 H 921.383 V 70.7305 h 38.633 V 273.301"
            />
            <path
              id="path26"
              style={{ fill: '#001A70' }}
              d="m 1052.53,178.184 v -31.707 h 21.92 v -46.196 c -8.84,-1.2654 -12.82,-1.5115 -19.77,-1.5115 -13.99,0 -20.71,3.6135 -24.06,13.0465 -2.72,7.286 -3.36,18.848 -3.36,59.036 0,10.054 0.32,18.25 0.64,25.515 l 0.62,16.438 c 0.89,25.261 6.98,33.75 24.32,33.75 14.9,0 21.27,-7.297 21.27,-24.653 l 0.34,-10.293 h 41.92 c -0.25,20.071 -0.57,21.567 -2.7,30.996 -4.9,22.215 -26.12,33.813 -62.65,33.813 -10.33,0 -20.1,-1.227 -28.33,-3.344 -18.22,-5.5 -32.217,-22.246 -35.264,-42.972 -1.817,-13.934 -3.367,-41.918 -3.367,-66.235 0,-29.211 0.625,-43.203 3.367,-56.015 4.855,-26.4965 27.094,-40.1762 64.184,-40.1762 21.87,0 41.99,3.0469 64.76,8.8476 V 178.184 h -63.84"
            />
            <path
              id="path28"
              style={{ fill: '#001A70' }}
              d="m 1181.28,169.922 c 0.32,17.644 2.17,21.25 11.92,21.25 10.01,0 12.44,-4.199 12.44,-21.25 v -11.524 c -6.43,0.254 -9.45,0.254 -12.77,0.254 -39.27,0 -52.97,-12.5 -52.97,-48.984 0,-27.9805 10.39,-40.7227 32,-40.7227 13.68,0 23.08,4.5781 33.74,17.3047 l 0.61,-15.5195 h 38.93 c -1.17,11.25 -1.53,15.8398 -1.53,21.332 v 9.7145 56.621 c 0,25.84 -1.5,33.118 -8.52,41.309 -6.68,8.258 -21.61,12.793 -40.72,12.793 -15.22,0 -27.99,-2.766 -37.45,-8.203 -10.62,-6.094 -13.4,-13.125 -13.69,-34.375 z m 24.36,-32.559 v -17.324 c 0,-20.0898 -4.28,-27.7148 -15.21,-27.7148 -9.15,0 -12.47,5.4961 -12.47,20.3708 0,19.825 4.22,24.668 21.25,24.668 h 6.43"
            />
            <path
              id="path30"
              style={{ fill: '#001A70' }}
              d="m 1365.06,169.297 c 0,16.125 -1.8,22.558 -8.49,30.078 -7.91,8.84 -20.65,13.125 -39.54,13.125 -32.55,0 -51.11,-14.297 -51.11,-39.238 0,-16.114 7.86,-26.426 28.02,-36.504 l 10.29,-4.863 c 21.29,-10.665 25.57,-14.922 25.57,-25.293 0,-8.7895 -5.44,-14.8832 -13.68,-14.8832 -9.46,0 -12.8,5.2148 -13.09,18.5942 0,1.503 0,3.664 -0.28,6.375 h -37.77 v -3.7 c 0,-30.4372 16.78,-44.0427 52.96,-44.0427 31.28,0 50.48,15.7617 50.48,42.2847 0,10.293 -3.64,19.688 -10.3,26.458 -6.09,6.074 -14.34,11.218 -29.52,18.835 l -8.5,4.266 c -12.19,6.086 -16.46,10.949 -16.46,18.254 0,7.93 4.27,12.129 11.86,12.129 8.2,0 11.87,-4.512 11.87,-15.469 0,-1.523 0.33,-3.633 0.6,-6.406 h 37.09"
            />
          </g>
        </g>
      </svg>
    );

    switch (logoName) {
      default:
        return socalGasLogo;
    }
  };
  return <div style={{ lineHeight: 0 }}>{getLogo()}</div>;
};

export default ATMBrandLogo;
