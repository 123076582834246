import React, { FC } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import routes from './router';
import PrivateLayout from './components/common/Layout/private.component';
import Erro404 from './components/pages/error/error-404/error-404.component';

const AppRouter: FC = () => {
    return (
        <>
            <Router>
                <PrivateLayout>
                    <Routes>
                        {routes}
                        <Route path='*' element={<Erro404 />} />
                    </Routes>
                </PrivateLayout>
            </Router >
        </>
    );
};

export default AppRouter;
