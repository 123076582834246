import React, { useEffect, useState } from 'react';
import { ATMHeader } from 'src/components/common/atoms/ATMHeader/ATMHeader.component';
import { ATMSegment } from 'src/components/common/atoms/ATMSegment/ATMSegment.component';
import { ATMField } from 'src/components/common/atoms/ATMField/ATMField.component';
import styles from './SubmitRequest.module.scss';
import './SubmitRequest.module.scss';
import {
    ExpandLess,
    ExpandMore,
    ModeEdit,
    Security,
} from '@mui/icons-material';
import Lang from 'src/libraries/language';
import { ATMGrid } from 'src/components/common/atoms/ATMGrid/ATMGrid.component';
import { ATMDivider } from 'src/components/common/atoms/ATMDivider/ATMDivider.component';
import { ATMTextArea } from 'src/components/common/atoms/ATMTextArea/ATMTextArea.component';

import moment from 'moment';
import { ATMDatePicker } from 'src/components/common/atoms/ATMDatePicker/ATMDatePicker.component';
import { ATMButton } from 'src/components/common/atoms/ATMButton/ATMButton.component';
import {
    useSubmitRequestDispatch,
    useSubmitRequestState,
} from 'src/contexts/submitRequest.context';
import {
    useSubmitRequestErrorsDispatch,
    useSubmitRequestErrorsState,
} from 'src/contexts/submitRequestErrors.context';
import { validateForm } from 'src/utils/common.utils';

interface PrivacyAndSecurityProps {
    setSecurityToggle: (value: boolean) => void;
    SecurityToggle: boolean;
    handleDataChange: ([_, data]: [any, any]) => any;
    control: any;
    reviewAndSubmitButton: boolean;
    setReviewAndSubmitButton: (value: boolean) => void;
}

const PrivacyAndSecurity: React.FC<PrivacyAndSecurityProps> = ({
    setSecurityToggle,
    SecurityToggle,
    handleDataChange,
    control,
    reviewAndSubmitButton,
    setReviewAndSubmitButton,
}) => {
    const [showPrivacyStaticData, setShowPrivacyStaticData] = useState(false);
    const { submitRequestForm } = useSubmitRequestState();
    const dispatchForm = useSubmitRequestDispatch();
    const { submitRequestErrors } = useSubmitRequestErrorsState();
    const dispatchErrors = useSubmitRequestErrorsDispatch();

    const datePicker = (props) => {
        return (
            <ATMDatePicker
                format="MM/DD/YYYY"
                pointing="top left"
                allowOnlyNumbers={false}
                value={props.value !== '' ? new Date(props.value) : undefined}
                defaultValue={
                    props.value !== '' ? new Date(props.value) : undefined
                }
                onChange={props.onChange}
                filterDate={(date) => {
                    const now = new Date();
                    return date >= now;
                }}
                {...props}
            />
        );
    };

    useEffect(() => {
        if (reviewAndSubmitButton) {
            setShowPrivacyStaticData(true);
        } else {
            setShowPrivacyStaticData(false);
        }
    }, [reviewAndSubmitButton]);

    const handleEditClick = () => {
        setShowPrivacyStaticData(false);
        setReviewAndSubmitButton(false);
    };

    const handleOnBlur = (data) => {
        const fieldName = data.name;
        const fieldValue = data.value;

        submitRequestForm[fieldName] = fieldValue;
        const { errors, formData } = validateForm(
            fieldName,
            submitRequestForm,
            submitRequestErrors
        );

        dispatchForm({
            type: 'UPDATE_SUBMIT_REQUEST',
            payload: formData,
        });

        dispatchErrors({
            type: 'UPDATE_SUBMIT_REQUEST_ERRORS',
            payload: errors,
        });

        return fieldValue;
    };

    const handleToggle = () => {
        setSecurityToggle(!SecurityToggle);
    };

    return (
        <ATMSegment size="tiny" className={styles.commonMargin}>
            <ATMGrid>
                <ATMGrid.Row>
                    <ATMGrid.Column
                        width={8}
                        className={styles.segmentsStyle}
                        onClick={handleToggle}
                    >
                        <ATMHeader as="h2" className={styles.headerStyle}>
                            <Security />
                            {Lang.LBL_PRIVACY_AND_SECURITY}
                        </ATMHeader>
                    </ATMGrid.Column>

                    {!!SecurityToggle ? (
                        showPrivacyStaticData ? (
                            <ATMGrid.Column
                                width={8}
                                floated="right"
                                textAlign="right"
                            >
                                <ATMButton
                                    className={[
                                        styles.editButton,
                                        'sempraNoPaddingRight',
                                    ].join(' ')}
                                    onClick={() => handleEditClick()}
                                >
                                    <ModeEdit sx={{ fontSize: 16 }} /> Edit
                                </ATMButton>
                            </ATMGrid.Column>
                        ) : (
                            <ATMGrid.Column
                                width={8}
                                floated="right"
                                textAlign="right"
                                onClick={handleToggle} 
                            >
                                <ExpandLess/>
                            </ATMGrid.Column>
                        )
                    ) : (
                        <ATMGrid.Column
                            width={8}
                            floated="right"
                            textAlign="right"
                            onClick={handleToggle} 
                        >
                            <ExpandMore/>
                        </ATMGrid.Column>
                    )}
                </ATMGrid.Row>
            </ATMGrid>
            {SecurityToggle && (
                <>
                    {showPrivacyStaticData ? (
                        <ATMGrid className={styles.gridStyle}>
                            <ATMDivider className={styles.divider} />
                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={
                                            Lang.LBL_SECURELY_TRANSMIT_DATA
                                        }
                                        subheader={
                                            submitRequestForm.SecureDataTransmitPlan
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={Lang.LBL_SECURELY_STORED}
                                        subheader={
                                            submitRequestForm.SecureDataStore
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={
                                            Lang.LBL_DATA_SECURE_DURING_USE
                                        }
                                        subheader={
                                            submitRequestForm.SecureDataUsageProcess
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={
                                            Lang.LBL_ACCESS_TO_DATA_CONTROLLED
                                        }
                                        subheader={
                                            submitRequestForm.DataAccessControlProcess
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={Lang.LBL_DISPOSE_DATA}
                                        subheader={
                                            !!submitRequestForm?.DataDisposalDate &&
                                            moment(
                                                submitRequestForm.DataDisposalDate
                                            ).format('MM/DD/YYYY')
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={Lang.LBL_SECURELY_DISPOSE_DATA}
                                        subheader={
                                            submitRequestForm.DataDisposalProcess
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                        </ATMGrid>
                    ) : (
                        <ATMGrid className={styles.gridStyle}>
                            <ATMDivider className={styles.divider} />
                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMField
                                        name="SecureDataTransmitPlan"
                                        placeholder={
                                            Lang.PLC_PLEASE_ENTER_TEXT_HERE
                                        }
                                        as={ATMTextArea}
                                        label={Lang.LBL_SECURELY_TRANSMIT_DATA}
                                        key={JSON.stringify(
                                            Lang.LBL_DATA_REQUEST
                                        )}
                                        onBlur={(data) =>
                                            handleOnBlur({
                                                name: 'SecureDataTransmitPlan',
                                                value: data?.target['value'],
                                            })
                                        }
                                        fluid
                                        required
                                        data-testid="SecureDataTransmitPlan"
                                        className={styles.fieldStyle}
                                        value={
                                            submitRequestForm.SecureDataTransmitPlan
                                        }
                                        restrictAngularBrackets
                                        maxLength={500}
                                        minLength={10}
                                        charLimit={500}
                                        charCount
                                        error={
                                            submitRequestErrors?.SecureDataTransmitPlan
                                        }
                                        noSpecialCharacters={true}
                                        autoComplete="off"
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMField
                                        name="SecureDataStore"
                                        placeholder={
                                            Lang.PLC_PLEASE_ENTER_TEXT_HERE
                                        }
                                        as={ATMTextArea}
                                        label={Lang.LBL_SECURELY_STORED}
                                        key={JSON.stringify(
                                            Lang.LBL_DATA_REQUEST
                                        )}
                                        onBlur={(data) =>
                                            handleOnBlur({
                                                name: 'SecureDataStore',
                                                value: data?.target['value'],
                                            })
                                        }
                                        fluid
                                        required
                                        data-testid="SecureDataStore"
                                        className={styles.fieldStyle}
                                        value={
                                            submitRequestForm.SecureDataStore
                                        }
                                        restrictAngularBrackets
                                        maxLength={500}
                                        minLength={10}
                                        charLimit={500}
                                        charCount
                                        error={
                                            submitRequestErrors?.SecureDataStore
                                        }
                                        noSpecialCharacters={true}
                                        autoComplete="off"
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMField
                                        name="SecureDataUsageProcess"
                                        placeholder={
                                            Lang.PLC_PLEASE_ENTER_TEXT_HERE
                                        }
                                        as={ATMTextArea}
                                        label={Lang.LBL_DATA_SECURE_DURING_USE}
                                        key={JSON.stringify(
                                            Lang.LBL_DATA_REQUEST
                                        )}
                                        onBlur={(data) =>
                                            handleOnBlur({
                                                name: 'SecureDataUsageProcess',
                                                value: data?.target['value'],
                                            })
                                        }
                                        fluid
                                        required
                                        data-testid="SecureDataUsageProcess"
                                        className={styles.fieldStyle}
                                        value={
                                            submitRequestForm.SecureDataUsageProcess
                                        }
                                        restrictAngularBrackets
                                        maxLength={500}
                                        minLength={10}
                                        charLimit={500}
                                        charCount
                                        error={
                                            submitRequestErrors?.SecureDataUsageProcess
                                        }
                                        noSpecialCharacters={true}
                                        autoComplete="off"
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMField
                                        name="DataAccessControlProcess"
                                        placeholder={
                                            Lang.PLC_PLEASE_ENTER_TEXT_HERE
                                        }
                                        as={ATMTextArea}
                                        label={
                                            Lang.LBL_ACCESS_TO_DATA_CONTROLLED
                                        }
                                        key={JSON.stringify(
                                            Lang.LBL_DATA_REQUEST
                                        )}
                                        onBlur={(data) =>
                                            handleOnBlur({
                                                name: 'DataAccessControlProcess',
                                                value: data?.target['value'],
                                            })
                                        }
                                        fluid
                                        required
                                        data-testid="DataAccessControlProcess"
                                        className={styles.fieldStyle}
                                        value={
                                            submitRequestForm.DataAccessControlProcess
                                        }
                                        restrictAngularBrackets
                                        maxLength={500}
                                        minLength={10}
                                        charLimit={500}
                                        charCount
                                        error={
                                            submitRequestErrors?.DataAccessControlProcess
                                        }
                                        noSpecialCharacters={true}
                                        autoComplete="off"
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column computer={8} tablet={8} mobile={16} className='sempraMediumMarginBottom'>
                                    <ATMField
                                        name="DataDisposalDate"
                                        as={datePicker}
                                        label={Lang.LBL_DISPOSE_DATA}
                                        placeholder={Lang.PLC_SELECT_DATE}
                                        control={control}
                                        onChange={handleDataChange}
                                        required
                                        fluid
                                        data-testid="DataDisposalDate"
                                        className={styles.fieldStyle}
                                        error={
                                            submitRequestErrors?.DataDisposalDate
                                        }
                                        autoComplete="off"
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMField
                                        name="DataDisposalProcess"
                                        placeholder={
                                            Lang.PLC_PLEASE_ENTER_TEXT_HERE
                                        }
                                        as={ATMTextArea}
                                        label={Lang.LBL_SECURELY_DISPOSE_DATA}
                                        key={JSON.stringify(
                                            Lang.LBL_DATA_REQUEST
                                        )}
                                        onBlur={(data) =>
                                            handleOnBlur({
                                                name: 'DataDisposalProcess',
                                                value: data?.target['value'],
                                            })
                                        }
                                        fluid
                                        required
                                        data-testid="DataDisposalProcess"
                                        className={styles.fieldStyle}
                                        value={
                                            submitRequestForm.DataDisposalProcess
                                        }
                                        restrictAngularBrackets
                                        maxLength={500}
                                        minLength={10}
                                        charLimit={500}
                                        charCount
                                        error={
                                            submitRequestErrors?.DataDisposalProcess
                                        }
                                        noSpecialCharacters={true}
                                        autoComplete="off"
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                        </ATMGrid>
                    )}
                </>
            )}
        </ATMSegment>
    );
};

export default PrivacyAndSecurity;
