export default {
    TTL_APPLICATION: 'LCFS Fuel Card Incentive Program',
    TTL_GET_STARTED: 'Get Started Now',
    TTL_FORM_ERROR: 'There was some errors with your submission',
    TTL_VERIFY_EMAIL: 'Verify your Email Address!',
    TTL_CANCEL: 'Cancel',
    TTL_WELCOME: 'Welcome!',
    TTL_FORGOT_PASSWORD: 'Forgot Password?',
    TTL_FP_SUB_TITLE: "No worries! We'll send you the reset instructions.",
    TTL_SET_NEW_PASS: 'Set new Password',
    TTL_RP_SUB_TITLE: 'Password criteria are mentioned below:',
};
