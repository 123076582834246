export enum TableAction {
    CLEAR = 'clear',
    SORT = 'sort',
    PAGE = 'page',
    FILTER = 'filter',
    TOOLBAR = 'toolbar',
    LIMIT = 'limit',
}

export const additionalInfoTable = {
    header1: 'Category',
    header2: ' Category Description',
    header3: 'Common Data Elements',

    content1_1: 'Customer',
    content1_2: `A person or organization name is listed
      as customer of record in the back end
      system.`,
    content1_3: 'Zip Code',
    content1_4: 'Zip Code + 4',

    content2_1: 'Billing History',
    content2_2: `Information related to customer billing
      such as information that is seen on the
      bill or used for the production of a
      bill.`,
    content2_3: 'Rate schedule',
    content2_4: 'Billing Start Date',
    content2_5: 'Billing End Date',

    content3_1: 'Usage Data',
    content3_2: `Information that is related to the meter
        device or information registered in the
        meter.`,
    content3_3: 'Meter ID',
    content3_4: 'Meter Type',
    content3_5: 'kWh Usage',

    content4_1: 'Program Participation',
    content4_2: `Information related to energy saving
        plans, rate programs, etc. that the
        customer is participating in.`,
    content4_3: 'Effective Date',
    content4_4: 'Program Name',
};
