import axios, { AxiosInstance, AxiosResponse } from 'axios';

export class BaseService {
  public readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({ baseURL });
    this.initRequestInterceptor();
    this.initResponseInterceptor();
  }

  private initRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this.handleRequest,
      this.handleError
    );
  };

  private handleRequest = async (config) => {
    return config;
  };

  private initResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
  };

  private handleResponse = (data: AxiosResponse) => {
    return data;
  };

  protected handleError = async (error: any) => {
    if (error.response) {
      const { status, data, config } = error.response;
      console.log(`Error: status: ${status}, data: ${data}, config:${config}`);
    } else {
      console.log(error);
    }
    return Promise.reject(error);
  };
}

export const getServiceInstance = () => {
  const service = new BaseService('');
  return service.instance;
};
