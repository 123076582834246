import { getServiceInstance } from './base.service';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const googleRecaptcha = async (requestBody) => {
    try {
        const response = await getServiceInstance().post(
            `${BASE_URL}/verify-recaptcha`, requestBody
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};
