import React, { useEffect, useState } from 'react';
import { ATMGrid } from 'src/components/common/atoms/ATMGrid/ATMGrid.component';
import { ATMButton } from 'src/components/common/atoms/ATMButton/ATMButton.component';
import { ATMModal } from 'src/components/common/atoms/ATMModal/ATMModal.component';
import Lang from 'src/libraries/language';
import { ATMHeader } from 'src/components/common/atoms/ATMHeader/ATMHeader.component';
import { ATMField } from 'src/components/common/atoms/ATMField/ATMField.component';
import { ATMInput } from 'src/components/common/atoms/ATMInput/ATMInput.component';
import { WarningRounded } from '@mui/icons-material';
import styles from '../SubmitRequest/SubmitRequest.module.scss';
import { Dimmer, FormField, Icon } from 'semantic-ui-react';
import { ATMLoader } from 'src/components/common/atoms/ATMLoader/ATMLoader.component';
import useResendEmailVerification from 'src/hooks/useResendEmailVerification';
import { isBlacklistedDomain, isValidEmail } from 'src/utils/common.utils';
import { ATMMessage } from 'src/components/common/atoms/ATMMessage/ATMMessage.component';

interface EmailVerificationProps {
    resendLink: boolean;
    setResendLink: (value: boolean) => void;
}

const EmailVerification: React.FC<EmailVerificationProps> = ({
    resendLink,
    setResendLink,
}) => {
    const [emailAddress, setEmailAddress] = useState('' as any);
    const [emailError, setEmailError] = useState('' as any);
    const [error, setError] = useState('' as any);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [responseMessage, setResponseMessage] = useState<any>('');

    const {
        mutate: resendEmail,
        data: emailVerificationData,
        isError: emailVerificationError,
    } = useResendEmailVerification();
    useEffect(() => {
        if (emailVerificationError) {
           
            setSubmitLoader(false);
            setError(Lang.MSG_API_500);
        }
    }, [emailVerificationError]);

    const handleEmailChange = (value) => {
        if (!isValidEmail(value) || isBlacklistedDomain(value)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
        setEmailAddress(value);
        return value;
    };

    useEffect(() => {
        if (emailVerificationData && emailVerificationData?.data) {
            if (emailVerificationData?.data?.body) {
                const responseMsg = JSON.parse(
                    emailVerificationData?.data?.body
                );
                setResponseMessage(responseMsg?.data);
                setSubmitLoader(false);
                setShowResult(true);
            }
        }
    }, [emailVerificationData]);

    const handleSubmitEmail = () => {
        setResendLink(true);

        try {
            setSubmitLoader(true);
            resendEmail(emailAddress);
            setSubmitLoader(false);
        } catch (error) {
            setSubmitLoader(false);
            setError(Lang.MSG_API_500);
        }
        setSubmitLoader(false);
    };

    return (
        <ATMModal
            onClose={() => setResendLink(false)}
            closeOnDimmerClick={false}
            open={resendLink}
            size="tiny"
        >
            <ATMModal.Header>
                <ATMGrid>
                    <ATMGrid.Row columns="equal" verticalAlign="middle">
                        <ATMGrid.Column textAlign="left" width={12}>
                            <ATMHeader className={styles.modalHeader}>
                                {Lang.LBL_RESEND_VERIFICATION_LINK}
                            </ATMHeader>
                        </ATMGrid.Column>
                        <ATMGrid.Column textAlign="right" width={4}>
                            <Icon
                                circular
                                name="close"
                                className={styles.crossIcon}
                                onClick={() => setResendLink(false)}
                                data-testid="closeIcon"
                            />
                        </ATMGrid.Column>
                    </ATMGrid.Row>
                </ATMGrid>
            </ATMModal.Header>
            {submitLoader ? (
                <ATMModal.Content>
                    <Dimmer active={submitLoader}>
                        <ATMLoader />
                    </Dimmer>
                </ATMModal.Content>
            ) : null}

            {showResult ? (
                <ATMModal.Content>
                    <ATMGrid>
                        <ATMGrid.Row columns="equal" verticalAlign="middle">
                            <ATMGrid.Column textAlign="left">
                                <ATMHeader className={styles.modalContent}>
                                    {!!error ? (
                                        <ATMMessage
                                            className="sempraTinyPaddingAround sempraNoMarginTop"
                                            error
                                            content={error}
                                        />
                                    ) : (
                                        responseMessage
                                    )}
                                </ATMHeader>
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                        <ATMGrid.Row columns="equal" verticalAlign="middle">
                            <ATMGrid.Column textAlign="left">
                                <ATMHeader className={styles.modalContent}>
                                    If you have further questions, please send a
                                    follow up email to{' '}
                                    <a href="mailto:energydatarequest@socalgas.com">
                                        energydatarequest@socalgas.com
                                    </a>{' '}
                                    with your request confirmation id in subject
                                    line and we will be happy to assist you.
                                </ATMHeader>
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>
                </ATMModal.Content>
            ) : (
                <ATMModal.Content data-testid="resendEmailVerificationModal">
                    <ATMGrid>
                        <ATMGrid.Row>
                            <ATMGrid.Column computer={12} mobile={8}>
                                <ATMField
                                    size="tiny"
                                    name="resendEmailAddress"
                                    data-testid="resendEmailAddress"
                                    as={ATMInput}
                                    defaultValue={emailAddress}
                                    restrictNegativeSign
                                    label={Lang.LBL_REENTER_EMAIL_ADDRESS}
                                    required
                                    onChange={(event) =>
                                        handleEmailChange(event.target.value)
                                    }
                                    className={styles['emailVerification']}
                                />
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                        <ATMGrid.Row>
                            <ATMGrid.Column computer={12} mobile={8}>
                                {emailError != '' && (
                                    <FormField className="sempraMediumPaddingBottom">
                                        <p className={styles.errorText}>
                                            <WarningRounded />
                                            {emailError}
                                        </p>
                                    </FormField>
                                )}
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>
                </ATMModal.Content>
            )}

            {showResult ? (
                <></>
            ) : (
                <ATMModal.Actions>
                    <ATMGrid>
                        <ATMGrid.Row>
                            <ATMGrid.Column textAlign="right" computer={16}>
                                <ATMButton
                                    onClick={() => handleSubmitEmail()}
                                    size="tiny"
                                    disabled={emailError || emailAddress == ''}
                                    className={
                                        emailError
                                            ? styles.submitErrorsButton
                                            : styles.submitSuccessButton
                                    }
                                >
                                    {Lang.LBL_SUBMIT}
                                </ATMButton>
                            </ATMGrid.Column>
                        </ATMGrid.Row>
                    </ATMGrid>
                </ATMModal.Actions>
            )}
        </ATMModal>
    );
};

export default EmailVerification;
