import React, { useEffect, useState } from 'react';
import { ATMHeader } from 'src/components/common/atoms/ATMHeader/ATMHeader.component';
import { ATMSegment } from 'src/components/common/atoms/ATMSegment/ATMSegment.component';
import styles from './SubmitRequest.module.scss';
import './SubmitRequest.module.scss';
import {
    Description,
    ExpandLess,
    ExpandMore,
    ModeEdit,
    WarningRounded,
} from '@mui/icons-material';
import Lang from 'src/libraries/language';
import { ATMGrid } from 'src/components/common/atoms/ATMGrid/ATMGrid.component';
import { ATMDivider } from 'src/components/common/atoms/ATMDivider/ATMDivider.component';
import { ATMField } from 'src/components/common/atoms/ATMField/ATMField.component';
import { ATMRadio } from 'src/components/common/atoms/ATMRadio/ATMRadio.component';
import { ATMInput } from 'src/components/common/atoms/ATMInput/ATMInput.component';
import {
    FormField,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react';
import { ATMTextArea } from 'src/components/common/atoms/ATMTextArea/ATMTextArea.component';
import { CustomCheckbox } from 'src/components/common/CustomCheckBox/CustomCheckBox.component';
import { ATMDatePicker } from 'src/components/common/atoms/ATMDatePicker/ATMDatePicker.component';
import { ATMButton } from 'src/components/common/atoms/ATMButton/ATMButton.component';
import moment from 'moment';
import {
    geographicalAreaOptions,
    radioToggleOptions,
    utilityFieldsOptions,
    customerSegmentInfoOptions,
} from 'src/constants/home';
import CustomModal from 'src/components/common/CustomModal/CustomModal.component';
import {
    californiaUtility,
    customerSegment,
    geographicalArea,
    validateForm,
} from 'src/utils/common.utils';
import {
    useSubmitRequestDispatch,
    useSubmitRequestState,
} from 'src/contexts/submitRequest.context';
import {
    useSubmitRequestErrorsDispatch,
    useSubmitRequestErrorsState,
} from 'src/contexts/submitRequestErrors.context';
import { additionalInfoTable } from 'src/constants/table.constant';

interface DataRequestProps {
    setDataRequestToggle: (value: boolean) => void;
    DataRequestToggle: boolean;
    handleDataChange: ([_, data]: [any, any]) => any;
    handleRadioButtonChange: (_: any, data: any) => any;
    control: any;
    reviewAndSubmitButton: boolean;
    setReviewAndSubmitButton: (value: boolean) => void;
}

const DataRequest: React.FC<DataRequestProps> = ({
    setDataRequestToggle,
    DataRequestToggle,
    handleDataChange,
    control,
    reviewAndSubmitButton,
    handleRadioButtonChange,
    setReviewAndSubmitButton,
}) => {
    const [showStaticDataRequest, setshowStaticDataRequest] = useState(false);
    const { submitRequestForm } = useSubmitRequestState();
    const dispatchForm = useSubmitRequestDispatch();
    const { submitRequestErrors } = useSubmitRequestErrorsState();
    const dispatchErrors = useSubmitRequestErrorsDispatch();
    const [moreInfo, setMoreInfo] = useState(false);

    useEffect(() => {
        if (reviewAndSubmitButton) {
            setshowStaticDataRequest(true);
        } else {
            setshowStaticDataRequest(false);
        }
    }, [reviewAndSubmitButton]);

    const endDatePicker = (props) => {
        return (
            <ATMDatePicker
                format="MM/DD/YYYY"
                pointing="top left"
                allowOnlyNumbers={false}
                value={props.value !== '' ? new Date(props.value) : undefined}
                defaultValue={
                    props.value !== '' ? new Date(props.value) : undefined
                }
                onChange={props.onChange}
                {...props}
            />
        );
    };

    const startDatePicker = (props) => {
        return (
            <ATMDatePicker
                format="MM/DD/YYYY"
                pointing="top left"
                allowOnlyNumbers={false}
                value={props.value !== '' ? new Date(props.value) : undefined}
                defaultValue={
                    props.value !== '' ? new Date(props.value) : undefined
                }
                onChange={props.onChange}
                filterDate={(date) => {
                    const threeYearsAgo = new Date();
                    threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);
                    return date >= threeYearsAgo;
                }}
                {...props}
            />
        );
    };

    const handleEditClick = () => {
        setshowStaticDataRequest(false);
        setReviewAndSubmitButton(false);
    };

    // handleOnBlur is for conditional ATMTextArea fields
    const handleOnBlur = (data) => {
        const fieldName = data.name;
        const fieldValue = data.value;

        submitRequestForm[fieldName] = fieldValue;
        const { errors, formData } = validateForm(
            fieldName,
            submitRequestForm,
            submitRequestErrors
        );

        dispatchForm({
            type: 'UPDATE_SUBMIT_REQUEST',
            payload: formData,
        });

        dispatchErrors({
            type: 'UPDATE_SUBMIT_REQUEST_ERRORS',
            payload: errors,
        });

        return fieldValue;
    };

    const handleToggle = () => {
        setDataRequestToggle(!DataRequestToggle);
    };

    return (
        <ATMSegment size="tiny" className={styles.commonMargin}>
            <ATMGrid>
                <ATMGrid.Row>
                    <ATMGrid.Column
                        width={8}
                        className={styles.segmentsStyle}
                        onClick={handleToggle}
                    >
                        <ATMHeader as="h2" className={styles.headerStyle}>
                            <Description />
                            {Lang.LBL_DATA_REQUEST}
                        </ATMHeader>
                    </ATMGrid.Column>

                    {!!DataRequestToggle ? (
                        showStaticDataRequest ? (
                            <ATMGrid.Column
                                width={8}
                                floated="right"
                                textAlign="right"
                            >
                                <ATMButton
                                    className={[
                                        styles.editButton,
                                        'sempraNoPaddingRight',
                                    ].join(' ')}
                                    onClick={() => handleEditClick()}
                                >
                                    <ModeEdit sx={{ fontSize: 16 }} /> Edit
                                </ATMButton>
                            </ATMGrid.Column>
                        ) : (
                            <ATMGrid.Column
                                width={8}
                                floated="right"
                                textAlign="right"
                                onClick={handleToggle}
                            >
                                <ExpandLess />
                            </ATMGrid.Column>
                        )
                    ) : (
                        <ATMGrid.Column
                            width={8}
                            floated="right"
                            textAlign="right"
                            onClick={handleToggle}
                        >
                            <ExpandMore />
                        </ATMGrid.Column>
                    )}
                </ATMGrid.Row>
            </ATMGrid>

            {DataRequestToggle && (
                <>
                    {showStaticDataRequest ? (
                        <ATMGrid className={styles.gridStyle}>
                            <ATMDivider className={styles.divider} />
                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={Lang.LBL_WHAT_IS_YOUR_DATA_REQ}
                                        subheader={
                                            submitRequestForm.RequestDescription
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row className="sempraNoPaddingBottom">
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={
                                            Lang.LBL_WHAT_DATA_ARE_REQUESTED
                                        }
                                        subheader={
                                            submitRequestForm.RequestElements
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={
                                            Lang.LBL_WHAT_GEOGRAPHICAL_AREA_IS_REQUESTED
                                        }
                                        subheader={geographicalArea(
                                            submitRequestForm
                                        )}
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column
                                    computer={8}
                                    tablet={8}
                                    mobile={16}
                                    className="sempraMediumMarginBottom"
                                >
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={Lang.LBL_WHAT_IS_START_DATE}
                                        subheader={
                                            !!submitRequestForm.StartDate &&
                                            moment(
                                                submitRequestForm.StartDate
                                            ).format('MM/DD/YYYY')
                                        }
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column
                                    computer={8}
                                    tablet={8}
                                    mobile={16}
                                    className="sempraMediumMarginBottom"
                                >
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={Lang.LBL_WHAT_IS_END_DATE}
                                        subheader={
                                            !!submitRequestForm.EndDate &&
                                            moment(
                                                submitRequestForm.EndDate
                                            ).format('MM/DD/YYYY')
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={
                                            Lang.LBL_WHO_IS_AUDIENCE_OF_THIS_DATA
                                        }
                                        subheader={
                                            submitRequestForm.DataAudience
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={Lang.LBL_CUSTOMER_SEGMENT}
                                        subheader={
                                            customerSegment(submitRequestForm)
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={
                                            Lang.LBL_WILL_DATA_BE_SHARED_WITH_THIRD_PARTY
                                        }
                                        subheader={
                                            Object.keys(submitRequestForm)
                                                ?.length !== 0 &&
                                            submitRequestForm?.IsThirdPartyAccess ==
                                                'Yes'
                                                ? 'Yes'
                                                : 'No'
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={Lang.LBL_IS_THIS_RECURRING_REQ}
                                        subheader={
                                            Object.keys(submitRequestForm)
                                                ?.length !== 0 &&
                                            submitRequestForm?.RecurringRequest ==
                                                'Yes'
                                                ? 'Yes'
                                                : 'No'
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            {submitRequestForm?.RecurringRequest == 'Yes' && (
                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={
                                                Lang.LBL_PLEASE_SELECT_FREQUENCY_OF_RECURRING_REQ
                                            }
                                            subheader={
                                                submitRequestForm.RecurringFrequency
                                            }
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            )}

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles.atmHeader}
                                        as="h3"
                                        content={
                                            Lang.LBL_REQ_DATA_FROM_OTHER_CALIFORNIA_UTILITIES
                                        }
                                        subheader={
                                            submitRequestForm.IsFromCaliforniaUtility ==
                                            'Yes'
                                                ? 'Yes'
                                                : 'No'
                                        }
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            {submitRequestForm.IsFromCaliforniaUtility ==
                                'Yes' && (
                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMHeader
                                            className={styles.atmHeader}
                                            as="h3"
                                            content={
                                                Lang.LBL_WHICH_OTHER_UTILITIES
                                            }
                                            subheader={californiaUtility(
                                                submitRequestForm
                                            )}
                                        />
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            )}

                            {submitRequestForm?.OrganizationType ===
                                'Academic Researcher' && (
                                <>
                                    <ATMGrid.Row className="sempraNoPaddingBottom">
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles.atmHeader}
                                                as="h3"
                                                content={
                                                    Lang.LBL_CALIFORNIA_ENERGY_USE
                                                }
                                                subheader={
                                                    submitRequestForm.HowProjectAdvancesEnergy
                                                }
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles.atmHeader}
                                                as="h3"
                                                content={
                                                    Lang.LBL_CERTIFIED_FOR_CALIFORNIA_CIVIL_CODE
                                                }
                                                subheader={
                                                    submitRequestForm.HasProjectCertified ==
                                                    'Yes'
                                                        ? 'Yes'
                                                        : 'No'
                                                }
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles.atmHeader}
                                                as="h3"
                                                content={
                                                    Lang.LBL_CERTIFIED_FOR_HUMAN_SUBJECTS
                                                }
                                                subheader={
                                                    submitRequestForm.HasCertifiedByReviewBoard ==
                                                    'Yes'
                                                        ? 'Yes'
                                                        : 'No'
                                                }
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles.atmHeader}
                                                as="h3"
                                                content={
                                                    Lang.LBL_AGREE_TO_SIGN_AN_NDA
                                                }
                                                subheader={
                                                    submitRequestForm.AgreeNDASign ==
                                                    'Yes'
                                                        ? 'Yes'
                                                        : 'No'
                                                }
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>
                                </>
                            )}

                            {/*If the Requesting Entity = State/Federal Agencies.*/}
                            {submitRequestForm?.OrganizationType ==
                                'State/Federal Agency' && (
                                <>
                                    <ATMGrid.Row className="sempraMediumPaddingBottom">
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles.atmHeader}
                                                as="h3"
                                                content={
                                                    Lang.LBL_NEEDED_TO_COMPLY_STATUTORY_OBLIGATION
                                                }
                                                subheader={
                                                    submitRequestForm.CompyStatutoryObligation ==
                                                    'Yes'
                                                        ? 'Yes'
                                                        : 'No'
                                                }
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    {submitRequestForm.CompyStatutoryObligation ==
                                        'Yes' && (
                                        <ATMGrid.Row className="sempraMediumPaddingBottom">
                                            <ATMGrid.Column width={16}>
                                                <ATMHeader
                                                    className={styles.atmHeader}
                                                    as="h3"
                                                    content={
                                                        Lang.LBL_DESC_HOW_DATA_WILL_COMPLY_STATUTE
                                                    }
                                                    subheader={
                                                        submitRequestForm.DescHowDataComply
                                                    }
                                                />
                                            </ATMGrid.Column>
                                        </ATMGrid.Row>
                                    )}
                                </>
                            )}

                            {/* If entity type is CA Dept. of Community Services & Development (CSD) */}
                            {submitRequestForm?.OrganizationType ==
                                'CA Department of Community Services & Development (CSD)' && (
                                <>
                                    <ATMGrid.Row>
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles.atmHeader}
                                                as="h3"
                                                content={
                                                    Lang.LBL_NEEDED_TO_COMPLY_STATUTORY_OBLIGATION_FOR_LOW_INCOME_ENERGY_ASSISTANCE
                                                }
                                                subheader={
                                                    submitRequestForm.CSDComplyStatutoryObligation ==
                                                    'Yes'
                                                        ? 'Yes'
                                                        : 'No'
                                                }
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    {submitRequestForm.CSDComplyStatutoryObligation ==
                                        'Yes' && (
                                        <ATMGrid.Row className="sempraMediumPaddingBottom">
                                            <ATMGrid.Column width={16}>
                                                <ATMHeader
                                                    className={styles.atmHeader}
                                                    as="h3"
                                                    content={
                                                        Lang.LBL_DESC_HOW_DATA_WILL_COMPLY_STATUTE
                                                    }
                                                    subheader={
                                                        submitRequestForm.CSDDescHowDataComply
                                                    }
                                                />
                                            </ATMGrid.Column>
                                        </ATMGrid.Row>
                                    )}
                                </>
                            )}
                        </ATMGrid>
                    ) : (
                        <ATMGrid className={styles.gridStyle}>
                            <ATMDivider className={styles.divider} />
                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMField
                                        name="RequestDescription"
                                        placeholder={
                                            Lang.PLC_PLEASE_ENTER_TEXT_HERE
                                        }
                                        as={ATMTextArea}
                                        label={Lang.LBL_WHAT_IS_YOUR_DATA_REQ}
                                        key={JSON.stringify(
                                            Lang.LBL_DATA_REQUEST
                                        )}
                                        onBlur={(data) =>
                                            handleOnBlur({
                                                name: 'RequestDescription',
                                                value: data?.target['value'],
                                            })
                                        }
                                        fluid
                                        required
                                        data-testid="RequestDescription"
                                        className={styles.fieldStyle}
                                        value={
                                            submitRequestForm.RequestDescription
                                        }
                                        restrictAngularBrackets
                                        maxLength={1000}
                                        minLength={10}
                                        charLimit={1000}
                                        charCount
                                        error={
                                            submitRequestErrors.RequestDescription
                                        }
                                        noSpecialCharacters={true}
                                        autoComplete="off"
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row className="sempraNoPaddingBottom">
                                <ATMGrid.Column width={16}>
                                    <ATMField
                                        name="RequestElements"
                                        placeholder={
                                            Lang.PLC_PLEASE_ENTER_TEXT_HERE
                                        }
                                        as={ATMTextArea}
                                        label={Lang.LBL_WHAT_DATA_ARE_REQUESTED}
                                        key={JSON.stringify(
                                            Lang.LBL_DATA_REQUEST
                                        )}
                                        onBlur={(data) =>
                                            handleOnBlur({
                                                name: 'RequestElements',
                                                value: data?.target['value'],
                                            })
                                        }
                                        fluid
                                        required
                                        data-testid="RequestElements"
                                        className={styles.fieldStyle}
                                        value={
                                            submitRequestForm.RequestElements
                                        }
                                        restrictAngularBrackets
                                        maxLength={500}
                                        minLength={10}
                                        charLimit={500}
                                        charCount
                                        error={
                                            submitRequestErrors.RequestElements
                                        }
                                        noSpecialCharacters={true}
                                        autoComplete="off"
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <span className={styles.indicatorMsg}>
                                <p>
                                    {' '}
                                    Examples include usage data, billing
                                    history, and email addresses.
                                </p>{' '}
                                &nbsp; &nbsp;
                                <a
                                    className="sempraSecondaryLink"
                                    onClick={() => {
                                        setMoreInfo(!moreInfo);
                                    }}
                                >
                                    Click here to Learn more
                                </a>
                            </span>

                            <ATMGrid.Row>
                                <ATMGrid.Column width={8}>
                                    <div className="sempraSmallMarginBottom">
                                        <ATMHeader
                                            className={styles['ATMHeader']}
                                            as="h3"
                                        >
                                            <p
                                                className={
                                                    styles.paragraphLabel
                                                }
                                            >
                                                {
                                                    Lang.LBL_WHAT_GEOGRAPHICAL_AREA_IS_REQUESTED
                                                }
                                            </p>
                                            <p className={styles.indicator}>
                                                &nbsp; *
                                            </p>
                                        </ATMHeader>

                                        {geographicalAreaOptions.map(
                                            (geographicalArea) => (
                                                <>
                                                    <FormField
                                                        key={JSON.stringify(
                                                            geographicalArea.name
                                                        )}
                                                    >
                                                        <ATMField
                                                            name={
                                                                geographicalArea.name
                                                            }
                                                            as={CustomCheckbox}
                                                            label={
                                                                geographicalArea.label
                                                            }
                                                            control={control}
                                                            className={
                                                                styles[
                                                                    'checkBoxField'
                                                                ]
                                                            }
                                                            defaultChecked={
                                                                submitRequestForm[
                                                                    geographicalArea
                                                                        .name
                                                                ]
                                                            }
                                                            onChange={
                                                                handleDataChange
                                                            }
                                                            autoComplete="off"
                                                        />
                                                    </FormField>
                                                    {submitRequestForm[
                                                        geographicalArea.name
                                                    ] && (
                                                        <FormField
                                                            key={JSON.stringify(
                                                                submitRequestForm[
                                                                    geographicalArea
                                                                        .name
                                                                ]
                                                            )}
                                                        >
                                                            <ATMField
                                                                name={`${geographicalArea.name}Input`}
                                                                as={ATMInput}
                                                                placeholder={
                                                                    geographicalArea.inputPlaceholder
                                                                }
                                                                maxLength={100}
                                                                restrictAngularBrackets
                                                                onBlur={(
                                                                    data
                                                                ) =>
                                                                    handleOnBlur(
                                                                        {
                                                                            name: `${geographicalArea.name}Input`,
                                                                            value: data
                                                                                ?.target[
                                                                                'value'
                                                                            ],
                                                                        }
                                                                    )
                                                                }
                                                                defaultValue={
                                                                    submitRequestForm[
                                                                        `${geographicalArea.name}Input`
                                                                    ]
                                                                }
                                                                fluid
                                                                required
                                                                data-testid={`${geographicalArea.name}Input`}
                                                                className={
                                                                    styles.fieldStyle
                                                                }
                                                                error={
                                                                    submitRequestErrors[
                                                                        `${geographicalArea.name}Input`
                                                                    ]
                                                                }
                                                                isZip={
                                                                    `${geographicalArea.name}Input` ===
                                                                    'GeographicalAreaZipCodeInput'
                                                                }
                                                                autoComplete="off"
                                                            />
                                                        </FormField>
                                                    )}
                                                </>
                                            )
                                        )}
                                    </div>
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            {submitRequestErrors?.GeographicalAreaZipCode &&
                                submitRequestErrors?.GeographicalAreaCity &&
                                submitRequestErrors?.GeographicalAreaCounty &&
                                submitRequestErrors?.GeographicalAreaOthers && (
                                    <FormField className="sempraMediumPaddingBottom">
                                        <p className={styles.errorText}>
                                            <WarningRounded />
                                            This is a required field.
                                        </p>
                                    </FormField>
                                )}

                            <ATMGrid.Row>
                                <ATMGrid.Column
                                    computer={8}
                                    tablet={8}
                                    mobile={16}
                                    className="sempraMediumMarginBottom"
                                >
                                    <ATMField
                                        name="StartDate"
                                        as={startDatePicker}
                                        label={Lang.LBL_WHAT_IS_START_DATE}
                                        placeholder={Lang.PLC_SELECT_START_DATE}
                                        control={control}
                                        onChange={handleDataChange}
                                        required
                                        fluid
                                        data-testid="StartDate"
                                        className={styles.fieldStyle}
                                        error={submitRequestErrors.StartDate}
                                        autoComplete="off"
                                    />
                                </ATMGrid.Column>
                                <ATMGrid.Column
                                    computer={8}
                                    tablet={8}
                                    mobile={16}
                                    className="sempraMediumMarginBottom"
                                >
                                    <ATMField
                                        name="EndDate"
                                        as={endDatePicker}
                                        label={Lang.LBL_WHAT_IS_END_DATE}
                                        placeholder={Lang.PLC_SELECT_END_DATE}
                                        control={control}
                                        onChange={handleDataChange}
                                        required
                                        fluid
                                        data-testid="EndDate"
                                        className={styles.fieldStyle}
                                        error={submitRequestErrors.EndDate}
                                        autoComplete="off"
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>
                            <p className={styles.additionalText}>
                                The start date must be within the last three
                                years.
                            </p>

                            <ATMGrid.Row className="sempraNoPaddingBottom">
                                <ATMGrid.Column width={16}>
                                    <ATMField
                                        name="DataAudience"
                                        as={ATMTextArea}
                                        label={
                                            Lang.LBL_WHO_IS_AUDIENCE_OF_THIS_DATA
                                        }
                                        placeholder={
                                            Lang.PLC_PLEASE_ENTER_TEXT_HERE
                                        }
                                        key={JSON.stringify(
                                            Lang.LBL_DATA_REQUEST
                                        )}
                                        onBlur={(data) =>
                                            handleOnBlur({
                                                name: 'DataAudience',
                                                value: data?.target['value'],
                                            })
                                        }
                                        fluid
                                        required
                                        data-testid="DataAudience"
                                        className={styles.fieldStyle}
                                        value={submitRequestForm.DataAudience}
                                        restrictAngularBrackets
                                        maxLength={500}
                                        minLength={10}
                                        charLimit={500}
                                        charCount
                                        error={submitRequestErrors.DataAudience}
                                        noSpecialCharacters={true}
                                        autoComplete="off"
                                    />
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row>
                                <ATMGrid.Column
                                    computer={16}
                                    tablet={8}
                                    mobile={16}
                                    className="sempraMediumMarginBottom"
                                >
                                    <div>
                                        <ATMHeader
                                            className={styles['ATMHeader']}
                                            as="h3"
                                        >
                                            <p
                                                className={
                                                    styles.paragraphLabel
                                                }
                                            >
                                                {Lang.LBL_CUSTOMER_SEGMENT}
                                            </p>
                                            <p className={styles.indicator}>
                                                &nbsp; *
                                            </p>
                                        </ATMHeader>

                                        {customerSegmentInfoOptions.map(
                                            (option, index) => (
                                                <FormField key={index}>
                                                    <ATMField
                                                        name={`${option.name}`}
                                                        as={CustomCheckbox}
                                                        label={`${option.label}`}
                                                        control={control}
                                                        className={
                                                            styles[
                                                                'checkBoxField'
                                                            ]
                                                        }
                                                        defaultChecked={
                                                            submitRequestForm[
                                                                option.name
                                                            ]
                                                        }
                                                        onChange={
                                                            handleDataChange
                                                        }
                                                        autoComplete="off"
                                                    />
                                                </FormField>
                                            )
                                        )}

                                        {!!submitRequestErrors?.AllSectors &&
                                            !!submitRequestErrors?.Residential &&
                                            !!submitRequestErrors?.NonResidential &&
                                            !!submitRequestErrors?.Commercial &&
                                            !!submitRequestErrors?.Industrial && (
                                                <FormField className="sempraMediumPaddingBottom">
                                                    <p
                                                        className={
                                                            styles.errorText
                                                        }
                                                    >
                                                        <WarningRounded />
                                                        This is a required
                                                        field.
                                                    </p>
                                                </FormField>
                                            )}
                                    </div>
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            <ATMGrid.Row className="sempraTinyPaddingTop">
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles['ATMHeader']}
                                        as="h3"
                                    >
                                        <p className={styles.paragraphLabel}>
                                            {
                                                Lang.LBL_WILL_DATA_BE_SHARED_WITH_THIRD_PARTY
                                            }
                                        </p>
                                        <p className={styles.indicator}>
                                            &nbsp; *
                                        </p>
                                    </ATMHeader>
                                    <p>
                                        Please indicate whether or not the data
                                        will be shared with a third party
                                    </p>
                                    <div
                                        className={
                                            styles.primaryContactRadioButtons
                                        }
                                    >
                                        {radioToggleOptions?.map(
                                            (label, index) => (
                                                <ATMRadio
                                                    key={index}
                                                    label={label}
                                                    onChange={
                                                        handleRadioButtonChange
                                                    }
                                                    checked={
                                                        submitRequestForm?.IsThirdPartyAccess ===
                                                        `${label}`
                                                    }
                                                    value={`${label}`}
                                                    name={`IsThirdPartyAccess`}
                                                    autoComplete="off"
                                                />
                                            )
                                        )}
                                    </div>
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            {submitRequestErrors?.IsThirdPartyAccess && (
                                <FormField className="sempraMediumPaddingBottom">
                                    <p className={styles.errorText}>
                                        <WarningRounded />
                                        This is a required field.
                                    </p>
                                </FormField>
                            )}

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles['ATMHeader']}
                                        as="h3"
                                    >
                                        <p className={styles.paragraphLabel}>
                                            {Lang.LBL_IS_THIS_RECURRING_REQ}
                                        </p>
                                        <p className={styles.indicator}>
                                            &nbsp; *
                                        </p>
                                    </ATMHeader>
                                    <div
                                        className={
                                            styles.primaryContactRadioButtons
                                        }
                                    >
                                        {radioToggleOptions?.map(
                                            (label, index) => (
                                                <ATMRadio
                                                    key={index}
                                                    label={label}
                                                    onChange={
                                                        handleRadioButtonChange
                                                    }
                                                    checked={
                                                        submitRequestForm?.RecurringRequest ===
                                                        `${label}`
                                                    }
                                                    value={`${label}`}
                                                    name={`RecurringRequest`}
                                                    autoComplete="off"
                                                />
                                            )
                                        )}
                                    </div>
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            {submitRequestErrors?.RecurringRequest && (
                                <FormField className="sempraMediumPaddingBottom">
                                    <p className={styles.errorText}>
                                        <WarningRounded />
                                        This is a required field.
                                    </p>
                                </FormField>
                            )}

                            {submitRequestForm?.RecurringRequest == 'Yes' && (
                                <ATMGrid.Row>
                                    <ATMGrid.Column width={16}>
                                        <ATMHeader
                                            className={styles['ATMHeader']}
                                            as="h3"
                                        >
                                            <p
                                                className={
                                                    styles.paragraphLabel
                                                }
                                            >
                                                {
                                                    Lang.LBL_PLEASE_SELECT_FREQUENCY_OF_RECURRING_REQ
                                                }
                                            </p>
                                            <p className={styles.indicator}>
                                                &nbsp; *
                                            </p>
                                        </ATMHeader>
                                        <div
                                            className={
                                                styles.primaryContactRadioButtons
                                            }
                                        >
                                            {[
                                                'Monthly',
                                                'Quarterly',
                                                'Annually',
                                            ].map((label, index) => (
                                                <ATMRadio
                                                    key={index}
                                                    label={label}
                                                    onChange={
                                                        handleRadioButtonChange
                                                    }
                                                    checked={
                                                        submitRequestForm?.RecurringFrequency ===
                                                        `${label}`
                                                    }
                                                    value={`${label}`}
                                                    name={`${label}`}
                                                    autoComplete="off"
                                                />
                                            ))}
                                        </div>
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            )}

                            {submitRequestErrors?.RecurringFrequency && (
                                <FormField className="sempraMediumPaddingBottom">
                                    <p className={styles.errorText}>
                                        <WarningRounded />
                                        This is a required field.
                                    </p>
                                </FormField>
                            )}

                            <ATMGrid.Row>
                                <ATMGrid.Column width={16}>
                                    <ATMHeader
                                        className={styles['ATMHeader']}
                                        as="h3"
                                    >
                                        <p className={styles.paragraphLabel}>
                                            {
                                                Lang.LBL_REQ_DATA_FROM_OTHER_CALIFORNIA_UTILITIES
                                            }
                                        </p>
                                        <p className={styles.indicator}>
                                            &nbsp; *
                                        </p>
                                    </ATMHeader>
                                    <div
                                        className={
                                            styles.primaryContactRadioButtons
                                        }
                                    >
                                        {radioToggleOptions?.map(
                                            (label, index) => (
                                                <ATMRadio
                                                    key={index}
                                                    label={label}
                                                    onChange={
                                                        handleRadioButtonChange
                                                    }
                                                    checked={
                                                        submitRequestForm?.IsFromCaliforniaUtility ===
                                                        `${label}`
                                                    }
                                                    value={`${label}`}
                                                    name={`IsFromCaliforniaUtility`}
                                                    autoComplete="off"
                                                />
                                            )
                                        )}
                                    </div>
                                </ATMGrid.Column>
                            </ATMGrid.Row>

                            {submitRequestErrors?.IsFromCaliforniaUtility && (
                                <FormField className="sempraMediumPaddingBottom">
                                    <p className={styles.errorText}>
                                        <WarningRounded />
                                        This is a required field.
                                    </p>
                                </FormField>
                            )}

                            {submitRequestForm?.IsFromCaliforniaUtility ===
                                'Yes' && (
                                <ATMGrid.Row>
                                    <ATMGrid.Column
                                        computer={8}
                                        tablet={8}
                                        mobile={16}
                                        className="sempraMediumMarginBottom"
                                    >
                                        <div className="sempraSmallMarginBottom">
                                            <ATMHeader
                                                className={styles['ATMHeader']}
                                                as="h3"
                                            >
                                                <p
                                                    className={
                                                        styles.paragraphLabel
                                                    }
                                                >
                                                    {
                                                        Lang.LBL_WHICH_OTHER_UTILITIES
                                                    }
                                                </p>
                                                <p className={styles.indicator}>
                                                    &nbsp; *
                                                </p>
                                            </ATMHeader>

                                            {utilityFieldsOptions.map(
                                                (utilityField) => (
                                                    <FormField
                                                        key={utilityField.name}
                                                    >
                                                        <ATMField
                                                            name={
                                                                utilityField.name
                                                            }
                                                            as={CustomCheckbox}
                                                            label={
                                                                utilityField.label
                                                            }
                                                            control={control}
                                                            className={
                                                                styles[
                                                                    'checkBoxField'
                                                                ]
                                                            }
                                                            defaultChecked={
                                                                submitRequestForm[
                                                                    utilityField
                                                                        .name
                                                                ]
                                                            }
                                                            onChange={
                                                                handleDataChange
                                                            }
                                                            autoComplete="off"
                                                        />
                                                    </FormField>
                                                )
                                            )}

                                            {submitRequestForm[
                                                'IsOtherUtilities'
                                            ] && (
                                                <FormField>
                                                    <ATMField
                                                        name={`IsOtherUtilitiesInput`}
                                                        as={ATMInput}
                                                        restrictAngularBrackets
                                                        placeholder={
                                                            Lang.PLC_PLEASE_ENTER_DETAILS_HERE
                                                        }
                                                        onBlur={(data) =>
                                                            handleOnBlur({
                                                                name: `IsOtherUtilitiesInput`,
                                                                value: data
                                                                    ?.target[
                                                                    'value'
                                                                ],
                                                            })
                                                        }
                                                        defaultValue={
                                                            submitRequestForm[
                                                                `IsOtherUtilitiesInput`
                                                            ]
                                                        }
                                                        fluid
                                                        maxLength={100}
                                                        required
                                                        data-testid={`IsOtherUtilitiesInput`}
                                                        className={
                                                            styles.fieldStyle
                                                        }
                                                        error={
                                                            submitRequestErrors[
                                                                `IsOtherUtilitiesInput`
                                                            ]
                                                        }
                                                        noSpecialCharacters={
                                                            true
                                                        }
                                                        autoComplete="off"
                                                    />
                                                </FormField>
                                            )}

                                            {!!submitRequestErrors?.PacificGasAndElectric &&
                                                !!submitRequestErrors?.SouthernCaliforniaEdison &&
                                                !!submitRequestErrors?.SanDiegoGasAndElectric &&
                                                !!submitRequestErrors?.IsOtherUtilities && (
                                                    <FormField className="sempraMediumPaddingBottom">
                                                        <p
                                                            className={
                                                                styles.errorText
                                                            }
                                                        >
                                                            <WarningRounded />
                                                            This is a required
                                                            field.
                                                        </p>
                                                    </FormField>
                                                )}
                                        </div>
                                    </ATMGrid.Column>
                                </ATMGrid.Row>
                            )}

                            {submitRequestForm?.OrganizationType ===
                                'Academic Researcher' && (
                                <div
                                    key={JSON.stringify(
                                        submitRequestForm?.OrganizationType ===
                                            'Academic Researcher'
                                    )}
                                >
                                    <ATMGrid.Row className="sempraNoPaddingBottom">
                                        <ATMGrid.Column width={16}>
                                            <ATMField
                                                name="HowProjectAdvancesEnergy"
                                                key={JSON.stringify(
                                                    submitRequestForm?.IsFromCaliforniaUtility ===
                                                        'No'
                                                )}
                                                placeholder={
                                                    Lang.PLC_PLEASE_ENTER_TEXT_HERE
                                                }
                                                as={ATMTextArea}
                                                label={
                                                    Lang.LBL_CALIFORNIA_ENERGY_USE
                                                }
                                                onBlur={(data) =>
                                                    handleOnBlur({
                                                        name: 'HowProjectAdvancesEnergy',
                                                        value: data?.target[
                                                            'value'
                                                        ],
                                                    })
                                                }
                                                fluid
                                                required
                                                data-testid="HowProjectAdvancesEnergy"
                                                className={styles.fieldStyle}
                                                value={
                                                    submitRequestForm.HowProjectAdvancesEnergy
                                                }
                                                restrictAngularBrackets
                                                maxLength={500}
                                                minLength={10}
                                                charLimit={500}
                                                charCount
                                                error={
                                                    submitRequestErrors.HowProjectAdvancesEnergy
                                                }
                                                noSpecialCharacters={true}
                                                autoComplete="off"
                                            />
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>
                                    <p className="sempraTinyPaddingBottom">
                                        {Lang.MSG_CALIFORNIA_USAGE}
                                    </p>

                                    <ATMGrid.Row className="sempraMediumPaddingBottom">
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles['ATMHeader']}
                                                as="h3"
                                            >
                                                <p
                                                    className={
                                                        styles.paragraphLabel
                                                    }
                                                >
                                                    {
                                                        Lang.LBL_CERTIFIED_FOR_CALIFORNIA_CIVIL_CODE
                                                    }
                                                </p>
                                                <p className={styles.indicator}>
                                                    &nbsp; *
                                                </p>
                                            </ATMHeader>
                                            <div
                                                className={
                                                    styles.primaryContactRadioButtons
                                                }
                                            >
                                                {radioToggleOptions?.map(
                                                    (label, index) => (
                                                        <ATMRadio
                                                            key={index}
                                                            label={label}
                                                            onChange={
                                                                handleRadioButtonChange
                                                            }
                                                            checked={
                                                                submitRequestForm?.HasProjectCertified ===
                                                                `${label}`
                                                            }
                                                            value={`${label}`}
                                                            name={`HasProjectCertified`}
                                                            autoComplete="off"
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    {submitRequestErrors?.HasProjectCertified && (
                                        <FormField className="sempraMediumPaddingBottom">
                                            <p className={styles.errorText}>
                                                <WarningRounded />
                                                This is a required field.
                                            </p>
                                        </FormField>
                                    )}

                                    <ATMGrid.Row className="sempraMediumPaddingBottom">
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles['ATMHeader']}
                                                as="h3"
                                            >
                                                <p
                                                    className={
                                                        styles.paragraphLabel
                                                    }
                                                >
                                                    {
                                                        Lang.LBL_CERTIFIED_FOR_HUMAN_SUBJECTS
                                                    }
                                                </p>
                                                <p className={styles.indicator}>
                                                    &nbsp; *
                                                </p>
                                            </ATMHeader>
                                            <div
                                                className={
                                                    styles.primaryContactRadioButtons
                                                }
                                            >
                                                {radioToggleOptions?.map(
                                                    (label, index) => (
                                                        <ATMRadio
                                                            key={index}
                                                            label={label}
                                                            onChange={
                                                                handleRadioButtonChange
                                                            }
                                                            checked={
                                                                submitRequestForm?.HasCertifiedByReviewBoard ===
                                                                `${label}`
                                                            }
                                                            value={`${label}`}
                                                            name={`HasCertifiedByReviewBoard`}
                                                            autoComplete="off"
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    {submitRequestErrors?.HasCertifiedByReviewBoard && (
                                        <FormField className="sempraMediumPaddingBottom">
                                            <p className={styles.errorText}>
                                                <WarningRounded />
                                                This is a required field.
                                            </p>
                                        </FormField>
                                    )}

                                    <ATMGrid.Row className="sempraMediumPaddingBottom">
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                as="h3"
                                                className={styles['ATMHeader']}
                                            >
                                                <p
                                                    className={
                                                        styles.paragraphLabel
                                                    }
                                                >
                                                    {
                                                        Lang.LBL_AGREE_TO_SIGN_AN_NDA
                                                    }
                                                </p>
                                                <p className={styles.indicator}>
                                                    &nbsp; *
                                                </p>
                                            </ATMHeader>
                                            <div
                                                className={
                                                    styles.primaryContactRadioButtons
                                                }
                                            >
                                                {radioToggleOptions?.map(
                                                    (label, index) => (
                                                        <ATMRadio
                                                            key={index}
                                                            label={label}
                                                            onChange={
                                                                handleRadioButtonChange
                                                            }
                                                            checked={
                                                                submitRequestForm?.AgreeNDASign ===
                                                                `${label}`
                                                            }
                                                            value={`${label}`}
                                                            name={`AgreeNDASign`}
                                                            autoComplete="off"
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    {submitRequestErrors?.AgreeNDASign && (
                                        <FormField className="sempraMediumPaddingBottom">
                                            <p className={styles.errorText}>
                                                <WarningRounded />
                                                This is a required field.
                                            </p>
                                        </FormField>
                                    )}
                                </div>
                            )}

                            {/*If the Requesting Entity = State/Federal Agencies.*/}

                            {submitRequestForm?.OrganizationType ==
                                'State/Federal Agency' && (
                                <>
                                    <ATMGrid.Row className="sempraMediumPaddingBottom">
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles['ATMHeader']}
                                                as="h3"
                                            >
                                                <p
                                                    className={
                                                        styles.paragraphLabel
                                                    }
                                                >
                                                    {
                                                        Lang.LBL_NEEDED_TO_COMPLY_STATUTORY_OBLIGATION
                                                    }
                                                </p>
                                                <p className={styles.indicator}>
                                                    &nbsp; *
                                                </p>
                                            </ATMHeader>
                                            <div
                                                className={
                                                    styles.primaryContactRadioButtons
                                                }
                                            >
                                                {radioToggleOptions?.map(
                                                    (label, index) => (
                                                        <ATMRadio
                                                            key={index}
                                                            label={label}
                                                            onChange={
                                                                handleRadioButtonChange
                                                            }
                                                            checked={
                                                                submitRequestForm?.CompyStatutoryObligation ===
                                                                `${label}`
                                                            }
                                                            value={`${label}`}
                                                            name={`CompyStatutoryObligation`}
                                                            autoComplete="off"
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    {submitRequestErrors?.CompyStatutoryObligation && (
                                        <FormField className="sempraMediumPaddingBottom">
                                            <p className={styles.errorText}>
                                                <WarningRounded />
                                                This is a required field.
                                            </p>
                                        </FormField>
                                    )}

                                    {submitRequestForm?.CompyStatutoryObligation ==
                                        'Yes' && (
                                        <ATMGrid.Row className="sempraMediumPaddingTop sempraMediumPaddingBottom">
                                            <ATMGrid.Column width={16}>
                                                <ATMField
                                                    name="DescHowDataComply"
                                                    key={JSON.stringify(
                                                        submitRequestForm?.OrganizationType
                                                    )}
                                                    placeholder={
                                                        Lang.PLC_PLEASE_ENTER_TEXT_HERE
                                                    }
                                                    as={ATMTextArea}
                                                    label={
                                                        Lang.LBL_DESC_HOW_DATA_WILL_COMPLY_STATUTE
                                                    }
                                                    fluid
                                                    required
                                                    data-testid="DescHowDataComply"
                                                    className={
                                                        styles.fieldStyle
                                                    }
                                                    value={
                                                        submitRequestForm?.DescHowDataComply
                                                    }
                                                    restrictAngularBrackets
                                                    maxLength={1000}
                                                    minLength={10}
                                                    charLimit={1000}
                                                    charCount
                                                    error={
                                                        submitRequestErrors.DescHowDataComply
                                                    }
                                                    onBlur={(data) =>
                                                        handleOnBlur({
                                                            name: 'DescHowDataComply',
                                                            value: data?.target[
                                                                'value'
                                                            ],
                                                        })
                                                    }
                                                    noSpecialCharacters={true}
                                                    autoComplete="off"
                                                />
                                            </ATMGrid.Column>
                                        </ATMGrid.Row>
                                    )}
                                </>
                            )}

                            {/* If entity type is CA Dept. of Community Services & Development (CSD) */}

                            {submitRequestForm?.OrganizationType ==
                                'CA Department of Community Services & Development (CSD)' && (
                                <div
                                    key={JSON.stringify(
                                        submitRequestForm?.OrganizationType
                                    )}
                                >
                                    <ATMGrid.Row className="sempraMediumPaddingTop sempraMediumPaddingBottom">
                                        <ATMGrid.Column width={16}>
                                            <ATMHeader
                                                className={styles['ATMHeader']}
                                                as="h3"
                                            >
                                                <p
                                                    className={
                                                        styles.paragraphLabel
                                                    }
                                                >
                                                    {
                                                        Lang.LBL_NEEDED_TO_COMPLY_STATUTORY_OBLIGATION_FOR_LOW_INCOME_ENERGY_ASSISTANCE
                                                    }
                                                </p>
                                                <p className={styles.indicator}>
                                                    &nbsp; *
                                                </p>
                                            </ATMHeader>
                                            <div
                                                className={
                                                    styles.primaryContactRadioButtons
                                                }
                                            >
                                                {radioToggleOptions?.map(
                                                    (label, index) => (
                                                        <ATMRadio
                                                            key={index}
                                                            label={label}
                                                            onChange={
                                                                handleRadioButtonChange
                                                            }
                                                            checked={
                                                                submitRequestForm?.CSDComplyStatutoryObligation ===
                                                                `${label}`
                                                            }
                                                            value={`${label}`}
                                                            name={`CSDComplyStatutoryObligation`}
                                                            autoComplete="off"
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </ATMGrid.Column>
                                    </ATMGrid.Row>

                                    {submitRequestErrors.CSDComplyStatutoryObligation && (
                                        <FormField className="sempraMediumPaddingBottom">
                                            <p className={styles.errorText}>
                                                <WarningRounded />
                                                This is a required field.
                                            </p>
                                        </FormField>
                                    )}

                                    {submitRequestForm.CSDComplyStatutoryObligation ==
                                        'Yes' && (
                                        <ATMGrid.Row className=" sempraMediumPaddingTop sempraMediumPaddingBottom">
                                            <ATMGrid.Column width={16}>
                                                <ATMField
                                                    name="CSDDescHowDataComply"
                                                    key={JSON.stringify(
                                                        submitRequestForm?.CSDComplyStatutoryObligation
                                                    )}
                                                    placeholder={
                                                        Lang.PLC_PLEASE_ENTER_TEXT_HERE
                                                    }
                                                    as={ATMTextArea}
                                                    label={
                                                        Lang.LBL_DESC_HOW_DATA_WILL_COMPLY_STATUTE
                                                    }
                                                    fluid
                                                    required
                                                    data-testid="CSDDescHowDataComply"
                                                    className={
                                                        styles.fieldStyle
                                                    }
                                                    value={
                                                        submitRequestForm?.CSDDescHowDataComply
                                                    }
                                                    restrictAngularBrackets
                                                    maxLength={1000}
                                                    minLength={10}
                                                    charLimit={1000}
                                                    charCount
                                                    error={
                                                        submitRequestErrors.CSDDescHowDataComply
                                                    }
                                                    onBlur={(data) =>
                                                        handleOnBlur({
                                                            name: 'CSDDescHowDataComply',
                                                            value: data?.target[
                                                                'value'
                                                            ],
                                                        })
                                                    }
                                                    noSpecialCharacters={true}
                                                    autoComplete="off"
                                                />
                                            </ATMGrid.Column>
                                        </ATMGrid.Row>
                                    )}
                                </div>
                            )}
                        </ATMGrid>
                    )}
                </>
            )}

            {moreInfo && (
                // More Info Modal
                <CustomModal
                    modalOpen={moreInfo}
                    setModalClose={setMoreInfo}
                    header="Data Categories"
                    subHeader="The Table below provides 3rd parties examples of data elements that are commonly requested."
                    size="small"
                    content={
                        <div className={styles.additionInfoFont}>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell>
                                            {additionalInfoTable.header1}
                                        </TableHeaderCell>
                                        <TableHeaderCell>
                                            {additionalInfoTable.header2}
                                        </TableHeaderCell>
                                        <TableHeaderCell>
                                            {additionalInfoTable.header3}
                                        </TableHeaderCell>
                                    </TableRow>
                                </TableHeader>

                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            {additionalInfoTable.content1_1}
                                        </TableCell>
                                        <TableCell>
                                            {additionalInfoTable.content1_2}
                                        </TableCell>
                                        <TableCell verticalAlign="top">
                                            {additionalInfoTable.content1_3}
                                            <br />
                                            {additionalInfoTable.content1_4}
                                            <br />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            {additionalInfoTable.content2_1}
                                        </TableCell>
                                        <TableCell>
                                            {additionalInfoTable.content2_2}
                                        </TableCell>
                                        <TableCell verticalAlign="top">
                                            {additionalInfoTable.content2_3}
                                            <br />
                                            {additionalInfoTable.content2_4}
                                            <br />
                                            {additionalInfoTable.content2_5}
                                            <br />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            {additionalInfoTable.content3_1}
                                        </TableCell>
                                        <TableCell>
                                            {additionalInfoTable.content3_2}
                                        </TableCell>
                                        <TableCell verticalAlign="top">
                                            {additionalInfoTable.content3_3}
                                            <br />
                                            {additionalInfoTable.content3_4}
                                            <br />
                                            {additionalInfoTable.content3_5}
                                            <br />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            {additionalInfoTable.content4_1}
                                        </TableCell>
                                        <TableCell>
                                            {additionalInfoTable.content4_2}
                                        </TableCell>
                                        <TableCell verticalAlign="top">
                                            {additionalInfoTable.content4_3}
                                            <br />
                                            {additionalInfoTable.content4_4}
                                            <br />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <ATMGrid.Row className="sempraMediumPaddingAround">
                                {`Qualified Academic Researchers, per Regulatory Decision (D_14_05_016), may submit a request for information specific to a Customer such as Customer Name and Customer Address. 
                                These requests must align with the purpose of the research and a signed non-disclosure agreement is required prior to receiving the data.`}
                            </ATMGrid.Row>
                            <ATMGrid.Row className="sempraMediumPaddingAround">
                                All data requests submitted to IOUs will be
                                displayed in a public transaction log on the
                                web-site.
                            </ATMGrid.Row>
                        </div>
                    }
                />
            )}
        </ATMSegment>
    );
};

export default DataRequest;
