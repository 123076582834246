export const submitFormDefaultValues = {
    AcademicDepartment: '',
    AccreditedInstituteName: '',
    AdditionalRequesterCompany: '',
    AdditionalRequesterContact: '',
    AdditionalRequesterEmail: '',
    AdditionalRequesterName: '',
    AddressLine1: '',
    AgreeNDASign: '',
    City: '',
    CompyStatutoryObligation: '',
    ContactNumber: '',
    CSDComplyStatutoryObligation: '',
    CSDDescHowDataComply: '',
    DataAccessControlProcess: '',
    DataAudience: '',
    DataDisposalDate: '',
    DataDisposalProcess: '',
    DescHowDataComply: '',
    EIN: '',
    Email: '',
    EndDate: '',
    FacultyMemberSponsored: '',
    GeographicalAreaZipCode: false,
    GeographicalAreaCity: false,
    GeographicalAreaCounty: false,
    GeographicalAreaOthers: false,
    HasCertifiedByReviewBoard: '',
    HasProjectCertified: '',
    HowProjectAdvancesEnergy: '',
    IsFacultyMember: '',
    IsFromCaliforniaUtility: '',
    IsPrimaryContact: '',
    IsThirdPartyAccess: '',
    OrganizationName: '',
    OrganizationType: '',
    RecurringRequest: '',
    RequestDescription: '',
    RequestElements: '',
    RequesterName: '',
    Role: '',
    SecureDataStore: '',
    SecureDataTransmitPlan: '',
    SecureDataUsageProcess: '',
    StartDate: '',
    State: '',
    ZipCode: '',

    AllSectors: false,
    Residential: false,
    NonResidential: false,
    Commercial: false,
    Industrial: false,
};

export const fieldErrors = {
    OrganizationName: 'This field is required',
    AddressLine1: 'This field is required',
    City: 'This field is required',
    State: 'This field is required',
    ZipCode: 'This field is required',
    EIN: 'This field is required',
    OrganizationType: 'This field is required',
    Role: 'This field is required',
    RequesterName: 'This field is required',
    ContactNumber: 'This field is required',
    Email: 'This field is required',
    confirmEmailAddress: 'This field is required',
    IsPrimaryContact: 'This field is required',

    // Data Request
    RequestDescription: 'This field is required',
    RequestElements: 'This field is required',
    StartDate: 'This field is required',
    EndDate: 'This field is required',
    DataAudience: 'This field is required',
    IsThirdPartyAccess: 'This field is required',
    RecurringRequest: 'This field is required',
    RecurringFrequency: 'This field is required',
    IsFromCaliforniaUtility: 'This field is required',
    GeographicalAreaZipCode: 'This field is required',
    GeographicalAreaCity: 'This field is required',
    GeographicalAreaCounty: 'This field is required',
    GeographicalAreaOthers: 'This field is required',
    AllSectors: 'This field is required',
    Residential: 'This field is required',
    NonResidential: 'This field is required',
    Commercial: 'This field is required',
    Industrial: 'This field is required',

    // Privacy and Security
    SecureDataTransmitPlan: 'This field is required',
    SecureDataStore: 'This field is required',
    SecureDataUsageProcess: 'This field is required',
    DataAccessControlProcess: 'This field is required',
    DataDisposalDate: 'This field is required',
    DataDisposalProcess: 'This field is required',
};

export const submitRequestErrorsDefaultValues = {
    OrganizationName: null,
    AddressLine1: null,
    City: null,
    State: null,
    ZipCode: null,
    EIN: null,
    OrganizationType: null,
    Role: null,
    RequesterName: null,
    ContactNumber: null,
    Email: null,
    confirmEmailAddress: null,
    IsPrimaryContact: null,

    // Data Request
    RequestDescription: null,
    RequestElements: null,
    StartDate: null,
    EndDate: null,
    DataAudience: null,
    IsThirdPartyAccess: null,
    RecurringRequest: null,
    RecurringFrequency: null,
    IsFromCaliforniaUtility: null,
    GeographicalAreaZipCode: null,
    GeographicalAreaCity: null,
    GeographicalAreaCounty: null,
    GeographicalAreaOthers: null,
    AllSectors: null,
    Residential: null,
    NonResidential: null,
    Commercial: null,
    Industrial: null,

    // Privacy and Security
    SecureDataTransmitPlan: null,
    SecureDataStore: null,
    SecureDataUsageProcess: null,
    DataAccessControlProcess: null,
    DataDisposalDate: null,
    DataDisposalProcess: null,
};

export const submitRequestFields = {
    AcademicDepartment: null,
    AccreditedInstituteName: null,
    AdditionalRequesterCompany: null,
    AdditionalRequesterContact: null,
    AdditionalRequesterEmail: null,
    AdditionalRequesterName: null,
    AddressLine1: null,
    AddressLine2: null,
    AgreeNDASign: null,
    City: null,
    CompyStatutoryObligation: null,
    ContactNumber: null,
    CSDComplyStatutoryObligation: null,
    CSDDescHowDataComply: null,
    DataAccessControlProcess: null,
    DataAudience: null,
    DataDisposalDate: null,
    DataDisposalProcess: null,
    DescHowDataComply: null,
    EIN: null,
    Email: null,
    EndDate: null,
    FacultyMemberSponsored: null,
    GeographicalArea: null,
    GeographicalAreaZipCode: null,
    GeographicalAreaCity: null,
    GeographicalAreaCounty: null,
    GeographicalAreaOthers: null,
    HasCertifiedByReviewBoard: null,
    HasProjectCertified: null,
    HowProjectAdvancesEnergy: null,
    IsFacultyMember: null,
    IsFromCaliforniaUtility: null,
    IsPrimaryContact: null,
    IsThirdPartyAccess: null,
    OrganizationName: null,
    OrganizationType: null,
    OtherUtilities: null,
    RecurringFrequency: null,
    RecurringRequest: null,
    RequestDescription: null,
    RequestElements: null,
    RequesterName: null,
    Role: null,
    SecureDataStore: null,
    SecureDataTransmitPlan: null,
    SecureDataUsageProcess: null,
    SocalGasContactEmail: null,
    SoCalGasContactName: null,
    StartDate: null,
    State: null,
    ZipCode: null,
};

export const blackListedDomains = [
    'facebook',
    'google',
    'amazon',
    'netflix',
    'hotmail',
    'microsoft',
    'gmail',
    'yahoo',
    'orkut',
];
