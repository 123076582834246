export default {
    // Submit request -> Organization Requesting Data
    LBL_FROMYEAR: 'From year',
    LBL_TOYEAR: 'To year',
    LBL_HOME: 'Home',
    LBL_ORGANIZATION_NAME: 'Organization Name',
    LBL_ADDRESS: 'Address',
    LBL_ADDRESS_LINE_1: 'Address Line 1',
    LBL_ADDRESS_LINE_2: 'Address Line 2',
    LBL_CITY: 'City',
    LBL_STATE: 'State',
    LBL_EMPLOYER_IDENTIFICATION_NUMBER: 'Employer Identification Number',
    LBL_TYPE_OF_ORGANIZATION: 'Type of Organization',
    LBL_TITLE_OR_ROLE: 'Title/Role',
    LBL_NAME: 'Name',
    LBL_PHONE_NUMBER: 'Phone Number',
    LBL_YOUR_NAME: 'Your Name',
    LBL_YOUR_PHONE_NUMBER: 'Your Phone Number',
    LBL_OFFICIAL_EMAIL_ADDRESS: 'Official E-mail Address',
    LBL_CONFIRM_OFFICIAL_EMAIL_ADDRESS: 'Confirm Official E-mail Address',
    LBL_ARE_YOU_THE_PRIMARY_CONTACT: 'Are you the Primary Contact?',
    LBL_COMPANY: 'Your Company',
    LBL_EMAIL_ADDRESS: 'E-mail Address',
    LBL_YOUR_EMAIL_ADDRESS: 'Your E-mail Address',
    LBL_CONFIRM_YOUR_EMAIL_ADDRESS: 'Confirm Your E-mail Address',
    LBL_ORGANIZATION_REQUESTING_DATA: 'Organization Requesting Data',
    LBL_DATA_REQUEST: 'Data Request',
    LBL_PRIVACY_AND_SECURITY: 'Privacy And Security',
    LBL_EXIT: 'Exit',
    LBL_LOCAL_GOVERNMENT: 'Local Government',
    LBL_ACADEMIC_RESEARCHER: 'Academic Researcher',
    LBL_STATE_FEDERAL_AGENCY: 'State/Federal Agency',
    LBL_CSD: 'CA Department of Community Services & Development',
    LBL_OTHERS: 'Others (Please Specify)',

    // Submit request -> Local gov -> Faculty Contact
    LBL_ACCREDITED_INST_SPONSERING_RESEARCH:
        'Name of accredited institution sponsoring the research',
    LBL_FACULTY_MEMBER_OF_INST: 'Are you a faculty member at this institution?',
    LBL_FACULTY_MEMBER_SPONSORING: 'Faculty member sponsoring your project',
    LBL_DEPT_JOB_TITLE: 'Department/Job Title',

    // Submit request -> Data Request
    LBL_WHAT_IS_YOUR_DATA_REQ: 'What is your data request?',
    LBL_WHAT_DATA_ARE_REQUESTED: 'What data elements are requested?',
    LBL_WHAT_GEOGRAPHICAL_AREA_IS_REQUESTED:
        'What geographical area is requested?',
    LBL_WHICH_OTHER_UTILITIES: 'Which other Utilities?',
    LBL_ZIP_CODE: 'Zip Code',
    LBL_OTHER: 'Other (please specify)',
    LBL_COUNTY: 'County',
    LBL_WHAT_IS_START_DATE: 'What is the start date?',
    LBL_WHAT_IS_END_DATE: 'What is the end date?',
    LBL_WHO_IS_AUDIENCE_OF_THIS_DATA:
        'Who is the intended audience of this data?',
    LBL_WILL_DATA_BE_SHARED_WITH_THIRD_PARTY:
        'Will the data be shared with a third party?',
    LBL_IS_THIS_RECURRING_REQ: 'Is this a recurring request?',
    LBL_YES: 'Yes',
    LBL_NO: 'No',
    LBL_PLEASE_SELECT_FREQUENCY_OF_RECURRING_REQ:
        'Please select frequency of recurring request',
    LBL_MONTHLY: 'Monthly',
    LBL_QUARTERLY: 'Quarterly',
    LBL_ANNUALLY: 'Annually',
    LBL_PACIFIC_GAS_AND_ELECTRIC: 'Pacific Gas & Electric',
    LBL_SOUTHERN_CALIFORNIA_EDISON: 'Southern California Edison',
    LBL_SANDIEGO_GAS_AND_ELECTRIC: 'San Diego Gas & Electric',
    LBL_CUSTOMER_SEGMENT: 'What Customer Segments information is requested?',

    LBL_REQ_DATA_FROM_OTHER_CALIFORNIA_UTILITIES:
        'Are you requesting this data from other California utilities?',
    LBL_CALIFORNIA_ENERGY_USE:
        'Describe how your project advances California energy use and conservation',
    LBL_CERTIFIED_FOR_CALIFORNIA_CIVIL_CODE:
        'Has your project been certified by CPHS, CHHSA or an Institutional Review Board to be in compliance with California Civil Code Section 1798.24(t)?',
    LBL_CERTIFIED_FOR_HUMAN_SUBJECTS:
        'Has your project been certified by an Institutional Review Board to be in compliance with the Common Rule for the Protection of Human Subjects?',
    LBL_AGREE_TO_SIGN_AN_NDA: 'Do you agree to sign an NDA (if required)',
    LBL_NEEDED_TO_COMPLY_STATUTORY_OBLIGATION:
        'Is the data needed to comply with a statutory obligation?',
    LBL_DESC_HOW_DATA_WILL_COMPLY_STATUTE:
        'Describe how the data will be used to comply with the statute. Please reference the statute name/number and include references to the statute text.',
    LBL_NEEDED_TO_COMPLY_STATUTORY_OBLIGATION_FOR_LOW_INCOME_ENERGY_ASSISTANCE:
        'Is the data needed to comply with a statutory obligation pertaining to the Low Income Energy Assistance Program?',

    // Submit request -> Security and Privacy
    LBL_SECURELY_TRANSMIT_DATA:
        'How do you plan to securely transmit data received from SoCalGas?',
    LBL_SECURELY_STORED: 'How will the data be securely stored?',
    LBL_DATA_SECURE_DURING_USE:
        'How will you ensure the data is secure during use?',
    LBL_ACCESS_TO_DATA_CONTROLLED: 'How will access to the data be controlled?',
    LBL_DISPOSE_DATA: 'When will you dispose of the customer data provided?',
    LBL_SECURELY_DISPOSE_DATA: 'How will the data be securely disposed of?',

    LBL_SUBMIT: 'Submit',
    LBL_SUBMIT_REQUEST: 'Submit Request',
    LBL_REVIEW: 'Review',
    LBL_REQUEST_SUBMITTED: 'Request Submitted',
    LBL_REENTER_EMAIL_ADDRESS: 'Please re-enter your e-mail address below',

    LBL_QUARTERLY_DATA: 'Quarterly Gas Usage by ZIP Code',
    LBL_SUBMIT_A_REQUEST: 'Submit a Request',
    LBL_EDRP_DOWNLOAD: 'EDRP Offline Worksheet',
    LBL_DOWNLOAD_WORKSHEET: 'Download Worksheet',
    LBL_RESEND_VERIFICATION_LINK: 'Resend Verification Link',
    LBL_ENTER_EMAIL: 'Enter Email',
    LBL_DATA_REQUEST_LOG: 'Data Request Log',
    LBL_VIEW_REQ: 'View Request',
    LBL_DATA_REQ_LOG: 'Data Request Log',
    LBL_INFORMATION: 'Information',
    LBL_RESOURCES: 'Resources',

    LBL_EMAIL_VERIFICATION: 'Email Verification Completed',
    LBL_SEARCH: 'Search',
    LBL_STATUS: 'Status',
    LBL_REQUESTOR_TYPE: 'Requestor Type',
    LBL_EMAILID: 'energydatarequest@socalgas.com',

    LBL_ACCCESS_READILY_AVAILABLE: 'Access Readily Available Gas Usage Reports',
    LBL_ONLINE_SUBMISSION: 'Online Submission of Data Requests',
    LBL_THINGS_TO_KNOW: 'Things to Know',
    LBL_TIME_FRAMES: 'Time Frames for Processing Requests',
    LBL_UPDATED_AGGREGATION:
        'Upgrated Aggregation Rules and Data Security Policies',
    LBL_PROGRAM_BENEFITS: 'Program Benefits',
    LBL_PRIVACY_AND_SECURITY_REQ: 'Privacy & Security Information',
    LBL_HOW_TO_CONTACT_US: 'How to Contact Us',

    LBL_ACADEMIC_INSTITUTIONS_AND_RESEARCHERS: 'Academic Institutions and Researchers',
    LBL_LOCAL_GOVERNMENTS: 'Local Governents',
    LBL_STATE_FEDERAL_AGENCIES: 'State / Federal Agencies',
    LBL_CALIFORNIA_COMMUNITY_SERVICES_AND_DEVELOPMENT: 'California Community Services and Development (CSD)',
    LBL_ENERGY_INFO_ADMINISTRATION: 'Energy Information Administration',
    LBL_REQUIRED_AGREEMENTS: 'Required Agreements',
    LBL_CERTIFICATE_OF_DESTRUCTION: 'Certificate of Destruction',

    LBL_VIEW_SAMPLE_AGREEMENT: '(View Sample Agreement)',
    LBL_DOWNLOAD_CERTIFICATE_OF_DESTRUCTION: 'Download Certificate of Destruction',
};
